
import { computed, defineComponent, reactive, ref } from "vue";
import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import * as validators from "@vuelidate/validators";
import i18Validators from "@/utils/i18n-validators";
import customValidator from "@/utils/custom-validator";
import { OrganizationForm, RouteEntity } from "@/interfaces";
import { useStore } from "vuex";
import FileService from "@/services/FileService";
import { currentI18nLanguage } from "@/plugins/i18n";
import AddEntitySelect from "@/components/Backoffice/Common/AddEntitySelect.vue";
import OrganizationService from "@/services/OrganizationService";

export default defineComponent({
  name: "CreateOrganizationModal",
  emits: ["submit", "close", "update:modelValue"],
  components: {
    MrUiKitModal,
    MrUiKitButton,
    MrUiKitForm,
    AddEntitySelect,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const withI18nMessage = validators.createI18nMessage({ t });
    const required = withI18nMessage(validators.required, {
      messagePath: i18Validators.required,
    });
    const multiRequired = withI18nMessage(customValidator.multiRequired, {
      messagePath: i18Validators.multiRequired,
    });
    const store = useStore();
    const showForm = ref(false);
    const organization = ref<OrganizationForm>({
      id: -1,
      userCredentials: [],
      logo: {},
      isNational: false,
      fullname: {
        it: "",
        en: "",
      },
    });
    const fields = reactive([
      {
        component: "MrUiKitFileUpload",
        name: "logo",
        props: {
          label: "File",
        },
      },
      {
        component: "MrUiKitMultiLanguage",
        name: "fullname",
        props: {
          label: computed(() => t("common.fields.fullname")),
          labelPosition: "left",
          currentLanguage: currentI18nLanguage,
          languages: store.getters.supportedLanguages.map((l: any) => {
            return {
              value: l.id.toLowerCase(),
              label: l.id.toUpperCase(),
            };
          }),
        },
        rules: {
          multiRequired,
        },
      },
      {
        component: "MrUiKitToggle",
        name: "isNational",
        props: {
          label: computed(() => t("common.fields.isNational")),
          labelPosition: "left",
        },
      },
      {
        component: "MrUiKitSelect",
        name: "userCredentials",
        props: {
          labelKey: "email",
          label: "Account",
          labelPosition: "left",
          key: "id",
          multiple: true,
          chips: true,
          items: [],
          classes: ["h-25"],
          hintMessage: "Hint",
        }
      },
    ]);

    const onSubmit = () => {
      emit("submit", organization.value);
    };

    const onUpdateForm = async (event: any) => {
      if (organization.value == null) return;
      const field: keyof OrganizationForm = event.item.name;

      switch (event.type) {
        case "upload":
          const toUpload = organization.value[field].file;
          if (toUpload) {
            const uploadedFile = await FileService.upload(
              organization.value[field].file
            );
            organization.value[field].url = uploadedFile.url;
            organization.value[field].file = uploadedFile;
          }
          break;
        default:
          return;
      }
    };

    const onClose = () => {
      emit("close");
    };

    const onCreateOrganization = async ({ entity, show }: any) => {
      showForm.value = false;
      if (entity == null) {
        organization.value = {
          id: -1,
          userCredentials: [],
          logo: {},
          isNational: false,
          fullname: {
            it: "",
            en: "",
          },
        };
      } else {
        const find = await OrganizationService.getMultiLanguageById(entity.id);
        organization.value = {
          id: find.id,
          userCredentials: find.userCredentials ? find.userCredentials : [],
          logo: find.file,
          isNational: find.isNational,
          fullname: find.fullname,
        };
        emit("update:modelValue", organization.value);
      }
      showForm.value = show;
    };

    return {
      t,
      onClose,
      onUpdateForm,
      onSubmit,
      onCreateOrganization,
      organizationEntity: RouteEntity.Organization,
      showForm,
      organization,
      fields,
    };
  },
});
