import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-manage-metadata" }
const _hoisted_2 = { class: "d-flex pt-4 pb-4 align-items-center" }
const _hoisted_3 = { class: "fs-5" }
const _hoisted_4 = { class: "d-flex align-items-center ms-auto" }
const _hoisted_5 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_SportList = _resolveComponent("SportList")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_DisciplineList = _resolveComponent("DisciplineList")!
  const _component_StaffRoleList = _resolveComponent("StaffRoleList")!
  const _component_TrainerRoleList = _resolveComponent("TrainerRoleList")!
  const _component_MrUiKitNavTab = _resolveComponent("MrUiKitNavTab")!
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitCard, {
      headerClasses: ['border-bottom'],
      bodyClasses: ['pt-0']
    }, {
      header: _withCtx(() => [
        _createElementVNode("h4", null, _toDisplayString(_ctx.t('account.manageMetadata.title')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t('backoffice.sideNavTabs.subtitles.manageMetadata')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MrUiKitButton, { onClick: _ctx.onOpenAdd }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('common.operations.add')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_MrUiKitNavTab, { mode: "tab" }, {
            default: _withCtx(() => [
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.sport')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SportList, {
                    onUpdate: _ctx.onUpdate,
                    showModal: _ctx.showModal,
                    onClose: _ctx.onCloseModal
                  }, null, 8, ["onUpdate", "showModal", "onClose"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.discipline')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DisciplineList, {
                    onUpdate: _ctx.onUpdate,
                    showModal: _ctx.showModal,
                    onClose: _ctx.onCloseModal
                  }, null, 8, ["onUpdate", "showModal", "onClose"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.staffRole')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StaffRoleList, {
                    onUpdate: _ctx.onUpdate,
                    showModal: _ctx.showModal,
                    onClose: _ctx.onCloseModal
                  }, null, 8, ["onUpdate", "showModal", "onClose"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.trainerRole')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TrainerRoleList, {
                    onUpdate: _ctx.onUpdate,
                    showModal: _ctx.showModal,
                    onClose: _ctx.onCloseModal
                  }, null, 8, ["onUpdate", "showModal", "onClose"])
                ]),
                _: 1
              }, 8, ["name"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}