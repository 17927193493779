
import { computed, defineComponent, inject, onUpdated, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { BreadcrumbInstance, BreadcrumbStructureItem, ResetPassword } from '@/interfaces';
import { MrUiKitSideNav, MrUiKitTitle, MrUiKitTab } from 'mr-ui-kit';

import PersonalData from '@/components/Account/PersonalData.vue';
import ProfileManaged from '@/components/Account/ProfileManaged.vue';
import ManageMetadata from '@/components/Account/ManageMetadata.vue';
import VisitorList from '@/components/Account/VisitorList.vue';
import FederationList from '@/components/Account/FederationList.vue';
import InternationalCompetition from '@/components/Account/InternationalCompetition.vue';

import AccountService from '@/services/AccountService';
import AuthService from '@/services/AuthService';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AccountView',
    components: {
        PersonalData,
        ProfileManaged,
        MrUiKitSideNav,
        MrUiKitTitle,
        MrUiKitTab,
        ManageMetadata,
        FederationList,
        VisitorList,
        InternationalCompetition
    },
    setup() {
        const { t } = useI18n();
        const collapse = ref(false);
        const store = useStore();
        const sideNavLabel = reactive({
            personalDataTitle: computed(() => t('account.personalData.title')),
            profileManagedTitle: computed(() => t('account.profileManaged.title')),
            manageMetadataTitle: computed(() => t('account.manageMetadata.title')),
            tabFederation: computed(() => t('common.tabs.federations')),
            tabInternationalCompetition: computed(() => t('common.tabs.internationalCompetition')),
            tabManagerVisitor: computed(() => t('common.tabs.visitor'))
        });
        const passwords = reactive<ResetPassword>({
            oldPassword: null,
            newPassword: null,
            confirmNewPassword: null
        })

        onMounted(() => {
            store.commit('setBreadcrumbRebuild');
            store.commit('pushBreadcrumb', {
                label: 'Account',
                path: '/account',
                type: BreadcrumbStructureItem.Menu,
                isBackoffice: true
            });
        });

        const onResetPassword = async () => {
            try {
                await AccountService.resetPassword(passwords);
                store.commit('setAlert', {
                    type: 'success',
                    message: t('messages.passwordReset.success')
                })
            } catch (err) {
                store.commit('setAlert', {
                    type: 'danger',
                    message: t('messages.passwordReset.notMatch')
                })
            }
        }

        const logout = () => {
            AuthService.logout();
        }

        return {
            t,
            passwords,
            isSuperAdmin: store.getters.isSuperAdmin,
            isVisitor: store.getters.isVisitor,
            sideNavLabel,
            collapse,
            logout,
            onResetPassword
        }
    },
})
