
import { BreadcrumbInstance, BreadcrumbStructureItem, Organization, RouteEntity, SocialItem } from '@/interfaces';
import { computed, defineComponent, inject, onBeforeUpdate, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import OrganizationService from '@/services/OrganizationService';
import { MrUiKitProfile, MrUiKitNavTab, MrUiKitTab } from 'mr-ui-kit'

import Biography from '@/components/Frontend/Common/Biography.vue';
import StaffList from '@/components/Frontend/Common/StaffList.vue';
import TrainerList from '@/components/Frontend/Common/TrainerList.vue';
import AthleteList from '@/components/Frontend/Common/AthleteList.vue';
import TeamResult from '@/components/Frontend/TeamResult/TeamResult.vue';

import { useStore } from 'vuex';

export default defineComponent({
    name: 'OrganizationView',
    components: {
        MrUiKitProfile,
        MrUiKitNavTab,
        MrUiKitTab,
        Biography,
        StaffList,
        TrainerList,
        AthleteList,
        TeamResult
    },
    setup() {
        const route = useRoute();
        const { t, locale, } = useI18n();
        const store = useStore();
        const organizationId = ref(-1);
        const currentOrganization = ref<Organization | null>(null);
        const isMounted = ref<boolean>(false);
        const totalCounts = reactive({
            trainers: {
                count: 0
            },
            athletes: {
                count: 0
            }
        });

        onMounted(async () => {
            organizationId.value = +route.params.id;
            currentOrganization.value = await OrganizationService.getById(organizationId.value, locale.value);

            totalCounts.trainers = await OrganizationService.countSubList(organizationId.value, RouteEntity.Trainer);
            totalCounts.athletes = await OrganizationService.countSubList(organizationId.value, RouteEntity.Athlete);

			store.commit('setBreadcrumbRebuild');
            store.commit('pushBreadcrumb', {
                path: '/organizations/' + organizationId.value,
                label: currentOrganization.value.fullname,
                type: BreadcrumbStructureItem.Organization,
                entityType: RouteEntity.Organization,
                data: {
                    organization: currentOrganization.value
                }
            });

            isMounted.value = true;
        })

        onBeforeUpdate(() => {
            if (organizationId.value !== +route.params.id) {
                organizationId.value = +route.params.id;
                window.location.reload();
            }
        })

        const profileSubtitle = computed(() => {
            return totalCounts.trainers.count + ' ' + t('common.trainers') + ' - ' +
                totalCounts.athletes.count + ' ' + t('common.athletes');
        })

        const bioItems = computed(() => {
            if (currentOrganization.value == null) {
                return []
            }

            return [
                {
                    title: t('common.bioItems.foundation'),
                    value: currentOrganization.value.dateFoundation
                },
                {
                    title: t('common.bioItems.website'),
                    value: currentOrganization.value.website
                },
                {
                    title: t('common.bioItems.email'),
                    visible: currentOrganization.value.publicEmail,
                    value: currentOrganization.value.email
                },
                {
                    title: t('common.bioItems.telephone'),
                    visible: currentOrganization.value.publicTelephone,
                    value: currentOrganization.value.telephone
                }
            ]
        });

        const socialItems = computed<SocialItem>(() => {
            if (currentOrganization.value == null) {
                return {}
            }

            return {
                facebook: currentOrganization.value.facebook,
                instagram: currentOrganization.value.instagram
            }
        })

        const logo = computed(() => {
            return currentOrganization.value?.logo ? currentOrganization.value.logo.url : null;
        })

        const cover = computed(() => {
            return currentOrganization.value?.cover ? currentOrganization.value.cover.original : require('@/assets/img/copertina_fed_base.jpg');
        })

        return {
            t,
            logo,
            cover,
            organization: currentOrganization,
            totalCounts,
            isMounted,
            profileSubtitle,
            organizationEntity: RouteEntity.Organization,
            bioItems,
            socialItems
        }
    },
})
