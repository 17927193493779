
	import { FormattedAccount, RouteEntity } from '@/interfaces';
	import AccountService from '@/services/AccountService';
	import {
		MrUiKitModal,
		MrUiKitAddField,
		MrUiKitInput,
		MrUiKitButton,
	} from 'mr-ui-kit';
	import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRoute } from 'vue-router';
	import { useStore } from 'vuex';

	export default defineComponent({
		name: 'ManageAccountModal',
		emits: ['close'],
		components: {
			MrUiKitModal,
			MrUiKitAddField,
			MrUiKitInput,
			MrUiKitButton,
		},
		props: {
			entity: {
				type: String as PropType<RouteEntity>,
				default: RouteEntity.Federation,
			},
			id: {
				type: Number,
				default: -1,
			},
		},
		setup(props, { emit }) {
			const { t } = useI18n();
			const store = useStore();
			const route = useRoute();
			const accounts = reactive<FormattedAccount[]>([]);
			const template = reactive<FormattedAccount>({
				id: -1,
				email: '',
			});
			const isMounted = ref<boolean>(false);

			onMounted(async () => {
				await loadAccounts();
				isMounted.value = true;
			});

			const loadAccounts = async () => {
				const loaded = await AccountService.findAccountsByEntityId(
					props.entity,
					props.id,
				);
				accounts.push(...loaded);
			};

			const onSave = async () => {
				try {
					await AccountService.saveAccountsToEntity(
						props.entity,
						props.id,
						accounts.map((a) => {
							return {
								id: a.id,
								email: a.email,
							};
						}),
					);
					await loadAccounts();
					store.commit('setAlert', {
						type: 'success',
						message: t('messages.generalSuccess.save'),
					});
					emit('close');
				} catch (err) {
					store.commit('setAlert', {
						type: 'danger',
						message: t('messages.generalError.save'),
					});
				}
			};

			const onClose = () => {
				emit('close');
			};

			return {
				t,
				onSave,
				onClose,
				isMounted,
				accounts,
				template,
			};
		},
	});
