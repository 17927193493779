
	import { Federation, Filter } from '@/interfaces';
	import FederationService from '@/services/FederationService';
	import { defineComponent, onMounted, reactive, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { MrUiKitCard, MrUiKitList } from 'mr-ui-kit';
	import InfiniteScroll from '@/components/UI/InfiniteScroll.vue';
	import { useRouter } from 'vue-router';
	import { localizePath } from '@/plugins/i18n';

	export default defineComponent({
		name: 'LandingFederations',
		components: {
			MrUiKitCard,
			MrUiKitList,
			InfiniteScroll,
		},
		setup() {
			const { t, locale } = useI18n();
			const router = useRouter();
			const isLoading = ref(false);
			const federations = reactive<Federation[]>([]);
			const guard = ref(false);
			const isMounted = ref(false);
			const filter = ref<Filter>({
				limit: 8,
				offset: 0,
			});

			onMounted(async () => {
				await loadMore();
				isMounted.value = true;
			});

			const loadMore = async () => {
				if (!isLoading.value) {
					isLoading.value = true;
					const loaded = await FederationService.get(
						locale.value,
						filter.value,
					);
					federations.push(...loaded);
					isLoading.value = false;
					filter.value.offset += 8;

					if (loaded.length === 0) {
						guard.value = true;
					}
				}
			};

			const onClick = (federation: Federation) => {
				router.push(
					localizePath({
						path: '/federations/' + federation.id,
					}),
				);
			};

			return {
				t,
				onClick,
				loadMore,
				guard,
				federations,
				isLoading,
				isMounted,
			};
		},
	});
