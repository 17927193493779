
	import { defineComponent, inject, reactive, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { TypedJs } from '@/components/TypedJs';
	import { MrUiKitSearch } from 'mr-ui-kit';
	import {
		BreadcrumbInstance,
    BreadcrumbStructureItem,
		FormattedFilterResult,
		Organization,
    RouteEntity,
	} from '@/interfaces';
	import FilterService from '@/services/FilterService';
	import { useRouter } from 'vue-router';
import { localizePath } from '@/plugins/i18n';
import { useStore } from 'vuex';

	export default defineComponent({
		name: 'LandingSearch',
		components: {
			TypedJs,
			MrUiKitSearch,
		},
		setup() {
			const { t, locale } = useI18n();
			const router = useRouter();
			const store = useStore();
			const loopStrings = reactive([
				t('home.loopSearchStrings.federations'),
				t('home.loopSearchStrings.organizations'),
				t('home.loopSearchStrings.trainers'),
				t('home.loopSearchStrings.athletes'),
			]);
			const items = ref<FormattedFilterResult[]>([]);
			const search = ref('');

			const onClick = async (item: FormattedFilterResult) => {
				router.push(localizePath({
					path: '/' + item.entity + '/' + item.id,
				}));

				let type = BreadcrumbStructureItem.Federation;
				switch (item.entity) {
					case RouteEntity.Federation:
						type = BreadcrumbStructureItem.Federation;
						break;
					case RouteEntity.Organization:
						type = BreadcrumbStructureItem.Organization;
						break;
					case RouteEntity.Trainer:
						type = BreadcrumbStructureItem.Trainer;
						break;
					case RouteEntity.Athlete:
						type = BreadcrumbStructureItem.Athlete;
						break;
				}

				store.commit('pushBreadcrumb', {
					label: item.fullname,
					path: '/' + item.entity + '/' + item.id,
					type
				});
			};

			const onSearch = async (searchData: any) => {
				if (searchData.value == '') {
					items.value = [];
				} else {
					items.value = await FilterService.getByKeywords(
						searchData.value,
						locale.value,
						{
							limit: 10,
							offset: searchData.offset,
						},
					);
				}
			};

			const onShowMoreResults = () => {
				router.push(localizePath({
					path: '/search',
					query: {
						keyword: search.value,
					},
				}));
			};

			return {
				t,
				onClick,
				onSearch,
				onShowMoreResults,
				search,
				items,
				homeHeroImage: require('@/assets/img/homepage/home_hero.png'),
				options: {
					strings: loopStrings,
					loop: true,
					typeSpeed: 90,
					backDelay: 2500,
					backSpeed: 30,
				},
			};
		},
	});
