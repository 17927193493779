
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { MrUiKitButton, MrUiKitTable, MrUiKitPagination } from 'mr-ui-kit';
import { GeneralFormatted, PaginationModel } from '@/interfaces';
import { useI18n } from 'vue-i18n';
import StaffRoleService from '@/services/StaffRoleService';
import axios from 'axios';
import { useStore } from 'vuex';

import StaffRoleModal from '@/components/Backoffice/Modals/StaffRoleModal.vue';
import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';

export default defineComponent({
    name: 'StaffRoleList',
    emits: ['update', 'close'],
    components: {
        MrUiKitButton,
        MrUiKitTable,
        MrUiKitPagination,
        DeleteEntityModal,
        StaffRoleModal
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const store = useStore();
        const disciplines = ref<GeneralFormatted[]>([]);
        const showDeleteModal = ref(false);
        const search = ref<string | null>(null);
        const selectedStaffRole = ref<GeneralFormatted | null>(null);
        const isLoading = ref(false);
        const pagination = reactive<PaginationModel>({
            current: 0,
            count: 0,
            limit: 10
        });
        const columns = reactive([
            {
                label: computed(() => t('common.fields.name')),
                field: 'name'
            },
            {
                label: '',
                field: 'action'
            }
        ]);

        onMounted(async () => {
            await onInit();
        })

        const onInit = async () => {
            pagination.current = 1;
            const total = await StaffRoleService.count(locale.value, search.value);
            pagination.count = total.count;
            await onLoad(0);
        }

        const onLoad = async (offset: number) => {
            if (!isLoading.value) {
                isLoading.value = true;

                disciplines.value = await StaffRoleService.get(locale.value, {
                    limit: 10,
                    offset,
                    search: search.value != null ? search.value : null
                });

                isLoading.value = false;
            }
        }

        const onDelete = async (sport: GeneralFormatted) => {
            selectedStaffRole.value = sport;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedStaffRole.value) {
                try {    
                    await axios.delete('/staff-roles/' + selectedStaffRole.value.id);
                    selectedStaffRole.value = null;
                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        const onUpdate = (item: GeneralFormatted) => {
            selectedStaffRole.value = item;
            emit('update');
        }

        const onCloseModal = async () => {
            selectedStaffRole.value = null;
            await onInit();
            emit('close');
        }

        const onCloseDelete = () => {
            showDeleteModal.value = false;
            selectedStaffRole.value = null;
        }

        const onSearch = (newSearch: string) => {
            if (newSearch != '') {
                search.value = newSearch;
            } else {
                search.value = null;
            }

            onInit();
        }

        const searchLabel = computed(() => {
            return t('common.findDots');
        })

        return {
            t,
            onLoad,
            onDelete,
            onUpdate,
            onSubmitDelete,
            onCloseModal,
            onCloseDelete,
            onSearch,
            searchLabel,
            showDeleteModal,
            selectedStaffRole,
            disciplines,
            columns,
            pagination
        }
    },
})
