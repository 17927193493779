
import { Filter, OtherResult, RouteEntity } from "@/interfaces";
import { defineComponent, onMounted, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { MrUiKitCollapse } from "mr-ui-kit";

import InfiniteScroll from "@/components/UI/InfiniteScroll.vue";
import CompetitionService from "@/services/CompetitionService";
import OtherResultIntermiadiateBlock from "@/components/Frontend/OtherResult/OtherResultIntermiadiateBlock.vue";
import OtherResultIntermiadiate from "@/components/Frontend/OtherResult/OtherResultIntermiadiate.vue";

export default defineComponent({
  name: "OtherResult",
  components: {
    InfiniteScroll,
    MrUiKitCollapse,
    OtherResultIntermiadiateBlock,
    OtherResultIntermiadiate,
  },
  props: {
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Trainer,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const route = useRoute();
    const entityId = ref<number>(-1);
    const otherResults = ref<OtherResult[]>([]);
    const isLoading = ref<boolean>(false);
    const guard = ref(false);
    const filter = ref<Filter>({
      limit: 5,
      offset: 0,
    });

    onMounted(() => {
      entityId.value = +route.params.id;
      loadMore();
    });

    const loadMore = async () => {
      if (!isLoading.value && entityId.value !== -1) {
        isLoading.value = true;
        let loaded: OtherResult[] =
          await CompetitionService.getOtherResultsByEntityId(
            entityId.value,
            props.entity,
            locale.value,
            filter.value
          );
        otherResults.value.push(...loaded);
        isLoading.value = false;

        if (loaded.length === 0) {
          guard.value = true;
        }

        filter.value.offset += 5;
      }
    };

    return {
      t,
      guard,
      otherResults,
    };
  },
});
