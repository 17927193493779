
import { FormattedEntity, RouteEntity, PaginationModel, BreadcrumbStructureItem } from "@/interfaces";
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { MrUiKitButton, MrUiKitTable, MrUiKitPagination } from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import FederationService from "@/services/FederationService";
import OrganizationService from "@/services/OrganizationService";
import TrainerService from "@/services/TrainerService";
import AthleteService from "@/services/AthleteService";
import EntityFilters from "@/components/Frontend/EntityList/EntityFilters.vue";
import { localizePath } from "@/plugins/i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "EntityListView",
  components: {
    MrUiKitTable,
    MrUiKitPagination,
    MrUiKitButton,
    EntityFilters,
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const isMounted = ref(false);
    const currentSearch = ref<string | null>(null);
    const currentFilterId = ref<number | null>(null);
    const entity = ref<RouteEntity>(RouteEntity.Federation);
    const entities = ref<FormattedEntity[]>([]);
    const store = useStore();
    const columns = ref([
      {
        label: computed(() => t("common.fields.name")),
        field: "fullname",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: 10,
    });

    onMounted(async () => {
      entity.value = route.params.entity as RouteEntity;
      await onCount();
      await onLoad(0);
      
      store.commit('setBreadcrumbRebuild');
      store.commit('pushBreadcrumb', {
          path: '/',
          label: 'Home',
          type: BreadcrumbStructureItem.Menu
      });
      isMounted.value = true;
    });

    onBeforeUpdate(async () => {
      entity.value = route.params.entity as RouteEntity;
      store.commit('subBreadcrumb', -1);
      store.commit('pushBreadcrumb', {
          path: '/',
          label: 'Home',
          type: BreadcrumbStructureItem.Menu
      });
    });

    watch(entity, (newV, oldV) => {
      if (newV !== oldV && isMounted.value === true) {
        onInit();
      }
    });

    const onInit = async () => {
      pagination.current = 1;
      await onCount();
      await onLoad(0);
      window.scrollTo(0, 0);
    };

    const onCount = async () => {
      switch (entity.value) {
        case RouteEntity.Federation:
          const totalFed = await FederationService.count(locale.value, {
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          pagination.count = totalFed.count;
          columns.value = [
            {
              label: computed(() => t("common.fields.name")) as any,
              field: "fullname",
            },
          ];
          break;
        case RouteEntity.Organization:
          const totalOrg = await OrganizationService.count(locale.value, {
            federation:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          pagination.count = totalOrg.count;
          columns.value = [
            {
              label: computed(() => t("common.fields.name")) as any,
              field: "fullname",
            },
          ];
          break;
        case RouteEntity.Trainer:
          const totalTrainer = await TrainerService.count({
            organization:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          pagination.count = totalTrainer.count;
          columns.value = [
            {
              label: computed(() => t("common.fields.name")) as any,
              field: "fullname",
            },
            {
              label: computed(() => t("tabs.organizations")),
              field: "organizations",
            },
          ];
          break;
        case RouteEntity.Athlete:
          const totalAthlete = await AthleteService.count({
            organization:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          pagination.count = totalAthlete.count;
          columns.value = [
            {
              label: computed(() => t("common.fields.name")) as any,
              field: "fullname",
            },
            {
              label: computed(() => t("tabs.organizations")),
              field: "organizations",
            },
            {
              label: computed(() => t("tabs.trainers")),
              field: "trainers",
            },
          ];
          break;
      }
    };

    const onLoad = async (offset: number) => {
      switch (entity.value) {
        case RouteEntity.Federation:
          entities.value = await FederationService.get(locale.value, {
            limit: 10,
            offset,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          break;
        case RouteEntity.Organization:
          entities.value = await OrganizationService.get(locale.value, {
            limit: 10,
            offset,
            federation:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          break;
        case RouteEntity.Trainer:
          entities.value = await TrainerService.get(locale.value, {
            limit: 10,
            offset,
            organization:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          break;
        case RouteEntity.Athlete:
          entities.value = await AthleteService.get(locale.value, {
            limit: 10,
            offset,
            organization:
              currentFilterId.value != null ? currentFilterId.value : null,
            search: currentSearch.value != null ? currentSearch.value : null,
          });
          break;
      }
    };

    const onSearch = (search: string) => {
      if (search !== "") {
        currentSearch.value = search;
      } else {
        currentSearch.value = null;
      }
      onInit();
    };

    const onFilter = (filter: FormattedEntity | null) => {
      if (filter) {
        currentFilterId.value = filter.id;
      } else {
        currentFilterId.value = null;
      }
      onInit();
    };

    const onNavigate = (item: FormattedEntity) => {
      router.push(
        localizePath({
          path: "/" + entity.value + "/" + item.id,
        })
      );
    };

    const onNavigateOrgTra = (item: any, entity: string) => {
      router.push(
        localizePath({
          path: "/" + entity + "/" + item.id,
        })
      );
    };

    const itemUrl = (item: FormattedEntity) => {
      const logo = item.photo ? item.photo : item.logo ? item.logo : null;
      return logo != null ? logo.url : null;
    };

    const searchLabel = computed(() => {
      return t('common.findDots');
    })

    return {
      t,
      entity,
      entities,
      columns,
      isMounted,
      pagination,
      searchLabel,
      itemUrl,
      onLoad,
      onFilter,
      onSearch,
      onNavigate,
      onNavigateOrgTra,
    };
  },
});
