
	import FederationService from '@/services/FederationService';
	import {
		computed,
		defineComponent,
		onMounted,
		reactive,
		ref,
	} from 'vue';
	import { useRoute } from 'vue-router';
	import { MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import customValidator from '@/utils/custom-validator';
	import i18nValidators from '@/utils/i18n-validators';
	import { useStore } from 'vuex';
	import FileService from '@/services/FileService';
	import {
		BreadcrumbInstance,
		BreadcrumbStructureItem,
		FederationInfoForm,
		MultiLanguageField,
		RouteEntity,
	} from '@/interfaces';
	import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'FederationPersonalData',
		components: {
			MrUiKitForm,
			MrUiKitButton,
		},
		setup() {
			const { t, locale } = useI18n();
			const isMounted = ref(false);
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18nValidators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18nValidators.multiRequired,
			});
			const store = useStore();
			const entityId = ref<number>(-1);
			const route = useRoute();
			const file = ref<any>({
				file: null,
				url: null,
			});
			const informationForm = ref<FederationInfoForm | null>(null);
			const fields = reactive([
				{
					component: 'MrUiKitToggle',
					name: 'active',
					props: {
						label: computed(() => t('common.fields.deleted')),
						labelPosition: 'left',
						subtitle: computed(() => t('common.fields.deletedSubtitle')),
					},
				},
				{
					component: 'MrUiKitFileUpload',
					name: 'cover',
					props: {
						label: computed(() => t('common.fields.cover')),
					},
				},
				{
					component: 'MrUiKitFileUpload',
					name: 'file',
					props: {
						label: 'Logo',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'fullname',
					props: {
						label: computed(() => t('common.fields.fullname')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
				// {
				// 	component: 'MrUiKitInput',
				// 	name: 'shortname',
				// 	props: {
				// 		label: computed(() => t('common.fields.shortname')),
				// 		labelPosition: 'left',
				// 	},
				// 	rules: {
				// 		required
				// 	}
				// },
				{
					component: 'MrUiKitInput',
					name: 'website',
					props: {
						label: computed(() => t('common.fields.website')),
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'telephone',
					props: {
						label: computed(() => t('common.fields.telephone')),
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'publicTelephone',
					props: {
						label: computed(() => t('common.fields.publicTelephone')),
						labelPosition: 'left',
						subtitle: computed(() =>
							t('common.fields.publicTelephoneSubtitle'),
						),
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'email',
					props: {
						label: 'Email',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'publicEmail',
					props: {
						label: computed(() => t('common.fields.publicEmail')),
						labelPosition: 'left',
						subtitle: computed(() => t('common.fields.publicEmailSubtitle')),
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'facebook',
					props: {
						label: 'Link Facebook',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'instagram',
					props: {
						label: 'Link instagram',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'bio',
					props: {
						type: 'texteditor',
						innerProps: {
							toolbarElements: [
								'header',
								'list',
								'fontStyle',
								'indent',
								'colors',
							],
						},
						label: computed(() => t('common.bio')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
				},
			]);

			onMounted(async () => {
				entityId.value = +route.params.id;
				informationForm.value = await FederationService.getMultiLanguageById(
					entityId.value,
				);
				const currentFederation = await FederationService.getById(
					entityId.value,
					locale.value,
				);

				store.commit('setBreadcrumbRebuild');
				store.commit('pushBreadcrumb', {
					path: '/federations/' + entityId.value + '/edit',
					label:
						informationForm.value.fullname[
							locale.value as keyof MultiLanguageField<string>
						],
					type: BreadcrumbStructureItem.Federation,
					isBackoffice: true,
					entityType: RouteEntity.Federation,
					data: {
						federation: currentFederation,
					},
				});

				isMounted.value = true;
			});

			const onUpdateForm = async (event: any) => {
				if (informationForm.value == null) return;
				const field: keyof FederationInfoForm = event.item.name;

				switch (event.type) {
					case 'upload':
						const toUpload = informationForm.value[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(
								informationForm.value[field].file,
							);
							informationForm.value[field].url = uploadedFile.url;
							informationForm.value[field].file = uploadedFile;
						}
						break;
					default:
						return;
				}
			};

			const onSubmit = async (disabled: boolean) => {
				if (informationForm.value == null || disabled) return;

				try {
					await FederationService.updateById(
						entityId.value,
						informationForm.value,
					);
					store.commit('setAlert', {
						type: 'success',
						message: t('messages.generalSuccess.update'),
					});
				} catch (err) {
					store.commit('setAlert', {
						type: 'danger',
						message: t('messages.generalError.update'),
					});
				}
			};

			return {
				t,
				onSubmit,
				onUpdateForm,
				fields,
				file,
				informationForm,
				isMounted,
			};
		},
	});
