
import { Athlete, Organization, RouteEntity, Trainer } from "@/interfaces";
import { computed, defineComponent, PropType } from "vue";
import { useRouter } from "vue-router";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "EntityBlock",
  props: {
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Organization,
    },
    items: {
      type: Array as PropType<Organization[] | Trainer[] | Athlete[]>,
      default: [],
    },
  },
  setup(props) {
    const router = useRouter();
    const isOrganization = computed(() => {
      return props.entity === RouteEntity.Organization;
    });

    const onNavigate = (item: any, entity: any) => {
      router.push(
        localizePath({
          path: "/" + entity + "/" + item.id,
        })
      );
    }

    return {
      onNavigate,
      isOrganization,
    };
  },
});
