import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-managed" }
const _hoisted_2 = { class: "d-flex pt-4 pb-4 align-items-center" }
const _hoisted_3 = { class: "fs-5" }
const _hoisted_4 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityList = _resolveComponent("EntityList")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_MrUiKitNavTab = _resolveComponent("MrUiKitNavTab")!
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitCard, {
      headerClasses: ['border-bottom'],
      bodyClasses: ['pt-0']
    }, {
      header: _withCtx(() => [
        _createElementVNode("h4", null, _toDisplayString(_ctx.t('account.profileManaged.title')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t('account.profileManaged.subtitle')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MrUiKitNavTab, { mode: "tab" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                return (_openBlock(), _createBlock(_component_MrUiKitTab, {
                  key: 'tab-form-' + i,
                  name: tab.label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_EntityList, {
                      entity: tab.entity
                    }, null, 8, ["entity"])
                  ]),
                  _: 2
                }, 1032, ["name"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}