
	import {
		computed,
		defineComponent,
		onMounted,
		reactive,
		ref,
	} from 'vue';
	import { MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import {
		BreadcrumbInstance,
		BreadcrumbStructureItem,
		Organization,
		RouteEntity,
		UpdateTrainerForm,
	} from '@/interfaces';
	import TrainerService from '@/services/TrainerService';
	import FileService from '@/services/FileService';
	import FederationService from '@/services/FederationService';
	import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'TrainerPersonalData',
		components: {
			MrUiKitForm,
			MrUiKitButton,
		},
		setup() {
			const { t, locale } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});
			const store = useStore();
			const entityId = ref<number>(-1);
			const route = useRoute();
			const form = ref<UpdateTrainerForm | null>(null);
			const countOrganizations = ref<number>(0);
			const federationId = ref<number>(-1);
			const offset = ref(0);
			const organizations = ref<Organization[]>([]);
			const isMounted = ref(false);
			const fields = reactive([
				{
					component: 'MrUiKitToggle',
					name: 'deleted',
					props: {
						label: computed(() => t('common.fields.deleted')),
						labelPosition: 'left',
						subtitle: computed(() => t('common.fields.deletedSubtitle')),
					},
				},
				{
					component: 'MrUiKitFileUpload',
					name: 'cover',
					props: {
						label: computed(() => t('common.fields.cover')),
					},
				},
				{
					component: 'MrUiKitFileUpload',
					name: 'photo',
					props: {
						label: 'Logo',
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'firstname',
					props: {
						label: computed(() => t('common.fields.firstname')),
						labelPosition: 'left',
					},
					rules: {
						required
					}
				},
				{
					component: 'MrUiKitInput',
					name: 'lastname',
					props: {
						label: computed(() => t('common.fields.lastname')),
						labelPosition: 'left',
					},
					rules: {
						required
					}
				},
				{
					component: 'MrUiKitSelect',
					name: 'trainerRole',
					props: {
						label: computed(() => t('common.fields.role')),
						labelPosition: 'left',
						labelKey: 'name',
						key: 'id',
						items: store.getters.trainerRoles,
					}
				},
				{
					component: 'MrUiKitSelect',
					name: 'organizations',
					props: {
						label: computed(() => t('tabs.organizations')),
						labelPosition: 'left',
						multiple: true,
						labelKey: 'fullname',
						items: organizations,
						infiniteScroll: true,
						count: countOrganizations,
					},
					rules: {
						required
					}
				},
				{
					component: 'MrUiKitInput',
					name: 'telephone',
					props: {
						label: computed(() => t('common.fields.telephone')),
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'publicTelephone',
					props: {
						label: computed(() => t('common.fields.publicTelephone')),
						labelPosition: 'left',
						subtitle: computed(() =>
							t('common.fields.publicTelephoneSubtitle'),
						),
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'email',
					props: {
						label: 'Email',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'publicEmail',
					props: {
						label: computed(() => t('common.fields.publicEmail')),
						labelPosition: 'left',
						subtitle: computed(() => t('common.fields.publicEmailSubtitle')),
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'facebook',
					props: {
						label: 'Link Facebook',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'instagram',
					props: {
						label: 'Link instagram',
						labelPosition: 'left',
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'bio',
					props: {
						type: 'texteditor',
						innerProps: {
							toolbarElements: [
								'header',
								'list',
								'fontStyle',
								'indent',
								'colors',
							],
						},
						label: computed(() => t('common.bio')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'active',
					props: {
						label: computed(() => t('common.fields.active')),
						labelPosition: 'left',
						classes: ['pt-4']
					},
				},
			]);

			onMounted(async () => {
				entityId.value = +route.params.id;
				form.value = await TrainerService.getMultiLangById(
					entityId.value,
					locale.value,
				);
				federationId.value = await FederationService.getIdByEntity(
					entityId.value,
					RouteEntity.Trainer,
				);
				const total = await FederationService.countSubList(
					federationId.value,
					RouteEntity.Organization,
					locale.value,
				);
				countOrganizations.value = total.count;
				await store.dispatch('getTrainerRoles', locale.value);

				const currentTrainer = await TrainerService.getById(
					entityId.value,
					locale.value,
				);

				await loadOrganizations();

				store.commit('setBreadcrumbRebuild');
				store.commit('pushBreadcrumb', {
					path: '/trainers/' + entityId.value + '/edit',
					label: form.value.firstname + ' ' + form.value.lastname,
					type: BreadcrumbStructureItem.Trainer,
					isBackoffice: true,
					entityType: RouteEntity.Trainer,
					data: {
						trainer: currentTrainer,
					},
				});

				isMounted.value = true;
			});

			const loadOrganizations = async () => {
				const loaded = await FederationService.getOrganizations(
					federationId.value,
					locale.value,
					{
						limit: 10,
						offset: offset.value,
					},
				);				
				offset.value += 10;
				organizations.value.push(...loaded);
			};

			const onSubmit = async (disabled: boolean) => {
				if (form.value == null || disabled) return;
				try {
					await TrainerService.updateById(form.value);
					store.commit('setAlert', {
						type: 'success',
						message: t('messages.generalSuccess.update'),
					});
				} catch (err) {
					store.commit('setAlert', {
						type: 'danger',
						message: t('messages.generalError.update'),
					});
				}
			};

			const onUpdateForm = async (event: any) => {
				if (form.value == null) return;
				const field: keyof UpdateTrainerForm = event.item.name;
				switch (event.type) {
					case 'upload':
						const toUpload = form.value[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(
								form.value[field].file,
							);
							form.value[field].url = uploadedFile.url;
							form.value[field].file = uploadedFile;
						}
						break;
					case 'load':
						await loadOrganizations();
					default:
						return;
				}
			};

			return {
				t,
				onSubmit,
				onUpdateForm,
				isMounted,
				form,
				fields,
				organizations,
			};
		},
	});
