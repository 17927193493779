
	import {
		Athlete,
		BreadcrumbInstance,
		BreadcrumbStructureItem,
		Organization,
		PaginationModel,
		RouteEntity,
	} from '@/interfaces';
	import {
		computed,
		defineComponent,
		inject,
		onMounted,
		PropType,
		reactive,
		ref,
		watch,
	} from 'vue';
	import { useI18n } from 'vue-i18n';
	import {
		MrUiKitPagination,
		MrUiKitTable,
		MrUiKitSelect,
		MrUiKitList,
		MrUiKitCard,
	} from 'mr-ui-kit';
	import { useRoute, useRouter } from 'vue-router';
	import FederationService from '@/services/FederationService';
	import OrganizationService from '@/services/OrganizationService';
	import TrainerService from '@/services/TrainerService';
	import { localizePath } from '@/plugins/i18n';
	import { useStore } from 'vuex';

	export default defineComponent({
		name: 'AthleteList',
		components: {
			MrUiKitPagination,
			MrUiKitTable,
			MrUiKitSelect,
			MrUiKitList,
			MrUiKitCard,
		},
		props: {
			count: {
				type: Number,
				default: null,
			},
			entity: {
				type: String as PropType<RouteEntity>,
				default: RouteEntity.Federation,
			},
		},
		setup(props) {
			const { t, locale } = useI18n();
			const store = useStore();
			const entityId = ref<number>(-1);
			const route = useRoute();
			const router = useRouter();
			const isLoading = ref<boolean>(false);
			const athletes = ref<Athlete[]>([]);
			const federationId = ref<number>(-1);
			const selectedOrganization = ref<Organization | null>(null);
			const organizationsItems = ref<Organization[]>([]);
			const countOrganization = ref(0);
			const offset = ref(0);
			const columns = reactive([
				{
					label: computed(() => t('athlete.table.name')),
					field: 'name',
				},
				{
					label: computed(() => t('athlete.table.organization')),
					field: 'organization',
				},
				{
					label: computed(() => t('athlete.table.trainer')),
					field: 'trainer',
				},
			]);
			const pagination = reactive<PaginationModel>({
				current: 1,
				count: 0,
				limit: 9,
			});

			onMounted(async () => {
				entityId.value = +route.params.id;
				await onCount();

				federationId.value = await FederationService.getIdByEntity(
					entityId.value,
					props.entity,
				);
				const total = await FederationService.countSubList(
					federationId.value,
					RouteEntity.Organization,
					locale.value,
				);
				countOrganization.value = total.count;
				await loadMore(0);
			});

			const onCount = async () => {
				if (props.count == null) {
					switch (props.entity) {
						case RouteEntity.Federation:
							const fedCount = await FederationService.countSubList(
								entityId.value,
								RouteEntity.Athlete,
							);
							pagination.count = fedCount.count;
							break;
						case RouteEntity.Organization:
							const orgCount = await OrganizationService.countSubList(
								entityId.value,
								RouteEntity.Athlete,
							);
							pagination.count = orgCount.count;
							break;
						case RouteEntity.Trainer:
							const trainerCount = await TrainerService.countSubList(
								entityId.value,
								RouteEntity.Athlete,
							);
							pagination.count = trainerCount.count;
							break;
					}
				} else {
					pagination.count = props.count;
				}
			};

			const loadMore = async (offset: number, organizationId?: number) => {
				if (!isLoading.value) {
					isLoading.value = true;
					switch (props.entity) {
						case RouteEntity.Federation:
							athletes.value = await FederationService.getAthletes(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
						case RouteEntity.Organization:
							athletes.value = await OrganizationService.getAthletes(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
						case RouteEntity.Trainer:
							athletes.value = await TrainerService.getAthletes(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
					}
					isLoading.value = false;
				}
			};

			const onLoadOrganizations = async () => {
				const loaded = await FederationService.getOrganizations(
					federationId.value,
					locale.value,
					{
						limit: 10,
						offset: offset.value,
					},
				);
				organizationsItems.value.push(...loaded);
				offset.value += 10;
			};

			watch(selectedOrganization, (newV) => {
				if (newV != null) {
					loadMore(0, newV.id);
				} else {
					loadMore(0);
				}
			});

			const goTo = (entity: 'organizations' | 'trainers', item: any) => {
				router.push(
					localizePath({
						path: '/' + entity + '/' + item.id,
					}),
				);

				store.commit('pushBreadcrumb', {
					label:
						entity === 'trainers'
							? item.firstname + ' ' + item.lastname
							: item.fullname,
					path: '/' + entity + '/' + item.id,
					type: BreadcrumbStructureItem.Trainer,
				});
			};

			const onClick = (athlete: Athlete) => {
				router.push(localizePath({ path: '/athletes/' + athlete.id }));

				store.commit('pushBreadcrumb', {
					label:
						athlete.firstname +
						(athlete.middlename ? ' ' + athlete.middlename : '') +
						' ' +
						athlete.lastname,
					path: '/athletes/' + athlete.id,
					type: BreadcrumbStructureItem.Athlete,
				});
			};

			const filterLabel = computed(() => {
				return t('common.operations.filter');
			})

			return {
				t,
				loadMore,
				onClick,
				goTo,
				pagination,
				athletes,
				filterLabel,
				columns,
				onLoadOrganizations,
				selectedOrganization,
				organizationsItems,
				countOrganization,
			};
		},
	});
