import { Count, Filter, GeneralFormatted, StaffRoleForm } from "@/interfaces";
import store from "@/store";
import axios from "axios";
import FilterService from "./FilterService";

class StaffRoleService {
    constructor() {}

    async count(lang: string, search: string | null): Promise<Count> {
        const { data } = await axios.get<Count>('/staff-roles/count?lang=' + lang + (search ? '&search=' + search : ''));
        return data;
    }

    async get(lang: string, filter: Filter): Promise<GeneralFormatted[]> {
        const filters = FilterService.parseFilter(filter);
        const { data } = await axios.get<GeneralFormatted[]>('/staff-roles?lang=' + lang + '&' + filters);
        return data;
    }

    async getMultiLang(id: number): Promise<StaffRoleForm> {
        const { data } = await axios.get<StaffRoleForm>('/staff-roles/' + id + '/multilang');
        return data;
    }

    async createOrUpdate(form: StaffRoleForm, lang: string) {
        const isUpdate = form.id !== -1;

        if (!isUpdate) {
            await axios.post('/staff-roles', form.name);
        } else {
            await axios.patch('/staff-roles/' + form.id, form.name);
        }

        store.dispatch('getStaffRoles', lang);
    }
}

export default new StaffRoleService();