
	import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
	import InfiniteScroll from '@/components/UI/InfiniteScroll.vue';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import { BreadcrumbStructureItem, Competition, Filter } from '@/interfaces';
	import { localizePath } from '@/plugins/i18n';
	import CompetitionService from '@/services/CompetitionService';
	import { useStore } from 'vuex';
	import ShowMoreFilters from '@/components/Frontend/NextCompetition/ShowMoreFilters.vue';
	import { MrUiKitList } from 'mr-ui-kit';

	export default defineComponent({
		name: 'NextCompetition',
		components: {
			InfiniteScroll,
			ShowMoreFilters,
			MrUiKitList,
		},
		setup() {
			const { t, locale } = useI18n();
			const router = useRouter();
			const isLoading = ref(false);
			const competitions = reactive<Competition[]>([]);
			const store = useStore();
			const guard = ref(false);
			const showMore = ref(false);
			const filter = ref<Filter>({
				limit: 8,
				offset: 0,
			});

			onMounted(() => {
				loadMore();

				store.commit('pushBreadcrumb', {
					label: t('home.nextCompetition.title'),
					path: '/next/competitions',
					type: BreadcrumbStructureItem.Menu,
				});
			});

			const reload = (toggle: boolean) => {
				window.scrollTo(0, 0);
				filter.value = {
					limit: 8,
					offset: 0,
				};
				showMore.value = toggle;
				competitions.splice(0, competitions.length);
				loadMore();
			};

			const loadMore = async () => {
				if (!isLoading.value) {
					isLoading.value = true;
					const loaded = await CompetitionService.getNext(locale.value, {
						limit: filter.value.limit,
						offset: filter.value.offset,
						showAll: showMore.value,
					});
					competitions.push(...loaded);
					isLoading.value = false;

					if (loaded.length === 0) {
						guard.value = true;
					}

					filter.value.offset += 8;
				}
			};

			const onClick = (competition: Competition) => {
				router.push(
					localizePath({
						path: '/competitions/' + competition.id,
					}),
				);
			};

			return {
				t,
				loadMore,
				onClick,
				reload,
				showMore,
				guard,
				competitions,
				isLoading,
			};
		},
	});
