import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "infinite-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.infiniteScrollContainerClasses)
    }, [
      _renderSlot(_ctx.$slots, "title"),
      _createElementVNode("div", {
        class: _normalizeClass(["row", _ctx.rowClasses])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}