
import {
  Organization,
  OrganizationForm,
  PaginationModel,
  RouteEntity,
} from "@/interfaces";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  MrUiKitCard,
  MrUiKitButton,
  MrUiKitModal,
  MrUiKitTable,
  MrUiKitPagination,
  MrUiKitToggle,
} from "mr-ui-kit";
import FederationService from "@/services/FederationService";
import OrganizationService from "@/services/OrganizationService";
import { useStore } from "vuex";

import CreateOrganizationModal from "@/components/Backoffice/Modals/CreateOrganizationModal.vue";
import ManageAccountModal from "@/components/Backoffice/Modals/ManageAccountModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import axios from "axios";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "SportClubList",
  components: {
    MrUiKitCard,
    MrUiKitButton,
    MrUiKitModal,
    MrUiKitTable,
    MrUiKitPagination,
    MrUiKitToggle,
    CreateOrganizationModal,
    ManageAccountModal,
    DeleteEntityModal,
  },
  setup(props) {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const entityId = ref<number>(-1);
    const showModal = ref<boolean>(false);
    const showAccountModal = ref<boolean>(false);
    const showDeleteModal = ref(false);
    const selectedSportClub = ref<Organization | null>(null);
    const organizations = ref<Organization[]>([]);
    const isLoading = ref<boolean>(false);
    const currentSearch = ref<string | null>(null);
    const pagination = reactive<PaginationModel>({
      current: 0,
      count: 0,
      limit: 10,
    });
    const columns = reactive([
      {
        label: computed(() => t("common.fields.fullname")),
        field: "fullname",
      },
      {
        label: computed(() => t("common.fields.isNational")),
        field: "isNational",
      },
      {
        label: "",
        field: "action",
      },
    ]);

    onMounted(async () => {
      entityId.value = +route.params.id;
      onInit();
    });

    const onInit = async () => {
      pagination.current = 1;
      const total = await FederationService.countSubList(
        entityId.value,
        RouteEntity.Organization,
        locale.value
      );
      pagination.count = total.count;
      await loadMore(0);
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;

        organizations.value = await FederationService.getOrganizations(
          entityId.value,
          locale.value,
          {
            limit: 10,
            offset,
            search: currentSearch.value != null ? currentSearch.value : null,
          }
        );

        isLoading.value = false;
      }
    };

    const onCreate = async (form: OrganizationForm) => {
      try {
        await OrganizationService.create(entityId.value, form);
        await onInit();
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }
      showModal.value = false;
    };

    const onOpenAccount = (item: Organization) => {
      selectedSportClub.value = item;
      showAccountModal.value = true;
    };

    const onNavigate = (item: Organization) => {
      router.push(
        localizePath({
          path: "/organizations/" + item.id + "/edit",
        })
      );
    };

    const onNavigateFrontOffice = (item: Organization) => {
      router.push(
        localizePath({
          path: "/organizations/" + item.id,
        })
      );
    };

    const onSearch = (search: string) => {
      if (search !== "") {
        currentSearch.value = search;
      } else {
        currentSearch.value = null;
      }
      onInit();
    };

    const onDelete = (item: Organization) => {
      selectedSportClub.value = item;
      showDeleteModal.value = true;
    };

    const onSubmitDelete = async () => {
      if (selectedSportClub.value) {
        try {
          await axios.delete("/organizations/" + selectedSportClub.value.id);
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const searchLabel = computed(() => {
      return t('common.findDots');
    })

    return {
      t,
      loadMore,
      onCreate,
      onDelete,
      onNavigate,
      onSearch,
      columns,
      organizations,
      searchLabel,
      pagination,
      showModal,
      showAccountModal,
      selectedSportClub,
      onOpenAccount,
      onSubmitDelete,
      showDeleteModal,
      organizationEntity: RouteEntity.Organization,
      onNavigateFrontOffice,
    };
  },
});
