
import { AccountEntity, BreadcrumbInstance, BreadcrumbStructureItem, PaginationModel, RouteEntity } from '@/interfaces'
import AccountService from '@/services/AccountService';
import { defineComponent, onMounted, PropType, reactive, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { MrUiKitPagination, MrUiKitTable, MrUiKitButton } from 'mr-ui-kit'
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useStore } from 'vuex';

import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';
import { localizePath } from '@/plugins/i18n';

export default defineComponent({
    name: 'EntityList',
    components: {
        MrUiKitPagination,
        MrUiKitTable,
        MrUiKitButton,
        DeleteEntityModal
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Federation
        }
    },
    setup(props) {
        const { t, locale } = useI18n();
        const store = useStore();
        const router = useRouter();
        const isLoading = ref<boolean>(false);
        const showDeleteModal = ref(false);
        const selectedEntity = ref<AccountEntity | null>(null);
        const entities = ref<AccountEntity[]>([]);
        const columns = reactive([
            {
                label: computed(() => t('account.profileManaged.table.name')),
                field: 'name'
            },
            {
                label: '',
                field: 'action'
            }
        ]);
        const pagination = reactive<PaginationModel>({
            current: 1,
            count: 0,
            limit: 9
        });

        onMounted(async () => {
            await onInit();
        })

        const onInit = async () => {
            await loadMore(0);
            pagination.current = 1;
        }

        const loadMore = async (offset: number) => {
            if (!isLoading.value) {
                const loadedCount = await AccountService.countSubList(props.entity);
                pagination.count = loadedCount.count;
                entities.value = await AccountService.getEntities(props.entity, { limit: 9, offset }, locale.value);
                isLoading.value = false;
            }
        }

        const onNavigate = (item: AccountEntity) => {
            router.push(localizePath({
                path: '/' + props.entity + '/' + item.id + '/edit'
            }));

            let type = BreadcrumbStructureItem.Federation;
            switch (props.entity) {
                case RouteEntity.Federation:
                    type = BreadcrumbStructureItem.Federation;
                    break;
                case RouteEntity.Organization:
                    type = BreadcrumbStructureItem.Organization;
                    break;
                case RouteEntity.Trainer:
                    type = BreadcrumbStructureItem.Trainer;
                    break;
                case RouteEntity.Athlete:
                    type = BreadcrumbStructureItem.Athlete;
                    break;
            }
            store.commit('pushBreadcrumb', {
                path: '/' + props.entity + '/' + item.id + '/edit',
                label: item.name,
                type,
                isBackoffice: true
            });
        }

        const onDelete = async (item: AccountEntity) => {
            selectedEntity.value = item;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedEntity.value) {
                try {    
                    await axios.delete('/' + props.entity + '/' + selectedEntity.value.id);
                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        return {
            t,
            pagination,
            loadMore,
            onNavigate,
            onDelete,
            entities,
            columns,
            selectedEntity,
            showDeleteModal,
            onSubmitDelete
        }
    },
})
