
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import Spinner from '@/components/UI/Spinner.vue';

export default defineComponent({
    name: 'InfiniteScroll',
    emits: ['load'],
    components: {
        Spinner
    },
    props: {
        guard: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        containerClasses: {
            type: Array,
            default: []
        },
        rowClasses: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        onMounted(() => {
            window.addEventListener('scroll', onLoad);
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', onLoad);
        });

        const onLoad = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !props.isLoading && !props.guard) {
                emit('load');
            }
        }

        const infiniteScrollContainerClasses = computed(() => {
            if (props.containerClasses && props.containerClasses.length > 0) {
                return props.containerClasses
            };

            return ['pt-4']
        })

        return {
            onLoad,
            infiniteScrollContainerClasses
        }
    },
})
