
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { MrUiKitButton, MrUiKitTable, MrUiKitPagination, MrUiKitSearch, MrUiKitSelect } from 'mr-ui-kit';
import { Discipline, PaginationModel, Sport } from '@/interfaces';
import { useI18n } from 'vue-i18n';
import DisciplineService from '@/services/DisciplineService';
import axios from 'axios';
import { useStore } from 'vuex';

import DisciplineModal from '@/components/Backoffice/Modals/DisciplineModal.vue';
import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';

export default defineComponent({
    name: 'DisciplineList',
    emits: ['update', 'close'],
    components: {
        MrUiKitButton,
        MrUiKitTable,
        MrUiKitPagination,
        DeleteEntityModal,
        DisciplineModal,
        MrUiKitSelect,
        MrUiKitSearch
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const store = useStore();
        const disciplines = ref<Discipline[]>([]);
        const showDeleteModal = ref(false);
        const search = ref<string>('');
        const selectedSport = ref<Sport | null>(null);
        const selectedDiscipline = ref<Discipline | null>(null);
        const isLoading = ref(false);
        const pagination = reactive<PaginationModel>({
            current: 0,
            count: 0,
            limit: 10
        });
        const columns = reactive([
            {
                label: computed(() => t('common.fields.name')),
                field: 'name'
            },
            {
                label: 'Sport',
                field: 'sport'
            },
            {
                label: '',
                field: 'action'
            }
        ]);

        onMounted(async () => {
            await onInit();
        })

        const onInit = async () => {
            pagination.current = 1;
            const total = await DisciplineService.count(locale.value, search.value, selectedSport.value != null ? selectedSport.value.id : null);
            pagination.count = total.count;
            await onLoad(0);
        }

        const onLoad = async (offset: number) => {
            if (!isLoading.value) {
                isLoading.value = true;

                disciplines.value = await DisciplineService.get(locale.value, {
                    limit: 10,
                    offset,
                    search: search.value != '' ? search.value : null,
                    sportId: selectedSport.value != null ? selectedSport.value.id : null
                });

                isLoading.value = false;
            }
        }

        const onDelete = async (sport: Discipline) => {
            selectedDiscipline.value = sport;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedDiscipline.value) {
                try {    
                    await axios.delete('/disciplines/' + selectedDiscipline.value.id);
                    selectedDiscipline.value = null;
                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        const onUpdate = (item: Discipline) => {
            selectedDiscipline.value = item;
            emit('update');
        }

        const onCloseModal = async () => {
            selectedDiscipline.value = null;
            await onInit();
            emit('close');
        }

        const onCloseDelete = () => {
            showDeleteModal.value = false;
            selectedDiscipline.value = null;
        }

        watch(search, (nS: string) => {
            onSearch(nS);
        })

        watch(selectedSport, (nSport: Sport | null) => {
            onChangeSport(nSport);
        })

        const onSearch = (newSearch: string) => {
            search.value = newSearch;
            onInit();
        }

        const onChangeSport = (newSport: Sport | null) => {
            selectedSport.value = newSport;
            onInit();
        }

        return {
            t,
            onLoad,
            onDelete,
            onUpdate,
            onSubmitDelete,
            onCloseModal,
            onCloseDelete,
            onSearch,
            selectedSport,
            sports: store.getters.sports,
            showDeleteModal,
            selectedDiscipline,
            disciplines,
            columns,
            pagination,
            search
        }
    },
})
