import { CreateOrUpdateOtherResult, IntermiadiateForm, IntermiadiateMultiLanguage, OtherResultForm, OtherResultMultiLanguage, OtherResultMultiLanguageField, RouteEntity, SingleFormModels, Sport } from "@/interfaces";
import axios from "axios";

class OtherResultService {
    constructor() {}

    async createOrUpdate(form: OtherResultForm, isUpdate: boolean): Promise<void> {
        const body: CreateOrUpdateOtherResult = {
            it: {
                competition: form.competition.it,
                notes: form.notes != null ? form.notes.it : undefined,
                generalNotes: form.generalNotes != null ? form.generalNotes.it : undefined
            },
            en: {
                competition: form.competition.en,
                notes: form.notes != null ? form.notes.en : undefined,
                generalNotes: form.generalNotes != null ? form.generalNotes.en : undefined
            },
            sportId: form.sport?.id as number,
            disciplineId: form.discipline?.id as number,
            position: form.position != null ? +form.position : null,
            totPartecipant: form.totPartecipant != null ? +form.totPartecipant : null,
            isLeft: form.isLeft as boolean,
            competitionResultTypeId: form.competitionResultType?.id as number,
            leftResult: form.leftResult != null ? form.leftResult : null,
            rightResult: form.rightResult != null ? form.rightResult : null,
            opponentId: form.opponent ? form.opponent.id : null,
            entity: form.entity,
            entityId: form.entityId,
            intermiadiates: form.intermiadiates.map((i: IntermiadiateForm) => {
                return {
                    id: i.id,
                    competitionResultTypeId: i.competitionResultType ? i.competitionResultType.id : null,
                    isLeft: i.isLeft || false,
                    leftResult: i.leftResult != null ? i.leftResult : null,
                    rightResult: i.rightResult != null ? i.rightResult : null,
                    opponentId: i.opponent ? i.opponent.id : null,
                    it: {
                        notes: i.notes ? i.notes.it : undefined
                    },
                    en: {
                        notes: i.notes ? i.notes.en : undefined
                    }
                }
            }),
            attachments: form.attachments,
        }

        if (isUpdate) {
            await axios.patch('/others/results/' + form.id, body);
        } else {
            await axios.post('/others/results', body);
        }
    }

    async getMultiLanguageById(id: number, lang: string): Promise<SingleFormModels> {
        const { data } = await axios.get<OtherResultMultiLanguage>('/others/multilang/' + id + '?lang=' + lang);
        return {
            generalIntermiadiate: {
                competitionResultType: data.competitionResultType,
                isLeft: data.isLeft,
                leftResult: data.leftResult != null ? String(data.leftResult) : null,
                rightResult: data.rightResult != null ? String(data.rightResult) : null,
                opponent: data.opponent,
                notes: {
                    it: data.it.notes || '',
                    en: data.en.notes || ''
                }
            },
            model: {
                id: data.id,
                competition: {
                    it: data.it.competition,
                    en: data.en.competition
                },
                sport: data.sport as Sport,
                discipline: data.discipline,
                position: data.position ? String(data.position) : null,
                totPartecipant: data.totPartecipant ? String(data.totPartecipant) : null,
                intermiadiates: data.intermiadiates.map((i: IntermiadiateMultiLanguage) => {
                    return {
                        id: i.id,
                        competitionResultType: i.competitionResultType,
                        isLeft: i.isLeft,
                        leftResult: i.leftResult ? i.leftResult : null,
                        rightResult: i.rightResult ? i.rightResult : null,
                        opponent: i.opponent,
                        notes: {
                            it: i.it.notes ? i.it.notes : null,
                            en: i.en.notes ? i.en.notes : null
                        }
                    }
                }) as IntermiadiateForm[],
                attachments: data.attachments.map(a => {
                    return {
                        id: a.id,
                        file: {
                            name: a.filename
                        }
                    }
                }),
                entityId: -1,
                entity: RouteEntity.Organization,
                generalNotes: {
                    it: data.it.generalNotes ? data.it.generalNotes : '',
                    en: data.en.generalNotes ? data.en.generalNotes : ''
                }
            }
        };
    }
}

export default new OtherResultService();