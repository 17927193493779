
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  Federation,
  FederationForm,
  PaginationModel,
} from "@/interfaces";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  MrUiKitCard,
  MrUiKitTable,
  MrUiKitPagination,
  MrUiKitButton,
} from "mr-ui-kit";
import FederationService from "@/services/FederationService";
import axios from "axios";

import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import CreateFederationModal from "@/components/Backoffice/Modals/CreateFederationModal.vue";
import { useRouter } from "vue-router";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "FederationList",
  components: {
    MrUiKitCard,
    MrUiKitTable,
    MrUiKitPagination,
    MrUiKitButton,
    DeleteEntityModal,
    CreateFederationModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const isMounted = ref(false);
    const search = ref<string | null>(null);
    const showDeleteModal = ref(false);
    const showModal = ref(false);
    const federations = ref<Federation[]>([]);
    const selectedFederation = ref<Federation | null>(null);
    const isLoading = ref(false);
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "name",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      count: 0,
      current: 0,
      limit: 10,
    });

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const onInit = async () => {
      const total = await FederationService.count(locale.value, {
        search: search.value != null ? search.value : undefined,
      });
      pagination.count = total.count;
      pagination.current = 1;
      await loadMore(0);
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;

        federations.value = await FederationService.get(locale.value, {
          limit: 10,
          offset,
          search: search.value != null ? search.value : undefined,
        });
        isLoading.value = false;
      }
    };

    const onOpenModal = () => {
      selectedFederation.value = null;
      showModal.value = true;
    };

    const onDelete = (item: Federation) => {
      selectedFederation.value = item;
      showDeleteModal.value = true;
    };

    const onNavigate = (item: Federation) => {
      router.push(
        localizePath({
          path: "/federations/" + item.id + "/edit",
        })
      );

      store.commit("pushBreadcrumb", {
        label: item.fullname,
        path: "/federations/" + item.id + "/edit",
        type: BreadcrumbStructureItem.Federation,
        isBackoffice: true,
      });
    };

    const onNavigateFrontOffice = (item: Federation) => {
      router.push(
        localizePath({
          path: "/federations/" + item.id,
        })
      );
    };

    const onSubmitDelete = async () => {
      if (selectedFederation.value) {
        try {
          await axios.delete("/federations/" + selectedFederation.value.id);
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const onSubmitForm = async (form: FederationForm) => {
      showModal.value = false;
      try {
        await FederationService.create(form);
        await onInit();
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }
    };

    const onSearch = (newS: string) => {
      if (newS != "") {
        search.value = newS;
      } else {
        search.value = null;
      }

      onInit();
    };

    const searchLabel = computed(() => {
      return t("common.findDots");
    });

    return {
      t,
      onSubmitForm,
      onSubmitDelete,
      onDelete,
      onOpenModal,
      loadMore,
      onNavigate,
      onSearch,
      onNavigateFrontOffice,
      searchLabel,
      isMounted,
      showModal,
      showDeleteModal,
      federations,
      columns,
      pagination,
      selectedFederation,
    };
  },
});
