import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LandingSearch = _resolveComponent("LandingSearch")!
  const _component_LandingStats = _resolveComponent("LandingStats")!
  const _component_LandingNextCompetition = _resolveComponent("LandingNextCompetition")!
  const _component_LandingFederations = _resolveComponent("LandingFederations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LandingSearch),
    _createVNode(_component_LandingStats),
    _createVNode(_component_LandingNextCompetition),
    _createVNode(_component_LandingFederations)
  ]))
}