
	import { defineComponent, onMounted, ref } from 'vue';
	import InfiniteScroll from '@/components/UI/InfiniteScroll.vue';
	import { useI18n } from 'vue-i18n';
	import { MrUiKitCard, MrUiKiList } from 'mr-ui-kit';
	import {
		BreadcrumbStructureItem,
		Filter,
		National,
		Organization,
	} from '@/interfaces';
	import { useRoute, useRouter } from 'vue-router';
	import FederationService from '@/services/FederationService';
	import { localizePath } from '@/plugins/i18n';
	import { useStore } from 'vuex';

	export default defineComponent({
		name: 'NationalList',
		components: {
			MrUiKitCard,
			MrUiKiList,
			InfiniteScroll,
		},
		setup() {
			const { t, locale } = useI18n();
			const route = useRoute();
			const store = useStore();
			const router = useRouter();
			const nationals = ref<National[]>([]);
			const isLoading = ref<boolean>(false);
			const guard = ref(false);
			const federationId = ref<number>(-1);
			const filter = ref<Filter>({
				limit: 12,
				offset: 0,
			});

			onMounted(() => {
				federationId.value = +route.params.id;
				loadMore();
			});

			const loadMore = async () => {
				if (!isLoading.value) {
					isLoading.value = true;
					const loaded = await FederationService.getNationals(
						federationId.value,
						locale.value,
						filter.value,
					);
					nationals.value.push(...loaded);
					isLoading.value = false;

					if (loaded.length === 0) {
						guard.value = true;
					}

					filter.value.offset += 12;
				}
			};
			const onClick = (organization: Organization) => {
				router.push(
					localizePath({ path: '/organizations/' + organization.id }),
				);

				store.commit('pushBreadcrumb', {
					label: organization.fullname,
					path: '/organizations/' + organization.id,
					type: BreadcrumbStructureItem.Organization,
				});
			};

			return {
				t,
				loadMore,
				onClick,
				guard,
				isLoading,
				nationals,
			};
		},
	});
