
import { defineComponent } from 'vue'
import { computed } from 'vue'

export default defineComponent({
    name: 'ResultMedal',
    props: {
        position: {
            type: Number,
            default: 0
        },
        maxHeight: {
            type: Number,
            default: 40
        }
    },
    setup(props) {
        const showMedal = computed(() => {
            return [1, 2, 3].includes(props.position);
        })

        const medalImg = computed(() => {
            return require('@/assets/img/medals/' + props.position + '.png');
        })

        return {
            showMedal,
            medalImg,
            style: 'max-height: ' + props.maxHeight + 'px'
        }
    },
})
