
import { defineComponent, inject, onMounted } from 'vue'

import LandingSearch from '@/components/Frontend/Home/LandingSearch.vue';
import LandingStats from '@/components/Frontend/Home/LandingStats.vue';
import LandingNextCompetition from '@/components/Frontend/Home/LandingNextCompetition.vue';
import LandingFederations from '@/components/Frontend/Home/LandingFederations.vue';
import { BreadcrumbInstance, BreadcrumbStructureItem } from '@/interfaces';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'HomeView',
    components: {
        LandingSearch,
        LandingStats,
        LandingNextCompetition,
        LandingFederations
    },
    setup() {
        const store = useStore();

        onMounted(() => {
            window.scrollTo(0, 0);

            store.commit('setBreadcrumbRebuild');
            store.commit('pushBreadcrumb', {
                path: '/',
                label: 'Home',
                type: BreadcrumbStructureItem.Menu
            });
        });

        return {}
    },
})
