import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-498b9bc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row justify-content-between d-flex align-item-center",
  style: {"align-items":"center","min-height":"56px"}
}
const _hoisted_2 = { class: "col-md-4 col-12 pb-2" }
const _hoisted_3 = { class: "mb-0 text-capitalize" }
const _hoisted_4 = {
  key: 0,
  class: "col-md-4 col-12 pb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitSelect = _resolveComponent("MrUiKitSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.entityLabel), 1)
    ]),
    (_ctx.haveFilter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_MrUiKitSelect, {
            style: {"margin-bottom":"0px !important"},
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
            labelKey: "fullname",
            items: _ctx.items,
            multiple: false,
            onLoad: _ctx.onLoad,
            infiniteScroll: true,
            count: _ctx.count,
            label: _ctx.filterLabel,
            labelPosition: 'left',
            placeholder: ''
          }, null, 8, ["modelValue", "items", "onLoad", "count", "label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}