import { Count, Filter, GeneralFormatted, TrainerRoleForm } from "@/interfaces";
import store from "@/store";
import axios from "axios";
import FilterService from "./FilterService";

class TrainerRoleService {
    constructor() {}

    async count(lang: string, search: string | null): Promise<Count> {
        const { data } = await axios.get('/trainer-roles/count?lang=' + lang + (search != null ? '&search=' + search : ''));
        return data;
    }

    async get(lang: string, filter: Filter): Promise<GeneralFormatted[]> {
        const filters = FilterService.parseFilter(filter);
        const { data } = await axios.get<GeneralFormatted[]>('/trainer-roles?lang=' + lang + '&' + filters);
        return data;
    }

    async getMultiLang(id: number): Promise<TrainerRoleForm> {
        const { data } = await axios.get<TrainerRoleForm>('/trainer-roles/' + id + '/multilang');
        return data;
    }

    async createOrUpdate(form: TrainerRoleForm, lang: string): Promise<void> {
        const isUpdate = form.id !== -1;

        if (!isUpdate) {
            await axios.post('/trainer-roles', form.name);
        } else {
            await axios.patch('/trainer-roles/' + form.id, form.name);
        }

        store.dispatch('getTrainerRoles', lang);
    }
}

export default new TrainerRoleService();