import { Count, CreateOrUpdateInternationalCompetition, Filter, InternationalCompetition, InternationalCompetitionForm, InternationalCompetitionMultiLanguage } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";
import moment from 'moment';

class InternationalCompetitionService {
    constructor() {}

    async count(lang: string, search: string | null): Promise<Count> {
        const { data } = await axios.get('/internationals/competitions/count?lang=' + lang + (search ? '&search=' + search : ''));
        return data;
    }

    async get(lang: string, filter: Filter): Promise<InternationalCompetition[]> {
        const filters = FilterService.parseFilter(filter);
        const { data } = await axios.get<InternationalCompetition[]>('/internationals/competitions?lang=' + lang + '&' + filters);
        return data;
    }

    async getNext(lang: string, filter: Filter): Promise<InternationalCompetition[]> {
        const filters = FilterService.parseFilter(filter);
        const { data } = await axios.get<InternationalCompetition[]>('/internationals/competitions/next?lang=' + lang + '&' + filters);
        return data;
    }

    async getMultiLang(id: number, lang: string): Promise<InternationalCompetitionForm> {
        const { data } = await axios.get<InternationalCompetitionMultiLanguage>('/internationals/competitions/' + id + '/multilang?lang=' + lang);
        return {
            id,
            photo: {
                file: data.photo ? data.photo : null,
                url: data.photo ? data.photo.url : null
            },
            startedAt: moment(data.startedAt, 'DD/MM/YYYY').toDate(),
            endedAt: moment(data.endedAt, 'DD/MM/YYYY').toDate(),
            federations: data.federations,
            location: data.location,
            name: {
                it: data.it && data.it.name ? data.it.name : '',
                en: data.en && data.en.name ? data.en.name : ''
            },
            haveMedals: data.haveMedals === true
        }
    }
    
    async createOrUpdate(form: InternationalCompetitionForm): Promise<void> {
        const isUpdate = form.id !== -1;
        const body: CreateOrUpdateInternationalCompetition = {
            photoId: form.photo.file.id,
            federations: form.federations.map(f => f.id),
            location: form.location,
            startedAt: form.startedAt.toISOString(),
            endedAt: form.endedAt.toISOString(),
            haveMedals: form.haveMedals,
            it: {
                name: form.name.it
            },
            en: {
                name: form.name.en
            }
        }

        if (!isUpdate) {
            await axios.post('/internationals/competitions', body);
        } else {
            await axios.patch('/internationals/competitions/' + form.id, body);
        }
    }
}

export default new InternationalCompetitionService();