import { Count, Filter, MultiLanguageField, Sport, SportForm } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";
import store from '@/store';

class SportService {
    constructor() {}

    async count(lang: string, search: string | null): Promise<Count> {
        const response = await axios.get<Count>('/sports/count?lang=' + lang + (search != null ? '&search=' + search : ''));
        return response.data;
    }

    async get(lang: string, filter: Filter): Promise<Sport[]> {
        const parsed = FilterService.parseFilter(filter);
        console.log(parsed)
        const  { data } = await axios.get<Sport[]>('/sports?lang=' + lang + '&' + parsed);
        return data;
    }

    async getMultiLang(id: number): Promise<SportForm> {
        const { data } = await axios.get<SportForm>('/sports/' + id + '/multilang');
        return data;
    }

    async createOrUpdate(form: SportForm, lang: string): Promise<void> {
        const isUpdate = form.id !== -1;

        if (isUpdate) {
            await axios.patch('/sports/' + form.id, form.name);
        } else {
            await axios.post('/sports', form.name);
        }

        store.dispatch('getSports', lang);
    }
}

export default new SportService();