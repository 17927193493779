import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76ac86a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "result-block-form mb-4 mx-n4 p-4",
  style: {"background":"rgba(0, 0, 0, 0.02)","border-radius":"15px"}
}
const _hoisted_2 = {
  key: 0,
  class: "result-container"
}
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col-sm-2 mb-4" }
const _hoisted_5 = { class: "col-sm-10 mb-4" }
const _hoisted_6 = {
  key: 1,
  class: "row"
}
const _hoisted_7 = { class: "side-container col-sm-12" }
const _hoisted_8 = { class: "row d-flex align-items-center" }
const _hoisted_9 = { class: "result-double-container col-sm-12" }
const _hoisted_10 = { class: "row d-flex align-items-center" }
const _hoisted_11 = { class: "col-sm-2 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitSelect = _resolveComponent("MrUiKitSelect")!
  const _component_MrUiKitLabel = _resolveComponent("MrUiKitLabel")!
  const _component_MrUiKitInputMask = _resolveComponent("MrUiKitInputMask")!
  const _component_MrUiKitMultiLanguage = _resolveComponent("MrUiKitMultiLanguage")!
  const _component_MrUiKitInput = _resolveComponent("MrUiKitInput")!
  const _component_MrUiKitTag = _resolveComponent("MrUiKitTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitSelect, {
      modelValue: _ctx.model.competitionResultType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.competitionResultType) = $event)),
      status: _ctx.status,
      name: _ctx.status != null ? 'competitionResultType' : null,
      onTouch: _ctx.touchResult,
      items: _ctx.resultTypes,
      label: _ctx.t('common.fields.competitionResultType'),
      labelPosition: "left",
      key: "id",
      labelKey: "name",
      placeholder: _ctx.t('common.placeholders.position')
    }, null, 8, ["modelValue", "status", "name", "onTouch", "items", "label", "placeholder"]),
    (_ctx.model.competitionResultType != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.model.competitionResultType.haveDoubleResult)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_MrUiKitLabel, { class: "col-sm-2 col-12" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('common.result')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_MrUiKitInputMask, {
                    modelValue: _ctx.model.leftResult,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.leftResult) = $event)),
                    mask: _ctx.model.competitionResultType.regex,
                    style: {"margin-bottom":"0 !important"}
                  }, null, 8, ["modelValue", "mask"])
                ]),
                _createVNode(_component_MrUiKitMultiLanguage, {
                  modelValue: _ctx.model.notes,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.notes) = $event)),
                  languages: _ctx.languages,
                  label: _ctx.t('common.notes'),
                  "label-position": 'left',
                  currentLanguage: _ctx.currentLanguage,
                  style: {"margin-bottom":"0 !important"}
                }, null, 8, ["modelValue", "languages", "label", "currentLanguage"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_MrUiKitLabel, { class: "col-sm-2 col-12 mb-4" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('common.opponent')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.model.isLeft)
                      ? (_openBlock(), _createBlock(_component_MrUiKitInput, {
                          key: 0,
                          class: "col-md-4 col-12",
                          modelValue: _ctx.text,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.text) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"]))
                      : (_openBlock(), _createBlock(_component_MrUiKitTag, {
                          key: 1,
                          class: "col-md-4 col-12",
                          items: _ctx.opponents,
                          modelValue: _ctx.model.opponent,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.opponent) = $event)),
                          onSearch: _ctx.onSearchOpponent,
                          onEnter: _ctx.onEnterOpponent,
                          labelKey: "name"
                        }, null, 8, ["items", "modelValue", "onSearch", "onEnter"])),
                    _createElementVNode("i", {
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleSide && _ctx.toggleSide(...args))),
                      style: {"cursor":"pointer"},
                      class: "col-md-2 col-12 bi-arrow-left-right d-flex justify-content-center mb-3"
                    }),
                    (!_ctx.model.isLeft)
                      ? (_openBlock(), _createBlock(_component_MrUiKitInput, {
                          key: 2,
                          class: "col-md-4 col-12",
                          modelValue: _ctx.text,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.text) = $event)),
                          disabled: true
                        }, null, 8, ["modelValue"]))
                      : (_openBlock(), _createBlock(_component_MrUiKitTag, {
                          key: 3,
                          class: "col-md-4 col-12",
                          items: _ctx.opponents,
                          modelValue: _ctx.model.opponent,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.opponent) = $event)),
                          onSearch: _ctx.onSearchOpponent,
                          onEnter: _ctx.onEnterOpponent,
                          labelKey: "name"
                        }, null, 8, ["items", "modelValue", "onSearch", "onEnter"]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_MrUiKitLabel, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('common.result')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_MrUiKitInput, {
                      modelValue: _ctx.model.leftResult,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.leftResult) = $event)),
                      class: "col-sm-5"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_MrUiKitInput, {
                      modelValue: _ctx.model.rightResult,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.rightResult) = $event)),
                      class: "col-sm-5"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_MrUiKitMultiLanguage, {
                      modelValue: _ctx.model.notes,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.notes) = $event)),
                      languages: _ctx.languages,
                      label: _ctx.t('common.notes'),
                      "label-position": 'left',
                      currentLanguage: _ctx.currentLanguage,
                      class: "sol-sm-12",
                      style: {"margin-bottom":"0 !important"}
                    }, null, 8, ["modelValue", "languages", "label", "currentLanguage"])
                  ])
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}