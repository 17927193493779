import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "athlete-ranking"
}
const _hoisted_2 = { class: "pt-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "row gx-lg-7" }
const _hoisted_5 = {
  key: 0,
  class: "col-sm-6 col-lg-4 mb-5"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "mt-3" }
const _hoisted_8 = { class: "fs-5" }
const _hoisted_9 = {
  key: 1,
  class: "col-sm-6 col-lg-4 mb-5"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "mt-3" }
const _hoisted_12 = { class: "fs-5" }
const _hoisted_13 = {
  key: 2,
  class: "col-sm-6 col-lg-4 mb-5"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "mt-3" }
const _hoisted_16 = { class: "fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!

  return (_ctx.athlete != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.t('common.ranking')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.athlete.internalCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_MrUiKitCard, { classes: ['h-100'] }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        style: {"max-height":"40px"},
                        src: _ctx.athleteImg
                      }, null, 8, _hoisted_6),
                      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.athlete.internalCode), 1),
                      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.t('common.internalCode')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.athlete.rankingPosition)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_MrUiKitCard, { classes: ['h-100'] }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        style: {"max-height":"40px"},
                        src: _ctx.actualPosImg
                      }, null, 8, _hoisted_10),
                      _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.athlete.rankingPosition) + "º ", 1),
                      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.t('common.rankingPosition')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.athlete.bestRankingPosition)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_MrUiKitCard, { classes: ['h-100'] }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        style: {"max-height":"40px"},
                        src: _ctx.bestPosImg
                      }, null, 8, _hoisted_14),
                      _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.athlete.bestRankingPosition) + "º ", 1),
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t('common.bestRankingPosition')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}