
	import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue';
	import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { Athlete, RouteEntity, TagForm } from '@/interfaces';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
	import { useStore } from 'vuex';
	import OrganizationService from '@/services/OrganizationService';
import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'CreateTagModal',
		emits: ['submit', 'close'],
		components: {
			MrUiKitModal,
			MrUiKitButton,
			MrUiKitForm,
		},
		props: {
			entity: {
				type: Number,
				default: -1,
			},
			modelValue: {
				type: Object as PropType<TagForm>,
				default: null,
			},
		},
		setup(props, { emit }) {
			const isMounted = ref(false);
			const { t, locale } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});
			const store = useStore();
			const model = ref<TagForm>(props.modelValue || {
				athletes: [],
				name: {
					it: '',
					en: ''
				}
			});
			const rulesConfig = reactive({});
			const count = ref<number>(0);
			const offset = ref<number>(0);
			const loadedAthletes = reactive<Athlete[]>([]);
			const fields = reactive([
				{
					component: 'MrUiKitSelect',
					name: 'athletes',
					props: {
						label: computed(() => t('common.athletes')),
						labelPosition: 'left',
						items: loadedAthletes,
						infiniteScroll: true,
						count: count,
						multiple: true,
						labelKey: 'fullname',
					}
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'name',
					props: {
						label: computed(() => t('common.fields.name')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
			]);

			onMounted(async () => {
				const total = await OrganizationService.countSubList(
					props.entity,
					RouteEntity.Athlete,
					locale.value,
				);
				count.value = total.count;
				isMounted.value = true;
			});

			const loadAthletes = async () => {
				const loaded = await OrganizationService.getAthletes(
					props.entity,
					locale.value,
					{
						limit: 10,
						offset: offset.value,
					},
				);
				loadedAthletes.push(...loaded);
				offset.value += 10;
			};

			const onSubmit = () => {
				emit('submit', model.value);
			};

			const onClose = () => {
				emit('close');
			};

			const onUpdate = async (event: any) => {
				switch (event.type) {
					case 'load':
						await loadAthletes();
						break;
				}
			};

			return {
				t,
				onSubmit,
				onClose,
				loadAthletes,
				onUpdate,
				rulesConfig,
				model,
				fields,
				isMounted,
			};
		},
	});
