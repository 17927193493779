
import {
  Federation,
  SocialItem,
  RouteEntity,
  BreadcrumbInstance,
  BreadcrumbStructureItem,
} from "@/interfaces";
import FederationService from "@/services/FederationService";
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { MrUiKitProfile, MrUiKitNavTab, MrUiKitTab } from "mr-ui-kit";

import Biography from "@/components/Frontend/Common/Biography.vue";
import StaffList from "@/components/Frontend/Common/StaffList.vue";
import NationalList from "@/components/Frontend/Federation/NationalList.vue";
import SportClubList from "@/components/Frontend/Federation/SportClubList.vue";
import TrainerList from "@/components/Frontend/Common/TrainerList.vue";
import AthleteList from "@/components/Frontend/Common/AthleteList.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FederationView",
  components: {
    MrUiKitProfile,
    MrUiKitNavTab,
    MrUiKitTab,
    Biography,
    StaffList,
    NationalList,
    SportClubList,
    TrainerList,
    AthleteList,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const federationId = ref(-1);
    const { t, locale } = useI18n();
    const currentFederation = ref<Federation | null>(null);
    const isMounted = ref<boolean>(false);
    const totalCounts = reactive({
      organizations: {
        count: 0,
      },
      trainers: {
        count: 0,
      },
      athletes: {
        count: 0,
      },
    });

    onMounted(async () => {
      federationId.value = +route.params.id;
      currentFederation.value = await FederationService.getById(
        federationId.value,
        locale.value
      );

      totalCounts.organizations = await FederationService.countSubList(
        federationId.value,
        RouteEntity.Organization,
        locale.value
      );
      totalCounts.trainers = await FederationService.countSubList(
        federationId.value,
        RouteEntity.Trainer
      );
      totalCounts.athletes = await FederationService.countSubList(
        federationId.value,
        RouteEntity.Athlete
      );

      store.commit('setBreadcrumbRebuild');
      store.commit("pushBreadcrumb", {
        path: "/federations/" + federationId.value,
        label: currentFederation.value.fullname,
        type: BreadcrumbStructureItem.Federation,
        data: {
          federation: currentFederation.value,
        },
      });

      isMounted.value = true;
    });

    onBeforeUpdate(() => {
      if (federationId.value !== +route.params.id) {
        federationId.value = +route.params.id;
        window.location.reload();
      }
    });

    const bioItems = computed(() => {
      if (currentFederation.value == null) {
        return [];
      }

      return [
        {
          title: t("common.bioItems.foundation"),
          value: currentFederation.value.dateFoundation,
        },
        {
          title: t("common.bioItems.website"),
          value: currentFederation.value.website,
        },
        {
          title: t("common.bioItems.email"),
          visible: currentFederation.value.publicEmail,
          value: currentFederation.value.email,
        },
        {
          title: t("common.bioItems.telephone"),
          visible: currentFederation.value.publicTelephone,
          value: currentFederation.value.telephone,
        },
      ];
    });

    const socialItems = computed<SocialItem>(() => {
      if (currentFederation.value == null) {
        return {};
      }

      return {
        facebook: currentFederation.value.facebook,
        instagram: currentFederation.value.instagram,
      };
    });

    const profileSubtitle = computed(() => {
      return (
        totalCounts.organizations.count +
        " " +
        t("common.organizations") +
        " - " +
        totalCounts.trainers.count +
        " " +
        t("common.trainers") +
        " - " +
        totalCounts.athletes.count +
        " " +
        t("common.athletes")
      );
    });

    const logo = computed(() => {
      return currentFederation.value?.logo
        ? currentFederation.value.logo.url
        : null;
    });

    const cover = computed(() => {
      return currentFederation.value?.cover
        ? currentFederation.value.cover.original
        : require('@/assets/img/copertina_fed_base.jpg');
    });

    return {
      t,
      logo,
      federationEntity: RouteEntity.Federation,
      federation: currentFederation,
      cover,
      bioItems,
      socialItems,
      isMounted,
      totalCounts,
      profileSubtitle,
    };
  },
});
