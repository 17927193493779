import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showMedal)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "img-fluid",
        style: _normalizeStyle(_ctx.style),
        src: _ctx.medalImg
      }, null, 12, _hoisted_1))
    : _createCommentVNode("", true)
}