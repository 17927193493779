
import {
  Athlete,
  AthleteForm,
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  Organization,
  PaginationModel,
  RouteEntity,
} from "@/interfaces";
import FederationService from "@/services/FederationService";
import OrganizationService from "@/services/OrganizationService";
import TrainerService from "@/services/TrainerService";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  MrUiKitCard,
  MrUiKitButton,
  MrUiKitModal,
  MrUiKitTable,
  MrUiKitPagination,
} from "mr-ui-kit";
import AthleteService from "@/services/AthleteService";
import { useStore } from "vuex";
import axios from "axios";

import CreateAthleteModal from "@/components/Backoffice/Modals/CreateAthleteModal.vue";
import ManageAccountModal from "@/components/Backoffice/Modals/ManageAccountModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "AthleteList",
  components: {
    MrUiKitCard,
    MrUiKitButton,
    MrUiKitModal,
    MrUiKitTable,
    MrUiKitPagination,
    CreateAthleteModal,
    ManageAccountModal,
    DeleteEntityModal,
  },
  props: {
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Federation,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const store = useStore();
    const showAccountModal = ref(false);
    const selectedAthlete = ref<Athlete | null>(null);
    const showDeleteModal = ref(false);
    const showAddModal = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const entityId = ref<number>(-1);
    const isLoading = ref<boolean>(false);
    const currentSearch = ref<string | null>(null);
    const athletes = ref<Athlete[]>([]);
    const newAthlete = ref<AthleteForm>({
      id: -1,
      userCredentials: [],
      organizations: [],
      photo: {},
      firstname: "",
      lastname: "",
    });
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "fullname",
      },
      {
        label: computed(() => t("common.organizations")),
        field: "organizations",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: 10,
    });

    onMounted(async () => {
      entityId.value = +route.params.id;
      onInit();
    });

    const onInit = async () => {
      pagination.current = 1;
      switch (props.entity) {
        case RouteEntity.Federation:
          const fedCount = await FederationService.countSubList(
            entityId.value,
            RouteEntity.Athlete
          );
          pagination.count = fedCount.count;
          break;
        case RouteEntity.Organization:
          const orgCount = await OrganizationService.countSubList(
            entityId.value,
            RouteEntity.Athlete
          );
          pagination.count = orgCount.count;
          break;
        case RouteEntity.Trainer:
          const trainerCount = await TrainerService.countSubList(
            entityId.value,
            RouteEntity.Athlete
          );
          pagination.count = trainerCount.count;
          break;
      }
      await loadMore(0);
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;
        switch (props.entity) {
          case RouteEntity.Federation:
            athletes.value = await FederationService.getAthletes(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
          case RouteEntity.Organization:
            athletes.value = await OrganizationService.getAthletes(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
          case RouteEntity.Trainer:
            athletes.value = await TrainerService.getAthletes(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
        }
        isLoading.value = false;
      }
    };

    const onOpenAccount = (item: Athlete) => {
      selectedAthlete.value = item;
      showAccountModal.value = true;
    };

    const onNavigate = (item: Athlete) => {
      router.push(
        localizePath({
          path: "/athletes/" + item.id + "/edit",
        })
      );
    };

    const onNavigateFrontOffice = (item: Athlete) => {
      router.push(
        localizePath({
          path: "/athletes/" + item.id,
        })
      );
    };

    const onSearch = (search: string) => {
      if (search != "") {
        currentSearch.value = search;
      } else {
        currentSearch.value = null;
      }
      onInit();
    };

    const onDelete = (item: Athlete) => {
      selectedAthlete.value = item;
      showDeleteModal.value = true;
    };

    const onSubmitDelete = async () => {
      if (selectedAthlete.value) {
        try {
          await axios.delete("/athletes/" + selectedAthlete.value.id);
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const onOpenAdd = () => {
      showAddModal.value = true;
      newAthlete.value = {
        id: -1,
        userCredentials: [],
        organizations: [],
        photo: {},
        firstname: "",
        lastname: "",
      };
    };

    const goToOrganization = (organization: Organization) => {
      router.push(
        localizePath({
          path: "/organizations/" + organization.id + "/edit",
        })
      );

      store.commit("pushBreadcrumb", {
        label: organization.fullname,
        path: "/organizations/" + organization.id + "/edit",
        type: BreadcrumbStructureItem.Organization,
      });
    };

    const onCreate = async (athlete: AthleteForm) => {
      try {
        await AthleteService.create(athlete);
        await loadMore(0);
        showAddModal.value = false;
        pagination.current = 1;
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }
    };

    const searchLabel = computed(() => {
      return t('common.findDots');
    })

    return {
      t,
      onNavigate,
      onDelete,
      loadMore,
      onOpenAdd,
      onCreate,
      onSearch,
      onSubmitDelete,
      goToOrganization,
      onNavigateFrontOffice,
      newAthlete,
      searchLabel,
      pagination,
      athletes,
      columns,
      entityId,
      showAddModal,
      showAccountModal,
      athleteEntity: RouteEntity.Athlete,
      selectedAthlete,
      onOpenAccount,
      showDeleteModal,
    };
  },
});
