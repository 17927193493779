
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import {
  MrUiKitButton,
  MrUiKitSelect,
  MrUiKitToggle,
  MrUiKitInput,
  MrUiKitMultiLanguage,
  MrUiKitMultiUpload,
  MrUiKitAddField,
} from "mr-ui-kit";
import {
  IntermiadiateForm,
  Organization,
  RouteEntity,
  TeamResultForm,
} from "@/interfaces";
import { useStore } from "vuex";
import ResultBlockForm from "@/components/Backoffice/TeamResult/ResultBlockForm.vue";
import CompetitionService from "@/services/CompetitionService";
import { useRoute } from "vue-router";
import FederationService from "@/services/FederationService";
import TeamResultService from "@/services/TeamResultService";
import FileService from "@/services/FileService";
import { currentI18nLanguage } from "@/plugins/i18n";
import TagService from "@/services/TagService";
import * as validators from "@vuelidate/validators";
import i18nValidators from "@/utils/i18n-validators";
import customValidators from "@/utils/custom-validator";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "TeamForm",
  emits: ["submit", "back"],
  components: {
    MrUiKitButton,
    MrUiKitSelect,
    MrUiKitToggle,
    MrUiKitInput,
    MrUiKitMultiLanguage,
    MrUiKitMultiUpload,
    MrUiKitAddField,
    ResultBlockForm,
  },
  props: {
    modelValue: {
      type: Object as PropType<TeamResultForm>,
      default: null,
    },
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Organization,
    },
    value: {
      type: Number,
      default: null,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();
    const entityId = ref<number>(-1);
    const toggleIsOrganizationItems = ref([
      {
        label: computed(() => t("tabs.athletes")),
        value: false,
      },
      {
        label: computed(() => t("tabs.organizations")),
        value: true,
      },
    ]);
    const withI18nMessage = validators.createI18nMessage({ t });
    const required = withI18nMessage(validators.required, {
      messagePath: i18nValidators.required,
    });
    const generalIntermiadiate = ref({
      competitionResultType: null,
      isLeft: true,
      leftResult: "0",
      rightResult: null,
      opponent: null,
      notes: {
        it: "",
        en: "",
      },
    });
    const model = ref<TeamResultForm>(
      props.modelValue || {
        competition: null,
        isOrganizationResult: false,
        federationId: 0,
        sport: null,
        discipline: null,
        organizations: [],
        trainers: [],
        tags: [],
        athletes: [],
        position: null,
        totPartecipant: null,
        intermiadiates: [],
        attachments: [],
        generalNotes: {
          it: "",
          en: "",
        },
      }
    );
    const teamResultHaveEntity = withI18nMessage(
      () =>
        customValidators.teamResultHaveEntity(
          model.value.organizations,
          model.value.trainers,
          model.value.athletes
        ),
      {
        messagePath: i18nValidators.teamResultHaveEntity,
      }
    );
    const hasPosition = withI18nMessage(
      () =>
        customValidators.hasPosition(
          model.value.position,
          generalIntermiadiate.value.competitionResultType
        ),
      {
        messagePath: i18nValidators.hasPosition,
      }
    );
    const intermiadiateTemplate = ref({
      competitionResultType: null,
      isLeft: true,
      leftResult: "0",
      rightResult: null,
      opponent: null,
      notes: {
        it: "",
        en: "",
      },
    });
    const isMounted = ref(false);
    const lists = ref<any>({
      competitions: [],
      organizations: [],
      trainers: [],
      tags: [],
      athletes: [],
    });
    const offset = ref<any>({
      competitions: 0,
      organizations: 0,
      trainers: 0,
      tags: 0,
      athletes: 0,
    });
    const counts = ref<any>({
      competitions: 0,
      organizations: 0,
      trainers: 0,
      tags: 0,
      athletes: 0,
    });
    const rulesConfig = reactive<any>({
      competition: {
        required,
      },
      federationId: {
        required,
      },
      sport: {
        required,
      },
      discipline: {
        required,
      },
      organizations: {
        teamResultHaveEntity,
      },
      trainers: {
        teamResultHaveEntity,
      },
      athletes: {
        teamResultHaveEntity,
      },
      position: {
        hasPosition,
      },
      competitionResultType: {
        hasPosition,
      },
    });
    const fullModel = computed(() => {
      return {...model.value, ...generalIntermiadiate.value}
    });
    const v$ = useVuelidate(rulesConfig, fullModel);

    onMounted(async () => {
      entityId.value = +route.params.id;

      if (model.value.federationId === 0) {
        model.value.federationId = await FederationService.getIdByEntity(
          entityId.value,
          props.entity
        );
      }
      if (props.value != null) {
        await retrieveResult();
      }

      const total = await CompetitionService.countCompetitionsByFederation(
        model.value.federationId,
        locale.value
      );
      counts.value.competitions = total.count;

      const totalOrganizations = await FederationService.countSubList(
        model.value.federationId,
        RouteEntity.Organization,
        locale.value
      );
      counts.value.organizations = totalOrganizations.count;

      const totalTrainers = await FederationService.countSubList(
        model.value.federationId,
        RouteEntity.Trainer,
        locale.value
      );
      counts.value.trainers = totalTrainers.count;

      const totalAthletes = await FederationService.countSubList(
        model.value.federationId,
        RouteEntity.Athlete,
        locale.value
      );
      counts.value.athletes = totalAthletes.count;

      if (model.value.organizations.length > 0) {
        const total = await TagService.countMulti(
          model.value.organizations.map((o: Organization) => o.id),
          locale.value
        );
        counts.value.tags = total.count;
      }

      isMounted.value = true;
    });

    const retrieveResult = async () => {
      const modelResult = await TeamResultService.getMultiLanguageById(
        props.value,
        locale.value
      );
      model.value = modelResult.model;
      generalIntermiadiate.value = modelResult.generalIntermiadiate as any;
    };

    const loadCompetitions = async () => {
      const loaded = await CompetitionService.getCompetitionsByFederation(
        model.value.federationId,
        locale.value,
        {
          limit: 10,
          offset: offset.value.competitions,
        }
      );
      lists.value.competitions.push(...loaded);
      offset.value.competitions += 10;
    };

    const loadOrganizations = async () => {
      const loaded = await FederationService.getOrganizations(
        model.value.federationId,
        locale.value,
        {
          limit: 10,
          offset: offset.value.organizations,
        }
      );
      lists.value.organizations.push(...loaded);
      offset.value.organizations += 10;

      // await loadTags();
    };

    const totalSelectedOrganizations = computed(
      () => model.value.organizations.length
    );
    watch(totalSelectedOrganizations, (newV, oldV) => {
      if (newV !== oldV && isMounted.value) {
        // reset
        loadTags(true);
      }
    });

    const loadTags = async (reset?: boolean) => {
      if (reset) {
        const total = await TagService.countMulti(
          model.value.organizations.map((o: Organization) => o.id),
          locale.value
        );
        counts.value.tags = total.count;
        offset.value.tags = 0;
        lists.value.tags = [];
        model.value.tags = [];
      }
      const loaded = await TagService.findMulti(
        model.value.organizations.map((o: Organization) => o.id),
        locale.value,
        {
          limit: 10,
          offset: offset.value.tags,
        }
      );
      lists.value.tags.push(...loaded);
      offset.value.tags += 10;
    };

    const loadTrainers = async () => {
      const loaded = await FederationService.getTrainers(
        model.value.federationId,
        locale.value,
        {
          limit: 10,
          offset: offset.value.trainers,
        }
      );
      lists.value.trainers.push(...loaded);
      offset.value.trainers += 10;
    };

    const loadAthletes = async () => {
      const loaded = await FederationService.getAthletes(
        model.value.federationId,
        locale.value,
        {
          limit: 10,
          offset: offset.value.athletes,
        }
      );
      lists.value.athletes.push(...loaded);
      offset.value.athletes += 10;
    };

    const onSubmit = async () => {
      try {
        await TeamResultService.createOrUpdate(
          {
            ...model.value,
            ...generalIntermiadiate.value,
          },
          props.value != null
        );
        emit("back");
        store.commit("setAlert", {
          type: "success",
          message:
            props.value != null
              ? t("messages.generalSuccess.update")
              : t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message:
            props.value != null
              ? t("messages.generalError.update")
              : t("messages.generalError.create"),
        });
      }
    };

    const onUpload = async ({ index, file }: any) => {
      const uploaded = await FileService.upload(file);
      model.value.attachments[index] = {
        ...model.value.attachments[index],
        id: uploaded.id,
        uploaded: true,
      };
    };

    const onBack = () => {
      emit("back");
    };

    const entityResultText = computed(() => {
      const tagAthletes = [];
      for (const t of model.value.tags) {
        if (t.athletes) {
          tagAthletes.push(...t.athletes);
        }
      }
      let entities: any[] = model.value.isOrganizationResult
        ? model.value.organizations
        : [...model.value.athletes, ...tagAthletes];

      // @DEPRECATED: 20230303
      // if (!model.value.isOrganizationResult) {
      //   // Remove duplicates
      //   entities = entities.filter(
      //     (athleteId: number, index: number) => entities.indexOf(athleteId) > 0
      //   );
      // }

      let out: string = "";

      for (let i = 0; i < entities.length; i++) {
        out += entities[i].fullname
          ? entities[i].fullname
          : entities[i].firstname + " " + entities[i].lastname;
        if (i + 1 < entities.length) {
          out += ", ";
        }
      }

      return out;
    });

    const disciplines = computed(() => {
      return model.value.sport ? model.value.sport.disciplines : [];
    });

    const onUpdateFormItem = (item: string) => {
      v$.value[item].$touch();
    };

    return {
      t,
      v$,
      onBack,
      onSubmit,
      loadCompetitions,
      loadOrganizations,
      loadTrainers,
      loadAthletes,
      onUpload,
      loadTags,
      onUpdateFormItem,
      model,
      toggleIsOrganizationItems,
      intermiadiateTemplate,
      entityResultText,
      lists,
      sports: store.getters.sports,
      disciplines,
      isMounted,
      generalIntermiadiate,
      counts,
      currentLanguage: currentI18nLanguage,
      languages: store.getters.supportedLanguages.map((l: any) => {
        return {
          value: l.id.toLowerCase(),
          label: l.id.toUpperCase(),
        };
      }),
    };
  },
});
