
import { Intermiadiate } from "@/interfaces";
import { defineComponent, PropType } from "vue";
import { MrUiKitInputMask } from "mr-ui-kit";

export default defineComponent({
  name: "IntermiadiateBlock",
  components: {
    MrUiKitInputMask,
  },
  props: {
    items: {
      type: Array as PropType<Intermiadiate[]>,
      default: [],
    },
  },
  setup() {},
});
