import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72270ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "athlete-detail"
}
const _hoisted_2 = {
  key: 0,
  class: "badge bg-soft-secondary text-secondary"
}
const _hoisted_3 = { class: "container pt-0 pb-4" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg",
  style: {"width":"20px","height":"20px"}
}
const _hoisted_8 = { class: "ms-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg",
  style: {"width":"20px","height":"20px"}
}
const _hoisted_12 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitProfile = _resolveComponent("MrUiKitProfile")!
  const _component_TeamResult = _resolveComponent("TeamResult")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_OtherResult = _resolveComponent("OtherResult")!
  const _component_MrUiKitNavTab = _resolveComponent("MrUiKitNavTab")!
  const _component_Biography = _resolveComponent("Biography")!
  const _component_Ranking = _resolveComponent("Ranking")!
  const _component_TrainerList = _resolveComponent("TrainerList")!

  return (_ctx.athlete != null && _ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MrUiKitProfile, {
          cover: _ctx.cover,
          title: _ctx.title,
          imageRounded: "",
          image: _ctx.photo
        }, {
          right: _withCtx(() => [
            (_ctx.athlete.active)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t("common.fields.active")), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["cover", "title", "image"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MrUiKitNavTab, null, {
            default: _withCtx(() => [
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.result'),
                class: "py-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_MrUiKitNavTab, { mode: "tab" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MrUiKitTab, {
                          name: _ctx.t('common.tabs.result')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TeamResult, {
                              entity: _ctx.athleteEntity,
                              class: "mt-4"
                            }, null, 8, ["entity"])
                          ]),
                          _: 1
                        }, 8, ["name"]),
                        _createVNode(_component_MrUiKitTab, {
                          name: _ctx.t('common.tabs.otherResult')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_OtherResult, {
                              entity: _ctx.athleteEntity,
                              class: "mt-4"
                            }, null, 8, ["entity"])
                          ]),
                          _: 1
                        }, 8, ["name"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.info'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Biography, {
                    title: _ctx.t('common.bio'),
                    content: _ctx.athlete.bio,
                    items: _ctx.bioItems,
                    social: _ctx.socialItems
                  }, {
                    federations: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.athlete.federations, (fed) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'athlete-feds-' + fed.id,
                          class: "pointer mb-2 align-items-md-center",
                          onClick: ($event: any) => (_ctx.onRedirect(fed, 'federations'))
                        }, [
                          (fed.logo)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: fed.logo.url,
                                style: {"width":"20px","height":"20px"}
                              }, null, 8, _hoisted_6))
                            : (_openBlock(), _createElementBlock("img", _hoisted_7)),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(fed.fullname), 1)
                        ], 8, _hoisted_5))
                      }), 128))
                    ]),
                    organizations: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.athlete.organizations, (org) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'athlete-orgs-' + org.id,
                          class: "pointer mb-2 align-items-md-center",
                          onClick: ($event: any) => (_ctx.onRedirect(org, 'organizations'))
                        }, [
                          (org.logo)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: org.logo.url,
                                style: {"width":"20px","height":"20px"}
                              }, null, 8, _hoisted_10))
                            : (_openBlock(), _createElementBlock("img", _hoisted_11)),
                          _createElementVNode("span", _hoisted_12, _toDisplayString(org.fullname), 1)
                        ], 8, _hoisted_9))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["title", "content", "items", "social"]),
                  _createVNode(_component_Ranking, { athlete: _ctx.athlete }, null, 8, ["athlete"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.trainer'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TrainerList, { entity: _ctx.athleteEntity }, null, 8, ["entity"])
                ]),
                _: 1
              }, 8, ["name"])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}