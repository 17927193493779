import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "federation-detail"
}
const _hoisted_2 = { class: "container pt-0 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitProfile = _resolveComponent("MrUiKitProfile")!
  const _component_Biography = _resolveComponent("Biography")!
  const _component_StaffList = _resolveComponent("StaffList")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_NationalList = _resolveComponent("NationalList")!
  const _component_SportClubList = _resolveComponent("SportClubList")!
  const _component_TrainerList = _resolveComponent("TrainerList")!
  const _component_AthleteList = _resolveComponent("AthleteList")!
  const _component_MrUiKitNavTab = _resolveComponent("MrUiKitNavTab")!

  return (_ctx.federation != null && _ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MrUiKitProfile, {
          cover: _ctx.cover,
          title: _ctx.federation.fullname,
          subTitle: _ctx.profileSubtitle,
          image: _ctx.logo
        }, null, 8, ["cover", "title", "subTitle", "image"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MrUiKitNavTab, null, {
            default: _withCtx(() => [
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.info'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Biography, {
                    title: _ctx.t('common.bio'),
                    content: _ctx.federation.bio,
                    items: _ctx.bioItems,
                    social: _ctx.socialItems
                  }, null, 8, ["title", "content", "items", "social"]),
                  _createVNode(_component_StaffList, { entity: _ctx.federationEntity }, null, 8, ["entity"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.national'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NationalList)
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.organization'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SportClubList)
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.trainer'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TrainerList, {
                    count: _ctx.totalCounts.trainers.count,
                    entity: _ctx.federationEntity
                  }, null, 8, ["count", "entity"])
                ]),
                _: 1
              }, 8, ["name"]),
              _createVNode(_component_MrUiKitTab, {
                name: _ctx.t('common.tabs.athlete'),
                class: "pt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AthleteList, {
                    count: _ctx.totalCounts.athletes.count,
                    entity: _ctx.federationEntity
                  }, null, 8, ["count", "entity"])
                ]),
                _: 1
              }, 8, ["name"])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}