
	import { computed, defineComponent } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { MrUiKitModal, MrUiKitButton } from 'mr-ui-kit';

	export default defineComponent({
		name: 'DeleteEntityModal',
		emits: ['submit', 'close'],
		components: {
			MrUiKitModal,
			MrUiKitButton,
		},
		props: {
			name: {
				type: String,
				default: '',
			},
		},
		setup(props, { emit }) {
			const { t } = useI18n();

			const text = computed(() => {
				return t('common.modals.deleteEntity.title').replace(
					/:name:/g,
					props.name,
				);
			});

			const onSubmit = () => {
				emit('submit');
			};

			const onClose = () => {
				emit('close');
			};

			return {
				t,
				text,
				onSubmit,
				onClose,
			};
		},
	});
