
import { computed, defineComponent, PropType, reactive } from 'vue'
import { MrUiKitCard, MrUiKitForm, MrUiKitInput, MrUiKitButton } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';
import * as validators from '@vuelidate/validators';
import { ResetPassword } from '@/interfaces';
import i18Validators from '@/utils/i18n-validators';

export default defineComponent({
    name: 'PersonalData',
    emits: ['update:modelValue', 'click', 'logout'],
    components: {
        MrUiKitCard,
        MrUiKitForm,
        MrUiKitInput,
        MrUiKitButton
    },
    props: {
        passwords: {
            type: Object as PropType<ResetPassword>,
            default: {
                oldPassword: null,
                newPassword: null,
                confirmNewPassword: null
            }
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const withI18nMessage = validators.createI18nMessage({ t });
        const userPasswordForm = reactive<ResetPassword>(props.passwords);
        const required = withI18nMessage(validators.required, { messagePath: i18Validators.required });
        const valid = withI18nMessage((value: string | null) => {
            if (value == null) return false;
            return value === userPasswordForm.newPassword;
        }, { messagePath: i18Validators.confirmPassordNotMatch });
        const formFields = reactive([
            {
                component: 'MrUiKitInput',
                name: 'oldPassword',
                props: {
                    label: computed(() => t('common.oldPassword')),
                    type: 'password',
                    labelPosition: 'left'
                },
                rules: {
                    required
                }
            },
            {
                component: 'MrUiKitInput',
                name: 'newPassword',
                props: {
                    label: computed(() => t('common.newPassword')),
                    type: 'password',
                    labelPosition: 'left'
                },
                rules: {
                    required
                }
            },
            {
                component: 'MrUiKitInput',
                name: 'confirmNewPassword',
                props: {
                    label: computed(() => t('common.confirmNewPassword')),
                    type: 'password',
                    labelPosition: 'left'
                },
                rules: {
                    required,
                    valid
                }
            }
        ]);

        const onClick = () => {
            emit('click');
        }

        const onLogout = () => {
            emit('logout')
        }

        return {
            t,
            formFields,
            userPasswordForm,
            onClick,
            onLogout
        }
    },
})
