
	import { defineComponent } from 'vue';
	import { useRouter } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import { localizePath } from '@/plugins/i18n';

	export default defineComponent({
		name: 'LandingNextCompetition',
		setup() {
			const { t } = useI18n();
			const router = useRouter();

			const goToNextCompetitions = () => {
				router.push(localizePath({ path: `/next/competitions` }));
			};

			return {
				t,
				goToNextCompetitions,
				nextCompetitionImg: require('@/assets/img/homepage/img_competizioni.png'),
			};
		},
	});
