
	import { GeneralFormatted, StaffRoleForm } from '@/interfaces';
	import StaffRoleService from '@/services/StaffRoleService';
	import {
		computed,
		defineComponent,
		onMounted,
		PropType,
		reactive,
		ref,
	} from 'vue';
	import { MrUiKitModal, MrUiKitForm, MrUiKitButton } from 'mr-ui-kit';
	import { useStore } from 'vuex';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'StaffRoleModal',
		components: {
			MrUiKitButton,
			MrUiKitForm,
			MrUiKitModal,
		},
		props: {
			value: {
				type: Object as PropType<GeneralFormatted>,
				default: null,
			},
		},
		setup(props, { emit }) {
			const { t, locale } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});
			const store = useStore();
			const isMounted = ref(false);
			const model = ref<StaffRoleForm>({
				id: -1,
				name: {
					it: '',
					en: '',
				},
			});
			const fields = reactive([
				{
					component: 'MrUiKitMultiLanguage',
					name: 'name',
					props: {
						label: computed(() => t('common.fields.name')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
			]);

			onMounted(async () => {
				if (props.value != null) {
					model.value = await StaffRoleService.getMultiLang(props.value.id);
				}
				isMounted.value = true;
			});

			const title = computed(() => {
				return props.value
					? t('common.modals.staffRoleModal.titleUpdate')
					: t('common.modals.staffRoleModal.titleCreate');
			});

			const onSubmit = async () => {
				await StaffRoleService.createOrUpdate(model.value, locale.value);
				emit('close');
			};

			const onClose = () => {
				emit('close');
			};

			return {
				t,
				title,
				model,
				fields,
				onSubmit,
				onClose,
				isMounted,
			};
		},
	});
