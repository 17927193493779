
import { RouteEntity } from '@/interfaces'
import { defineComponent, PropType, ref } from 'vue'
import { MrUiKitCard, MrUiKitButton, MrUiKitTable, MrUiKitPagination, MrUiKitNavTab, MrUiKitTab } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';

import ResultTable from '@/components/Backoffice/TeamResult/ResultTable.vue';
import CreateResultForm from '@/components/Backoffice/TeamResult/CreateResultForm.vue';

export default defineComponent({
    name: 'ResultList',
    components: {
        MrUiKitCard,
        MrUiKitButton,
        MrUiKitTable,
        MrUiKitPagination,
        MrUiKitNavTab,
        MrUiKitTab,
        ResultTable,
        CreateResultForm,
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Organization
        }
    },
    setup(props) {
        const { t } = useI18n();
        const showCurrentForm = ref<'all' | 'team' | 'single' | 'new'>('all');
        const currentResultId = ref<number | null>(null);

        const onOpenAdd = ({ type, item }: any) => {
            showCurrentForm.value = type;
            if (item) {
                currentResultId.value = item.id;
            } else {
                currentResultId.value = null;
            }
        }

        const onCloseAdd = () => {
            showCurrentForm.value = 'all';
            currentResultId.value = null;
        }

        return {
            t,
            onOpenAdd,
            onCloseAdd,
            currentResultId,
            showCurrentForm
        }
    },
})
