import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "search-result" }
const _hoisted_2 = { class: "container pt-6" }
const _hoisted_3 = {
  class: "row justify-content-between d-flex align-item-center",
  style: {"align-items":"center","min-height":"56px"}
}
const _hoisted_4 = { class: "col-md-4 col-12 pb-2" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = { class: "container pt-0" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "flex-shrink-0" }
const _hoisted_9 = { class: "avatar avatar-circle" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "avatar-img",
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_12 = { class: "flex-grow-1 ms-3 mb-0" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 0,
  style: {"padding":"0.5rem"},
  class: "ms-2 badge bg-soft-primary text-primary"
}
const _hoisted_17 = { class: "pt-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitTable = _resolveComponent("MrUiKitTable")!
  const _component_MrUiKitPagination = _resolveComponent("MrUiKitPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t('common.searchResult')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_MrUiKitTable, {
        search: true,
        onSearch: _ctx.onSearch,
        rows: _ctx.items,
        columns: _ctx.columns,
        fixedWidth: 2
      }, {
        fullname: _withCtx(({ item }) => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (item.photo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "avatar-img",
                      src: item.photo
                    }, null, 8, _hoisted_10))
                  : (_openBlock(), _createElementBlock("img", _hoisted_11))
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h5", {
                class: "mb-0 link-navigate",
                onClick: () => _ctx.onNavigate(item)
              }, _toDisplayString(item.fullname), 9, _hoisted_13)
            ])
          ])
        ]),
        organizations: _withCtx(({ item }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.organizations, (organization, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: 'trainer-org-' + organization.id,
              onClick: () => _ctx.onNavigateOrg(organization, 'organizations'),
              class: "link-navigate"
            }, [
              _createElementVNode("span", null, _toDisplayString(organization.fullname), 1),
              (
                item.organizations.length &&
                index < item.organizations.length - 1
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, " , "))
                : _createCommentVNode("", true)
            ], 8, _hoisted_14))
          }), 128)),
          (item.countOrganizations > 3)
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, " + " + _toDisplayString(item.countOrganizations - 3), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onSearch", "rows", "columns"]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_MrUiKitPagination, {
          modelValue: _ctx.pagination,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
          onLoad: _ctx.onLoad
        }, {
          default: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.t("pagination.page")) + " " + _toDisplayString(_ctx.pagination.current) + " " + _toDisplayString(_ctx.t("pagination.of")) + " " + _toDisplayString(slotProps.lastPage), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "onLoad"])
      ])
    ])
  ]))
}