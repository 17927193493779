import { h, ref, PropType, defineComponent, onMounted } from "vue";
import type { TypedOptions } from "typed.js";
import Typed from "typed.js";
import "./TypedJs.scss";

export default defineComponent({
    name: "Typed",
    props: {
        options: {
            type: Object as PropType<TypedOptions>,
            required: true,
        },
    },

    setup(props, context) {
        const typedElement = ref<Element>();

        const initTypedJS = () => {
            return new Typed(
                typedElement.value!.querySelector(".js-text-animation")!,
                props.options
            );
        };

        onMounted(initTypedJS);

        return () => {
            return h(
                "span",
                {
                    ref: typedElement,
                    class: "typed-element",
                },
                context.slots.default!()
            );
        };
    },
});