
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  MrUiKitCard,
  MrUiKitButton,
  MrUiKitTable,
  MrUiKitPagination,
} from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import {
  InternationalCompetition,
  InternationalCompetitionForm,
  PaginationModel,
} from "@/interfaces";
import InternationalCompetitionService from "@/services/InternationalCompetitionService";
import axios from "axios";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import CreateInternationalCompetitionModal from "@/components/Backoffice/Modals/CreateInternationalCompetitionModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "InternationalCompetition",
  components: {
    MrUiKitCard,
    MrUiKitButton,
    MrUiKitTable,
    MrUiKitPagination,
    DeleteEntityModal,
    CreateInternationalCompetitionModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const showDeleteModal = ref(false);
    const showModal = ref(false);
    const route = useRoute();
    const router = useRouter();
    const internationalCompetition = ref<InternationalCompetition[]>([]);
    const selectedCompetition = ref<InternationalCompetition | null>(null);
    const isLoading = ref(false);
    const search = ref<string | null>(null);
    const isMounted = ref(false);
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "name",
      },
      {
        label: computed(() => t("common.fields.period")),
        field: "period",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      count: 0,
      current: 0,
      limit: 10,
    });

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const onInit = async () => {
      const total = await InternationalCompetitionService.count(
        locale.value,
        search.value
      );
      pagination.count = total.count;
      await loadMore(0);
      pagination.current = 1;
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;
        internationalCompetition.value =
          await InternationalCompetitionService.get(locale.value, {
            limit: 10,
            offset,
            search: search.value != null ? search.value : undefined,
          });

        isLoading.value = false;
      }
    };

    const openModal = (item: InternationalCompetition | null) => {
      console.log(item);
      selectedCompetition.value = item;
      showModal.value = true;
    };

    const onDelete = (item: InternationalCompetition) => {
      selectedCompetition.value = item;
      showDeleteModal.value = true;
    };

    const onSubmitDelete = async () => {
      if (selectedCompetition.value) {
        try {
          await axios.delete(
            "/internationals/competitions/" + selectedCompetition.value.id
          );
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const onSubmitForm = async (form: InternationalCompetitionForm) => {
      try {
        await InternationalCompetitionService.createOrUpdate(form);
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }

      showModal.value = false;
      onInit();
    };

    const onSearch = (s: string) => {
      if (s != "") {
        search.value = s;
      } else {
        search.value = null;
      }

      onInit();
    };

    const onNavigateFrontOffice = (item: InternationalCompetition) => {
      router.push(
        localizePath({
          path: "/competitions/" + item.id,
        })
      );
    };

    const searchLabel = computed(() => {
      return t("common.findDots");
    });

    return {
      t,
      openModal,
      loadMore,
      onDelete,
      onSubmitDelete,
      onSubmitForm,
      onSearch,
      onNavigateFrontOffice,
      searchLabel,
      showModal,
      isMounted,
      internationalCompetition,
      columns,
      pagination,
      showDeleteModal,
      selectedCompetition,
    };
  },
});
