
import { PaginationModel } from "@/interfaces";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import VisitorService from "@/services/VisitorService";
import CreateVisitorModal from "@/components/Backoffice/Modals/CreateVisitorModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import {
  MrUiKitCard,
  MrUiKitTable,
  MrUiKitPagination,
  MrUiKitButton,
} from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { useStore } from "vuex";

export default defineComponent({
  name: "VisitorList",
  components: {
    MrUiKitCard,
    MrUiKitTable,
    MrUiKitPagination,
    MrUiKitButton,
    CreateVisitorModal,
    DeleteEntityModal,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isMounted = ref(false);
    const isLoading = ref(false);
    const visitors = ref<any[]>([]);
    const showModal = ref(false);
    const showDeleteModal = ref(false);
    const selectedVisitor = ref<any | null>(null);
    const columns = reactive([
      {
        label: "Email",
        field: "email",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      count: 0,
      current: 0,
      limit: 10,
    });

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const onInit = async () => {
      const total = await VisitorService.count();
      pagination.count = total.count;
      pagination.current = 1;
      await load(0);
    };

    const load = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;

        visitors.value = await VisitorService.get({
          limit: 10,
          offset,
        });
        isLoading.value = false;
      }
    };

    const onOpenModal = () => {
      selectedVisitor.value = null;
      showModal.value = true;
    };

    const onDelete = (item: any) => {
      showDeleteModal.value = true;
      selectedVisitor.value = item;
    };

    const onSubmitDelete = async () => {
      if (selectedVisitor.value) {
        try {
          await axios.delete("/admin/visitors/" + selectedVisitor.value.id);
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const onCreate = async (body: any) => {
      showModal.value = false;
      try {
        await VisitorService.create(body);
        await onInit();
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }
    };

    return {
      isMounted,
      visitors,
      columns,
      showModal,
      showDeleteModal,
      selectedVisitor,
      pagination,
      t,
      load,
      onOpenModal,
      onCreate,
      onDelete,
      onSubmitDelete,
    };
  },
});
