import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "entity-block" }
const _hoisted_2 = { class: "mt-2 d-flex entity-block-in element-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitAvatar = _resolveComponent("MrUiKitAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'int-block-' + _ctx.entity + '-' + item.id,
          class: "d-flex align-items-md-center pe-4 mb-1 mt-1"
        }, [
          _createVNode(_component_MrUiKitAvatar, {
            "onLabel:click": () => _ctx.onNavigate(item, _ctx.entity),
            dimensions: 'small',
            primaryText: 
            _ctx.isOrganization
              ? item.fullname
              : item.firstname +
                ' ' +
                (item.middlename ?? '') +
                ' ' +
                item.lastname
          ,
            picture: 
            item.photo
              ? item.photo.url
              : 'https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg'
          
          }, null, 8, ["onLabel:click", "primaryText", "picture"])
        ]))
      }), 128))
    ])
  ]))
}