import { CreateOrUpdateTeamResult, IntermiadiateForm, IntermiadiateMultiLanguage, TeamFormModels, TeamResultForm, TeamResultMultiLanguage } from "@/interfaces";
import axios from "axios";

class TeamResultService {
    constructor() {}

    async createOrUpdate(form: TeamResultForm, isUpdate: boolean): Promise<void> {
        const body: CreateOrUpdateTeamResult = {
            competitionId: form.competition?.id as number,
            isOrganizationResult: form.isOrganizationResult,
            federationId: form.federationId,
            sportId: form.sport?.id as number,
            disciplineId: form.discipline?.id as number,
            organizations: form.organizations.map(o => o.id),
            trainers: form.trainers.map(t => t.id),
            tags: form.tags.map(t => t.id),
            athletes: form.athletes.map(a => a.id),
            position: form.position ? +form.position : null,
            totPartecipant: form.totPartecipant ? +form.totPartecipant : null,
            isLeft: form.isLeft as boolean,
            competitionResultTypeId: form.competitionResultType ? form.competitionResultType.id : null,
            leftResult: form.leftResult != null ? form.leftResult : null,
            rightResult: form.rightResult != null ? form.rightResult : null,
            opponentId: form.opponent ? form.opponent.id : null,
            intermiadiates: form.intermiadiates.map((i: IntermiadiateForm) => {
                return {
                    id: i.id,
                    competitionResultTypeId: i.competitionResultType ? i.competitionResultType.id : null,
                    isLeft: i.isLeft as boolean,
                    leftResult: i.leftResult != null ? i.leftResult : null,
                    rightResult: i.rightResult != null ? i.rightResult : null,
                    opponentId: i.opponent ? i.opponent.id : null,
                    it: {
                        notes: i.notes != null ? i.notes.it : undefined
                    },
                    en: {
                        notes: i.notes != null ? i.notes.it : undefined
                    }
                }
            }),
            attachments: form.attachments.map(a => a.id),
            it: {
                notes: form.notes != null ? form.notes.it : undefined,
                generalNotes: form.generalNotes != null ? form.generalNotes.it : undefined
            },
            en: {
                notes: form.notes != null ? form.notes.en : undefined,
                generalNotes: form.generalNotes != null ? form.generalNotes.en : undefined
            }
        }

        if (isUpdate) {
            await axios.patch('/competitions/results/' + form.id, body);
        } else {
            await axios.post('/competitions/results', body);
        }
    }

    async getMultiLanguageById(id: number, lang: string): Promise<TeamFormModels> {
        const { data } = await axios.get<TeamResultMultiLanguage>('/competitions/results/multilang/' + id + '?lang=' + lang);
        return {
            model: {
                id: data.id,
                competition: data.competition,
                isOrganizationResult: data.isOrganizationResult,
                federationId: data.federation.id,
                sport: data.sport,
                discipline: data.discipline,
                organizations: data.organizations,
                trainers: data.trainers,
                tags: data.tags,
                athletes: data.athletes,
                position: data.position != null ? String(data.position) : null,
                totPartecipant: data.totPartecipant != null ? String(data.totPartecipant) : null,
                competitionResultType: data.competitionResultType,
                isLeft: data.isLeft,
                leftResult: data.leftResult != null ? String(data.leftResult) : null,
                rightResult: data.rightResult != null ? String(data.rightResult) : null,
                opponent: data.opponent,
                intermiadiates: data.intermiadiates.map((i: IntermiadiateMultiLanguage) => {
                    return {
                        id: i.id,
                        competitionResultType: i.competitionResultType,
                        isLeft: i.isLeft,
                        leftResult: i.leftResult ? String(i.leftResult) : null,
                        rightResult: i.rightResult ? String(i.rightResult) : null,
                        opponent: i.opponent,
                        notes: {
                            it: i.it.notes ? i.it.notes : '',
                            en: i.en.notes ? i.en.notes : ''
                        }
                    }
                }) as IntermiadiateForm[],
                attachments: data.attachments.map(a => {
                    return {
                        id: a.id,
                        file: {
                            name: a.filename
                        }
                    }
                }),
                generalNotes: {
                    it: data.it.generalNotes ? data.it.generalNotes : '',
                    en: data.en.generalNotes ? data.en.generalNotes : ''
                }
            },
            generalIntermiadiate: {
                competitionResultType: data.competitionResultType,
                isLeft: data.isLeft,
                leftResult: data.leftResult ? String(data.leftResult) : '0',
                rightResult: data.rightResult ? String(data.rightResult) : null,
                opponent: data.opponent,
                notes: {
                    it: data.it.notes ? data.it.notes : '',
                    en: data.en.notes ? data.en.notes : ''
                }
            }
        }
    }
}

export default new TeamResultService();