
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { TeamResult } from "@/interfaces";
import { MrUiKitInputMask } from "mr-ui-kit";

import ResultMedal from "@/components/Frontend/TeamResult/ResultMedal.vue";

export default defineComponent({
  name: "TeamResultIntermiadiateBlock",
  components: {
    ResultMedal,
    MrUiKitInputMask,
  },
  props: {
    haveMedals: {
      type: Boolean,
      default: true,
    },
    teamResult: {
      type: Object as PropType<TeamResult>,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
