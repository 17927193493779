import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "athlete-personal-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_MrUiKitForm = _resolveComponent("MrUiKitForm")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MrUiKitForm, {
          mode: "card",
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
          fields: _ctx.fields,
          onSubmit: _ctx.onSubmit,
          "onUpdate:form": _ctx.onUpdateForm
        }, {
          header: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.t('common.tabs.info')), 1)
          ]),
          default: _withCtx(({ disabled }) => [
            _createVNode(_component_MrUiKitButton, {
              type: "button",
              onClick: ($event: any) => (_ctx.onSubmit(disabled)),
              disabled: disabled
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('common.operations.save')), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "disabled"])
          ]),
          _: 1
        }, 8, ["modelValue", "fields", "onSubmit", "onUpdate:form"])
      ]))
    : _createCommentVNode("", true)
}