import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "delete-entity-modal" }
const _hoisted_2 = { class: "d-flex pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_MrUiKitModal = _resolveComponent("MrUiKitModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitModal, { onClose: _ctx.onClose }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MrUiKitButton, { onClick: _ctx.onSubmit }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('common.operations.save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_MrUiKitButton, {
            onClick: _ctx.onClose,
            classes: ['ms-2', 'btn-white']
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('common.operations.close')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["onClose"])
  ]))
}