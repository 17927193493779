import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-557f506a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "login-view flex-grow-1"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "cons-bg col-lg-5 col-xl-4 d-none d-lg-flex justify-content-center align-items-center position-relative" }
const _hoisted_5 = { class: "text-center flex-grow-1 p-5" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-lg-7 col-xl-8 d-flex justify-content-between align-items-center min-vh-100 flex-column p-5" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  class: "pb-5",
  style: {"max-width":"500px","width":"100%"}
}
const _hoisted_11 = {
  class: "row align-items-center text-center",
  style: {"width":"100%"}
}
const _hoisted_12 = {
  style: {"font-size":"14px"},
  class: "col-12 col-sm-12 col-md-6 order-sm-2 order-md-1 order-2 p-2"
}
const _hoisted_13 = { class: "d-md-flex justify-content-start" }
const _hoisted_14 = { class: "col-12 col-sm-12 order-md-2 order-sm-1 order-1 col-md-6 p-2" }
const _hoisted_15 = { class: "d-md-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_FirstResetPasswordForm = _resolveComponent("FirstResetPasswordForm")!
  const _component_SocialLanguage = _resolveComponent("SocialLanguage")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  class: "mb-3",
                  style: {"max-width":"275px"},
                  src: _ctx.logoLogin
                }, null, 8, _hoisted_6)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  class: "mb-3 d-lg-none",
                  style: {"max-width":"40%"},
                  src: _ctx.logoLoginDark
                }, null, 8, _hoisted_9)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.isLogin && _ctx.verifyToken == null)
                  ? (_openBlock(), _createBlock(_component_LoginForm, {
                      key: 0,
                      modelValue: _ctx.loginForm,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginForm) = $event)),
                      onClick: _ctx.onClick
                    }, null, 8, ["modelValue", "onClick"]))
                  : (_openBlock(), _createBlock(_component_FirstResetPasswordForm, {
                      key: 1,
                      modelValue: _ctx.loginForm,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm) = $event)),
                      onClick: _ctx.onFirstReset
                    }, null, 8, ["modelValue", "onClick"]))
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('footer.copyright')), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_SocialLanguage)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}