
import { GeneralFormatted, RouteEntity } from '@/interfaces'
import { defineComponent, PropType, reactive, ref, watch } from 'vue'
import { MrUiKitTag } from 'mr-ui-kit';
import FilterService from '@/services/FilterService';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'AddEntitySelect',
    emits: ['create'],
    components: {
        MrUiKitTag
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Federation
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const selected = ref(null);
        const entities = reactive<GeneralFormatted[]>([]);
        const offset = ref<number>(0);

        const loadEntities = async (search: string) => {
            if (search.length === 0) {
                entities.splice(0, entities.length);
                offset.value = 0;
                emit('create', {
                    entity: null,
                    show: false
                })
            } else {
                const loaded = await FilterService.findByEntity(props.entity, search, locale.value, {
                    limit: 10,
                    offset: offset.value
                });
                entities.push(...loaded);
                offset.value += 10;
    
                if (loaded.length === 0) {
                    emit('create', {
                        entity: null,
                        show: true
                    });
                }
            }
        }

        watch(selected, (newSelect) => {
            emit('create', {
                entity: newSelect,
                show: newSelect == null ? false : true
            })
        })

        return {
            t,
            entities,
            selected,
            loadEntities
        }
    },
})
