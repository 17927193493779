
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import FederationService from '@/services/FederationService';
import OrganizationService from '@/services/OrganizationService';
import TrainerService from '@/services/TrainerService';
import AthleteService from '@/services/AthleteService';

export default defineComponent({
    name: 'LandingStats',
    setup() {
        const { t, locale } = useI18n();
        const isMounted = ref<boolean>(false);
        const entities = ref<any[]>([]);

        onMounted(async () => {
            const federationCount = await FederationService.count(locale.value);
            entities.value.push({
                count: federationCount.count,
                img: require('@/assets/img/homepage/ico_federazione.png'),
                name: t('common.federations')
            });

            const organizationCount = await OrganizationService.count(locale.value);
            entities.value.push({
                count: organizationCount.count,
                img: require('@/assets/img/homepage/ico_societa.png'),
                name: t('common.organizations')
            });

            const trainerCount = await TrainerService.count();
            entities.value.push({
                count: trainerCount.count,
                img: require('@/assets/img/homepage/ico_allenatore.png'),
                name: t('common.trainers')
            });

            const athleteCount = await AthleteService.count();
            entities.value.push({
                count: athleteCount.count,
                img: require('@/assets/img/homepage/ico_atleta.png'),
                name: t('common.athletes')
            });

            isMounted.value = true
        });
        
        return {
            t,
            title: t('home.stats.title'),
            entities,
            isMounted
        }
    },
})
