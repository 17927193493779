
import { Athlete } from '@/interfaces'
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitCard } from 'mr-ui-kit';

export default defineComponent({
    name: 'Ranking',
    components: {
        MrUiKitCard
    },
    props: {
        athlete: {
            type: Object as PropType<Athlete>,
            default: null
        }
    },
    setup() {
        const { t } = useI18n();

        return {
            t,
            athleteImg: require('@/assets/img/homepage/ico_atleta.png'),
            actualPosImg: require('@/assets/img/homepage/ico_pos_attuale.png'),
            bestPosImg: require('@/assets/img/homepage/ico_best_pos.png')
        }
    },
})
