import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sport-modal"
}
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = { class: "d-flex flex-row-reverse mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_MrUiKitForm = _resolveComponent("MrUiKitForm")!
  const _component_MrUiKitModal = _resolveComponent("MrUiKitModal")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MrUiKitModal, { onClose: _ctx.onClose }, {
          header: _withCtx(() => [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_MrUiKitForm, {
              modelValue: _ctx.model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
              fields: _ctx.fields,
              onSubmit: _ctx.onSubmit
            }, {
              default: _withCtx(({ disabled }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_MrUiKitButton, {
                    type: "submit",
                    classes: ['ms-2', 'btn-primary'],
                    disabled: disabled
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('common.operations.save')), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled"]),
                  _createVNode(_component_MrUiKitButton, {
                    type: "button",
                    classes: ['btn-white'],
                    onClick: _ctx.onClose
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('common.operations.close')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "fields", "onSubmit"])
          ]),
          _: 1
        }, 8, ["onClose"])
      ]))
    : _createCommentVNode("", true)
}