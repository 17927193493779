import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sport-club-list" }
const _hoisted_2 = { class: "row justify-content-between d-flex align-item-center mb-4" }
const _hoisted_3 = { class: "col-md-4 col-12" }
const _hoisted_4 = { class: "d-flex align-items-md-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "img-fluid",
  style: {"max-width":"44px"},
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_7 = { class: "ms-3" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "container justify-content-center text-center p-7 ps-0 pe-0",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!
  const _component_MrUiKitList = _resolveComponent("MrUiKitList")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfiniteScroll, {
      guard: _ctx.guard,
      rowClasses: ['d-flex'],
      isLoading: _ctx.isLoading,
      onLoad: _ctx.loadMore
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.t('common.sportClubs')), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.organizations.length)
          ? (_openBlock(), _createBlock(_component_MrUiKitList, {
              key: 0,
              classes: ['mb-4'],
              columns: 3,
              items: _ctx.organizations
            }, {
              default: _withCtx(({ item }) => [
                _createVNode(_component_MrUiKitCard, {
                  classes: ['organization-card', 'h-100'],
                  bodyClasses: ['py-4', 'px-4', 'd-flex']
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      (item.logo)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "img-fluid",
                            style: {"max-width":"44px"},
                            src: item.logo.url
                          }, null, 8, _hoisted_5))
                        : (_openBlock(), _createElementBlock("img", _hoisted_6)),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h4", {
                          class: "m-0 link-navigate",
                          style: {"line-height":"1.3 !important"},
                          onClick: () => _ctx.onClick(item)
                        }, _toDisplayString(item.fullname), 9, _hoisted_8)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["items"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t("common.noSportClubs")), 1))
      ]),
      _: 1
    }, 8, ["guard", "isLoading", "onLoad"])
  ]))
}