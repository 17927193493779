import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "landing-stats"
}
const _hoisted_2 = { class: "container content-space-2 content-space-b-lg-2" }
const _hoisted_3 = { class: "w-md-75 text-center mx-md-auto mb-10" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "row col-md-divider col-divider-rotated justify-content-center" }
const _hoisted_6 = { "data-aos": "fade-up" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", { innerHTML: _ctx.title }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'stats-entity-' + index,
                class: "col-md-3"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      class: "mb-4",
                      style: {"max-height":"40px"},
                      src: entity.img
                    }, null, 8, _hoisted_8),
                    _createElementVNode("h2", null, _toDisplayString(entity.count), 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(entity.name), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}