import { Athlete, Competition, Count, CountFilter, CreateFederation, Federation, FederationForm, FederationInfoForm, FederationMultiLanguage, Filter, FilterByOrganization, National, Organization, RouteEntity, Staff, Trainer, UpdateFederation } from "@/interfaces";
import axios from "axios";
import AccountService from "./AccountService";
import FilterService from "./FilterService";

class FederationService {
    constructor() {}

    async count(lang: string, filter?: CountFilter): Promise<Count> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Count>('/federations/count?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async get(lang: string, filter: Filter): Promise<Federation[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Federation[]>('/federations?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getById(id: number, lang: string): Promise<Federation> {
        const response = await axios.get<Federation>('/federations/' + id + '?lang=' + lang);
        return response.data;
    }

    async countSubList(id: number, type: RouteEntity, lang?: string): Promise<Count> {
        const response = await axios.get<Count>('/federations/' + id + '/' + type + '/count' + (lang ? '?lang=' + lang : ''));
        return response.data;
    }

    async getStaff(id: number, lang: string, filter: Filter): Promise<Staff[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Staff[]>('/federations/' + id + '/staffs?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getNationals(id: number, lang: string, filter: Filter): Promise<National[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<National[]>('/federations/' + id + '/nationals?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getOrganizations(id: number, lang: string, filter?: Filter): Promise<Organization[]> {
        let parsedFilter = '';
        if (filter) {
            parsedFilter = FilterService.parseFilter(filter);
            if (parsedFilter.length > 0) {
                parsedFilter = '&' + parsedFilter;
            }
        }
        const response = await axios.get<Organization[]>('/federations/' + id + '/organizations?lang=' + lang + parsedFilter);
        return response.data;
    }

    async getSportClubs(id: number, lang: string, filter: Filter): Promise<Organization[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Organization[]>('/federations/' + id + '/sport-clubs?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getTrainers(id: number, lang: string, filter: FilterByOrganization): Promise<Trainer[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Trainer[]>('/federations/' + id + '/trainers?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getAthletes(id: number, lang: string, filter: FilterByOrganization): Promise<Athlete[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Athlete[]>('/federations/' + id + '/athletes?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getMultiLanguageById(id: number): Promise<FederationInfoForm> {
        const { data } = await axios.get<FederationMultiLanguage>('/federations/' + id + '/multilang');
        return {
            cover: {
                file: data.cover || null,
                url: data.cover && data.cover.url ? data.cover.url : null
            },
            file: {
                file: data.logo || null,
                url: data.logo && data.logo.url ? data.logo.url : null
            },
            fullname: {
                it: data.it.fullname,
                en: data.en.fullname
            },
            bio: {
                it: data.it && data.it.bio ? data.it.bio : '',
                en: data.en && data.en.bio ? data.en.bio : '',
            },
            shortname: data.shortname || '',
            publicEmail: data.publicEmail,
            publicTelephone: data.publicTelephone,
            email: data.email,
            website: data.website,
            telephone: data.telephone,
            instagram: data.instagram,
            facebook: data.facebook,
            sports: data.sports,
            dateFoundation: data.dateFoundation,
            active: !data.deleted
        };
    }

    async countCompetitions(id: number, lang: string): Promise<Count> {
        const response = await axios.get<Count>('/competitions/' + id + '/count?lang=' + lang);
        return response.data;
    }

    async getCompetitions(id: number, lang: string, filter: Filter): Promise<Competition[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Competition[]>('/competitions/' + id + '?skipInternational=true&lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async updateById(id: number, form: FederationInfoForm): Promise<FederationInfoForm> {
        const body: UpdateFederation = {
            coverId: form.cover && form.cover.file ? form.cover.file.id : null,
            logoId: form.file && form.file.file ? form.file.file.id : null,
            sports: form.sports,
            email: form.email,
            publicEmail: form.publicEmail,
            telephone: form.telephone,
            publicTelephone: form.publicTelephone,
            website: form.website,
            facebook: form.facebook,
            instagram: form.instagram,
            dateFoundation: form.dateFoundation,
            shortname: form.shortname,
            deleted: !form.active,
            it: {
                fullname: form.fullname.it,
                bio: form.bio.it || undefined
            },
            en: {
                fullname: form.fullname.en,
                bio: form.bio.en || undefined
            }
        }

        await axios.patch('/federations/' + id, body);
        return form;
    }

    async getIdByEntity(id: number, entity: RouteEntity): Promise<number> {
        const { data } = await axios.get<number>('/entities/' + entity + '/' + id + '/federation');
        return data;
    }

    async create(form: FederationForm): Promise<void> {
        const userCredentials = await AccountService.checkAccount(form.userCredentials);
        const body: CreateFederation = {
            userCredentials,
            sports: form.sports.map(s => s.id),
            logoId: form.logo && form.logo.file ? form.logo.file.id : undefined,
            it: {
                fullname: form.fullname.it,
            },
            en: {
                fullname: form.fullname.en
            }
        }

        await axios.post('/federations', body);
    }
}

export default new FederationService();