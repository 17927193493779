
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import { AthleteForm, Organization, RouteEntity } from "@/interfaces";
import { useI18n } from "vue-i18n";
import * as validators from "@vuelidate/validators";
import i18Validators from "@/utils/i18n-validators";
import { useRoute } from "vue-router";
import FileService from "@/services/FileService";
import FederationService from "@/services/FederationService";
import OrganizationService from "@/services/OrganizationService";
import AddEntitySelect from "@/components/Backoffice/Common/AddEntitySelect.vue";
import AthleteService from "@/services/AthleteService";

export default defineComponent({
  name: "CreateAthleteModal",
  emits: ["submit", "close", "update:modelValue"],
  components: {
    MrUiKitModal,
    MrUiKitButton,
    MrUiKitForm,
    AddEntitySelect,
  },
  props: {
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Federation,
    },
    modelValue: {
      type: Object as PropType<AthleteForm>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const withI18nMessage = validators.createI18nMessage({ t });
    const required = withI18nMessage(validators.required, {
      messagePath: i18Validators.required,
    });
    const route = useRoute();
    const isMounted = ref<boolean>(false);
    const entityId = ref<number>(-1);
    const showForm = ref(false);
    const currentOrganizations = reactive<Organization[]>([]);
    const athlete = ref<AthleteForm>(
      props.modelValue || {
        id: -1,
        userCredentials: [],
        organizations: [],
        photo: {},
        firstname: "",
        lastname: "",
      }
    );
    const fields = reactive([
      {
        component: "MrUiKitFileUpload",
        name: "photo",
        props: {
          label: "File",
        },
      },
      {
        component: "MrUiKitInput",
        name: "firstname",
        props: {
          label: computed(() => t("common.fields.firstname")),
          labelPosition: "left",
        },
        rules: {
          required,
        },
      },
      {
        component: "MrUiKitInput",
        name: "lastname",
        props: {
          label: computed(() => t("common.fields.lastname")),
          labelPosition: "left",
        },
        rules: {
          required,
        },
      },
      {
        component: "MrUiKitSelect",
        name: "organizations",
        props: {
          labelKey: "fullname",
          label: computed(() => t("common.organizations")),
          labelPosition: "left",
          key: "id",
          multiple: true,
          items: currentOrganizations,
          classes: ["h-25"],
        },
        rules: {
          required,
        },
      },
      {
        component: "MrUiKitSelect",
        name: "userCredentials",
        props: {
          labelKey: "email",
          label: "Account",
          labelPosition: "left",
          key: "id",
          multiple: true,
          chips: true,
          items: [],
          classes: ["h-25"],
        },
      },
    ]);

    onMounted(async () => {
      entityId.value = +route.params.id;
      if (props.entity === RouteEntity.Federation) {
        await loadOrganizations();
      } else if (props.entity === RouteEntity.Organization) {
        await loadOrganizationById();
      }
      isMounted.value = true;
    });

    const loadOrganizations = async () => {
      const loaded = await FederationService.getOrganizations(
        entityId.value,
        locale.value
      );
      currentOrganizations.push(...loaded);
    };

    const loadOrganizationById = async () => {
      const current = await OrganizationService.getById(
        entityId.value,
        locale.value
      );
      currentOrganizations.push(current);
      athlete.value.organizations.push(current);
    };

    const onSubmit = () => {
      emit("submit", athlete.value);
    };

    const onUpdateForm = async (event: any) => {
      if (athlete.value == null) return;
      const field: keyof AthleteForm = event.item.name;
      switch (event.type) {
        case "upload":
          const toUpload = athlete.value[field].file;
          if (toUpload) {
            const uploadedFile = await FileService.upload(
              athlete.value[field].file
            );
            athlete.value[field].url = uploadedFile.url;
            athlete.value[field].file = uploadedFile;
          }
          break;
      }
    };

    const onCreateAthlete = async ({ entity, show }: any) => {
      showForm.value = false;
      if (entity == null) {
        athlete.value = {
          id: -1,
          userCredentials: [],
          organizations: [],
          photo: {},
          firstname: "",
          lastname: "",
        };
      } else {
        const findAthlete = await AthleteService.getById(
          entity.id,
          locale.value
        );
        athlete.value = {
          id: findAthlete.id,
          userCredentials: findAthlete.userCredentials
            ? findAthlete.userCredentials
            : [],
          organizations: findAthlete.organizations,
          photo: {
            file: findAthlete.photo ? findAthlete.photo : null,
            url: findAthlete.photo ? findAthlete.photo.url : null,
          },
          firstname: findAthlete.firstname,
          lastname: findAthlete.lastname,
        };
        emit("update:modelValue", athlete.value);
      }
      showForm.value = show;
    };

    const onClose = () => {
      emit("close");
    };

    return {
      t,
      onSubmit,
      onUpdateForm,
      onClose,
      onCreateAthlete,
      athlete,
      showForm,
      fields,
      isMounted,
      athleteEntity: RouteEntity.Athlete,
    };
  },
});
