
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import {
  AccountEntity,
  Filter,
  RouteEntity,
  Sport,
  TeamResult,
} from "@/interfaces";
import CompetitionService from "@/services/CompetitionService";
import {
  MrUiKitCollapse,
  MrUiKitSelect,
  MrUiKitAvatar,
  MrUiKitList,
} from "mr-ui-kit";

import InfiniteScroll from "@/components/UI/InfiniteScroll.vue";
import TeamResultIntermiadiateBlock from "@/components/Frontend/TeamResult/TeamResultIntermiadiateBlock.vue";
import TeamResultIntermiadiate from "@/components/Frontend/TeamResult/TeamResultIntermiadiate.vue";
import { useRouter } from "vue-router";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "TeamResult",
  components: {
    InfiniteScroll,
    MrUiKitCollapse,
    MrUiKitSelect,
    TeamResultIntermiadiateBlock,
    TeamResultIntermiadiate,
    MrUiKitAvatar,
    MrUiKitList,
  },
  props: {
    haveMedals: {
      type: Boolean,
      default: true,
    },
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Organization,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const route = useRoute();
    const guard = ref(false);
    const entityId = ref<number>(-1);
    const filterSelected = ref<Sport>({
      id: -1,
      name: "Sport",
      disciplines: [],
    });
    const sports = reactive<Sport[]>([
      {
        id: -1,
        name: "Sport",
        disciplines: [],
      },
    ]);
    const teamResults = ref<TeamResult[]>([]);
    const isLoading = ref<boolean>(false);
    const filter = ref<Filter>({
      limit: 5,
      offset: 0,
    });
    const router = useRouter();

    const log = (item: any) => {
      console.log(item);
    };

    onMounted(async () => {
      entityId.value = +route.params.id;
      loadMore();

      const loadedSports = await CompetitionService.getSports(
        entityId.value,
        locale.value
      );
      sports.push(...loadedSports);
    });

    const loadMore = async (reset?: boolean) => {
      if (!isLoading.value && entityId.value !== -1) {
        if (reset === true) teamResults.value = [];
        isLoading.value = true;
        let loaded: TeamResult[] = [];
        switch (props.entity as RouteEntity) {
          case RouteEntity.Competition:
            loaded = await CompetitionService.getTeamResultsByCompetition(
              entityId.value,
              locale.value,
              {
                limit: 5,
                offset: filter.value.offset,
                sportId:
                  filterSelected.value.id !== -1
                    ? filterSelected.value.id
                    : undefined,
              }
            );
            break;
          default:
            loaded = await CompetitionService.getTeamResultsByEntityId(
              entityId.value,
              props.entity,
              locale.value,
              filter.value
            );
            break;
        }
        teamResults.value.push(...loaded);
        isLoading.value = false;

        if (loaded.length === 0) {
          guard.value = true;
        }

        filter.value.offset += 5;
      }
    };

    watch(filterSelected, (newF, oldF) => {
      if (props.entity === RouteEntity.Competition) {
        loadMore(true);
      }
    });

    const onNavigate = (id: any, entity: string) => {
      router.push(
        localizePath({
          path: "/" + entity + "/" + id,
        })
      );
    };

    const showFilter = computed(() => {
      return sports.length > 1;
    });

    return {
      t,
      loadMore,
      guard,
      teamResults,
      isLoading,
      showFilter,
      sports,
      filterSelected,
      onNavigate,
    };
  },
});
