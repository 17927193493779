
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { OtherResult } from "@/interfaces";

import ResultMedal from "@/components/Frontend/TeamResult/ResultMedal.vue";

export default defineComponent({
  name: "OtherResultIntermiadiateBlock",
  components: {
    ResultMedal,
  },
  props: {
    otherResult: {
      type: Object as PropType<OtherResult>,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
