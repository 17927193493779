import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "entity-list-view"
}
const _hoisted_2 = { class: "container pt-0" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "flex-shrink-0" }
const _hoisted_5 = { class: "avatar avatar-circle" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "avatar-img",
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_8 = { class: "flex-grow-1 ms-3 mb-0" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  style: {"padding":"0.5rem"},
  class: "ms-2 badge bg-soft-primary text-primary"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 0,
  style: {"padding":"0.5rem"},
  class: "ms-2 badge bg-soft-primary text-primary"
}
const _hoisted_17 = { class: "pt-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityFilters = _resolveComponent("EntityFilters")!
  const _component_MrUiKitTable = _resolveComponent("MrUiKitTable")!
  const _component_MrUiKitPagination = _resolveComponent("MrUiKitPagination")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_EntityFilters, {
            entity: _ctx.entity,
            onFilter: _ctx.onFilter
          }, null, 8, ["entity", "onFilter"]),
          _createVNode(_component_MrUiKitTable, {
            search: true,
            onSearch: _ctx.onSearch,
            rows: _ctx.entities,
            columns: _ctx.columns,
            searchLabel: _ctx.searchLabel,
            fixedWidth: _ctx.entity === 'trainers' || _ctx.entity === 'athletes' ? 3 : null
          }, _createSlots({
            fullname: _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.itemUrl(item))
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "avatar-img",
                          src: _ctx.itemUrl(item)
                        }, null, 8, _hoisted_6))
                      : (_openBlock(), _createElementBlock("img", _hoisted_7))
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h5", {
                    class: "mb-0 link-navigate",
                    onClick: () => _ctx.onNavigate(item)
                  }, _toDisplayString(item.fullname), 9, _hoisted_9)
                ])
              ])
            ]),
            _: 2
          }, [
            (_ctx.entity === 'trainers' || _ctx.entity === 'athletes')
              ? {
                  name: "organizations",
                  fn: _withCtx(({ item }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.organizations, (organization, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        onClick: () => _ctx.onNavigateOrgTra(organization, 'organizations'),
                        class: "link-navigate"
                      }, [
                        _createElementVNode("span", null, _toDisplayString(organization.fullname), 1),
                        (
                item.organizations.length &&
                index < item.organizations.length - 1
              )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, " , "))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_10))
                    }), 128)),
                    (item.countOrganizations > 3)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " + " + _toDisplayString(item.countOrganizations - 3), 1))
                      : _createCommentVNode("", true)
                  ])
                }
              : undefined,
            (_ctx.entity === 'athletes')
              ? {
                  name: "trainers",
                  fn: _withCtx(({ item }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.trainers, (trainer, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        onClick: () => _ctx.onNavigateOrgTra(trainer, 'trainers'),
                        class: "link-navigate"
                      }, [
                        (index < 3)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(trainer.firstname) + " " + _toDisplayString(trainer.middlename) + " " + _toDisplayString(trainer.lastname), 1))
                          : _createCommentVNode("", true),
                        (item.trainers.length && index < item.trainers.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, " , "))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_13))
                    }), 128)),
                    (item.countTrainers > 3)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, " + " + _toDisplayString(item.countTrainers - 3), 1))
                      : _createCommentVNode("", true)
                  ])
                }
              : undefined
          ]), 1032, ["onSearch", "rows", "columns", "searchLabel", "fixedWidth"]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_MrUiKitPagination, {
              modelValue: _ctx.pagination,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
              onLoad: _ctx.onLoad
            }, {
              default: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.t("pagination.page")) + " " + _toDisplayString(_ctx.pagination.current) + " " + _toDisplayString(_ctx.t("pagination.of")) + " " + _toDisplayString(slotProps.lastPage), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "onLoad"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}