import { Athlete, Count, CreateTrainer, RouteEntity, Trainer, TrainerForm, Organization, UpdateTrainerForm, TrainerMultiLang, UpdateTrainerBody, FilterByOrganization, Filter, CountFilterByOrganization } from "@/interfaces";
import axios from "axios";
import AccountService from "./AccountService";
import FilterService from "./FilterService";

class TrainerService {
    constructor() {}

    async count(filter?: CountFilterByOrganization): Promise<Count> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Count>('/trainers/count' + '?' + parsedFilter);
        return response.data;
    }
    
    async get(lang: string, filter: FilterByOrganization): Promise<Organization[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Organization[]>('/trainers' + '?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getById(id: number, lang: string): Promise<Trainer> {
        const response = await axios.get<Trainer>('/trainers/' + id + '?lang=' + lang);
        return response.data;
    }

    async getMultiLangById(id: number, lang: string): Promise<UpdateTrainerForm> {
        const { data } = await axios.get<TrainerMultiLang>('/trainers/' + id + '/multilang?lang=' + lang);
        return {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            cover: {
                file: data.cover ? data.cover : null,
                url: data.cover ? data.cover.url : null
            },
            photo: {
                file: data.photo ? data.photo : null,
                url: data.photo ? data.photo.url : null
            },
            organizations: data.organizations,
            email: data.email,
            publicEmail: data.publicEmail,
            telephone: data.telephone,
            publicTelephone: data.publicTelephone,
            instagram: data.instagram,
            facebook: data.facebook,
            dateOfBirth: data.dateOfBirth,
            active: data.active,
            deleted: !data.deleted,
            trainerRole: data.trainerRole,
            bio: {
                it: data.it && data.it.bio ? data.it.bio : '',
                en: data.en && data.en.bio ? data.en.bio : '',
            }
        }
    }
    
    async countSubList(id: number, type: RouteEntity, lang?: string): Promise<Count> {
        const response = await axios.get<Count>('/trainers/' + id + '/' + type + '/count' + (lang ? '?lang=' + lang : ''));
        return response.data;
    }

    async getAthletes(id: number, lang: string, filter: FilterByOrganization): Promise<Athlete[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Athlete[]>('/trainers/' + id + '/athletes?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async create(form: TrainerForm): Promise<Trainer> {
        const userCredentials = await AccountService.checkAccount(form.userCredentials);

        const body: CreateTrainer = {
            id: form.id,
            userCredentials,
            organizations: form.organizations.map((o: Organization) => o.id),
            firstname: form.firstname,
            lastname: form.lastname,
            photoId: form.photo.file ? form.photo.file.id : undefined
        }
        const { data } = await axios.post<Trainer>('/trainers', body);
        return data;
    }

    async updateById(form: UpdateTrainerForm): Promise<void> {
        const body: UpdateTrainerBody = {
            coverId: form.cover && form.cover.file ? form.cover.file.id : null,
            photoId: form.photo && form.photo.file ? form.photo.file.id : null,
            firstname: form.firstname,
            lastname: form.lastname,
            organizations: form.organizations.map(o => o.id),
            email: form.email,
            publicEmail: form.publicEmail,
            telephone: form.telephone,
            facebook: form.facebook,
            instagram: form.instagram,
            publicTelephone: form.publicTelephone,
            active: form.active,
            deleted: !form.deleted,
            trainerRoleId: form.trainerRole ? form.trainerRole.id : 0,
            it: {
                bio: form.bio.it,
            },
            en: {
                bio: form.bio.en
            }
        }
        await axios.patch('/trainers/' + form.id, body);
    }
}

export default new TrainerService();