import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "next-competition-view" }
const _hoisted_2 = {
  style: {"min-height":"52px"},
  class: "d-flex mx-md-auto mb-4 justify-content-between"
}
const _hoisted_3 = { style: {"margin-bottom":"0px"} }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "img-fluid",
  style: {"width":"auto","height":"82px"},
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "small mb-4" }
const _hoisted_9 = { class: "small mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowMoreFilters = _resolveComponent("ShowMoreFilters")!
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!
  const _component_MrUiKitList = _resolveComponent("MrUiKitList")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfiniteScroll, {
      guard: _ctx.guard,
      rowClasses: ['d-flex', 'justify-content-center'],
      containerClasses: ['container', 'pt-0'],
      isLoading: _ctx.isLoading,
      onLoad: _ctx.loadMore
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('home.nextCompetition.title')), 1),
          _createVNode(_component_ShowMoreFilters, { onReload: _ctx.reload }, null, 8, ["onReload"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_MrUiKitList, {
          classes: ['mb-4'],
          columns: 4,
          items: _ctx.competitions
        }, {
          default: _withCtx(({ item }) => [
            _createVNode(_component_MrUiKitCard, {
              classes: ['h-100'],
              footerClasses: ['pt-0'],
              bodyClasses: ['pb-0']
            }, {
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.startedAt) + " - " + _toDisplayString(item.endedAt), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (item.photo)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "img-fluid",
                        style: {"width":"auto","height":"82px"},
                        src: item.photo.url
                      }, null, 8, _hoisted_5))
                    : (_openBlock(), _createElementBlock("img", _hoisted_6))
                ]),
                _createElementVNode("h3", {
                  class: "card-title link-navigate",
                  onClick: () => _ctx.onClick(item)
                }, _toDisplayString(item.name), 9, _hoisted_7),
                _createElementVNode("div", _hoisted_8, _toDisplayString(item.location), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _: 1
    }, 8, ["guard", "isLoading", "onLoad"])
  ]))
}