
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { MrUiKitTable, MrUiKitPagination, MrUiKitAvatar } from "mr-ui-kit";
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  FormattedFilterResult,
  Organization,
  PaginationModel,
  RouteEntity,
} from "@/interfaces";
import FilterService from "@/services/FilterService";
import { localizePath } from "@/plugins/i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "SearchResult",
  components: {
    MrUiKitTable,
    MrUiKitPagination,
    MrUiKitAvatar,
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const isMounted = ref(false);
    const keyword = ref("");
    const items = ref<FormattedFilterResult[]>([]);
    const store = useStore();
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "fullname",
      },
      {
        label: computed(() => t("common.organizations")),
        field: "organizations",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      current: 0,
      count: 0,
      limit: 10,
    });

    onMounted(async () => {
      keyword.value = route.query.keyword as string;
      await onInit();
      isMounted.value = true;
    });

    onBeforeUpdate(async () => {
      if (route.query.keyword !== keyword.value) {
        keyword.value = route.query.keyword as string;
        await onInit();
      }
    });

    const onInit = async () => {
      const total = await FilterService.count(keyword.value);
      pagination.count = total.count;
      pagination.current = 1;
      await onLoad(0);
    };

    const onLoad = async (offset: number) => {
      items.value = await FilterService.getByKeywords(
        keyword.value,
        locale.value,
        {
          limit: 10,
          offset,
        }
      );
    };

    const onSearch = (search: string) => {
      keyword.value = search;
      onInit();
    };

    const onNavigate = (item: FormattedFilterResult) => {
      router.push(
        localizePath({
          path: "/" + item.entity + "/" + item.id,
        })
      );

      let type = BreadcrumbStructureItem.Federation;
      switch (item.entity) {
        case RouteEntity.Federation:
          type = BreadcrumbStructureItem.Federation;
          break;
        case RouteEntity.Organization:
          type = BreadcrumbStructureItem.Organization;
          break;
        case RouteEntity.Trainer:
          type = BreadcrumbStructureItem.Trainer;
          break;
        case RouteEntity.Athlete:
          type = BreadcrumbStructureItem.Athlete;
          break;
      }
      store.commit("pushBreadcrumb", {
        label: item.fullname,
        path: "/" + item.entity + "/" + item.id,
        type,
      });
    };

    // const organizations = (itemOrganizations: Organization[]) => {
    //   return itemOrganizations.splice(0, 3);
    // };

    const onNavigateOrg = (item: any, entity: string) => {
      router.push(
        localizePath({
          path: "/" + entity + "/" + item.id,
        })
      );
    };

    return {
      t,
      onSearch,
      onNavigate,
      onLoad,
      // organizations,
      items,
      columns,
      isMounted,
      pagination,
      onNavigateOrg,
    };
  },
});
