
	import { GeneralFormatted, IntermiadiateForm } from '@/interfaces';
	import { computed, defineComponent, PropType, reactive, ref } from 'vue';
	import {
		MrUiKitSelect,
		MrUiKitInput,
		MrUiKitMultiLanguage,
		MrUiKitTag,
		MrUiKitInputMask,
		MrUiKitLabel,
	} from 'mr-ui-kit';
	import { useStore } from 'vuex';
	import OpponentService from '@/services/OpponentService';
	import { useI18n } from 'vue-i18n';
	import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'ResultBlockForm',
		emits: ['touch'],
		components: {
			MrUiKitSelect,
			MrUiKitInput,
			MrUiKitMultiLanguage,
			MrUiKitTag,
			MrUiKitInputMask,
			MrUiKitLabel,
		},
		props: {
			modelValue: {
				type: Object as PropType<IntermiadiateForm>,
				default: null,
			},
			text: {
				type: String,
				default: '',
			},
			status: {
				type: Object,
				default: null
			}
		},
		setup(props, { emit }) {
			const { t } = useI18n();
			const store = useStore();
			const currentLanguage = reactive({
				label: 'IT',
				value: 'it',
			});
			const opponents = ref<GeneralFormatted[]>([]);
			const model = reactive(
				props.modelValue || {
					competitionResultType: null,
					isLeft: true,
					leftResult: '0',
					rightResult: null,
					opponent: null,
					notes: {
						it: '',
						en: '',
					},
				},
			);

			const toggleSide = () => {
				model.isLeft = !model.isLeft;
			};

			const onSearchOpponent = async (search: string) => {
				opponents.value = await OpponentService.search(search);
			};

			const onEnterOpponent = async (search: string) => {
				const newOpponent = await OpponentService.create(search);
				model.opponent = newOpponent;
			};

			const touchResult = (name: string) => {
				emit('touch', name);
			}

			return {
				t,
				toggleSide,
				model,
				languages: store.getters.supportedLanguages.map((l: any) => {
					return {
						value: l.id.toLowerCase(),
						label: l.id.toUpperCase(),
					};
				}),
				currentLanguage: currentI18nLanguage,
				opponents,
				onSearchOpponent,
				onEnterOpponent,
				touchResult,
				resultTypes: store.getters.resultTypes,
			};
		},
	});
