import { AccountEntity, Count, Filter, FormattedAccount, ResetPassword, RouteEntity } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";

class AccountService {
    constructor() {}

    async countSubList(entity: RouteEntity, lang?: string): Promise<Count> {
        const response = await axios.get<Count>('/accounts/' + entity + '/count' + (lang ? '?lang=' + lang : ''));
        return response.data;
    }

    async getEntities(entity: RouteEntity, filter: Filter, lang?: string): Promise<AccountEntity[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<AccountEntity[]>('/accounts/' + entity + '?' + (lang ? 'lang=' + lang + '&' + parsedFilter : parsedFilter))
        return response.data;
    }

    async deleteEntityById(id: number, entity: RouteEntity): Promise<void> {
        await axios.delete<any>('/accounts/' + entity + '/' + id);
    }

    async resetPassword(form: ResetPassword): Promise<boolean> {
        const response = await axios.post<any>('/users/reset-password', form);
        return true;
    }

    async checkAccount(emails: string[]): Promise<number[]> {
        const response = await axios.post<number[]>('/users/check-email', emails);
        return response.data;
    }

    async findAccountsByEntityId(entity: RouteEntity, entityId: number): Promise<FormattedAccount[]> {
        const response = await axios.get<FormattedAccount[]>('/accounts/' + entity + '/' + entityId);
        return response.data;
    }

    async saveAccountsToEntity(entity: RouteEntity, entityId: number, body: FormattedAccount[]): Promise<void> {
        await axios.post('/accounts/' + entity + '/' + entityId + '/save', body);
    }
}

export default new AccountService();