import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "result-list-backoffice" }
const _hoisted_2 = { class: "text-capitalize" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-flex pt-4 pb-4 align-items-center" }
const _hoisted_5 = { class: "fs-5" }
const _hoisted_6 = { class: "ms-auto" }
const _hoisted_7 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_ResultTable = _resolveComponent("ResultTable")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_MrUiKitNavTab = _resolveComponent("MrUiKitNavTab")!
  const _component_CreateResultForm = _resolveComponent("CreateResultForm")!
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitCard, {
      headerClasses: ['border-bottom'],
      bodyClasses: ['pt-0']
    }, {
      header: _withCtx(() => [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.t('backoffice.sideNavTabs.contents.results')), 1)
      ]),
      default: _withCtx(() => [
        (_ctx.showCurrentForm === 'all')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t('backoffice.sideNavTabs.subtitles.results')) + " " + _toDisplayString(_ctx.t('common.' + _ctx.entity)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_MrUiKitButton, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpenAdd({ type: 'new' })))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('common.operations.add')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_MrUiKitNavTab, { mode: "tab" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MrUiKitTab, {
                      name: _ctx.t('common.tabs.result')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ResultTable, {
                          type: "team",
                          onUpdate: _ctx.onOpenAdd,
                          entity: _ctx.entity
                        }, null, 8, ["onUpdate", "entity"])
                      ]),
                      _: 1
                    }, 8, ["name"]),
                    _createVNode(_component_MrUiKitTab, {
                      name: _ctx.t('common.tabs.otherResult')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ResultTable, {
                          type: "single",
                          onUpdate: _ctx.onOpenAdd,
                          entity: _ctx.entity
                        }, null, 8, ["onUpdate", "entity"])
                      ]),
                      _: 1
                    }, 8, ["name"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createBlock(_component_CreateResultForm, {
              key: 1,
              value: _ctx.currentResultId,
              mode: _ctx.showCurrentForm,
              entity: _ctx.entity,
              onBack: _ctx.onCloseAdd
            }, null, 8, ["value", "mode", "entity", "onBack"]))
      ]),
      _: 1
    })
  ]))
}