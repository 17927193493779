import { CreateStaff, Staff, StaffForm } from '@/interfaces';
import { RouteEntity } from '@/interfaces/metadata';
import axios from 'axios';

class StaffService {
    constructor() {}

    async createOrUpdate(entity: RouteEntity, entityId: number, form: StaffForm): Promise<Staff> {
        const body: CreateStaff = {
            fullname: form.fullname,
            photoId: form.photo.file ? form.photo.file.id : undefined,
            staffRoleId: form.staffRole?.id as number,
            email: form.email || undefined,
            entityId
        }

        // If there is ID
        // Is update
        const isUpdate = form.id != null;
        const url = '/staffs/' + entity + (isUpdate ? '/' + form.id : '');
        if (!isUpdate) {
            const { data } = await axios.post<Staff>(url, body);
            return data;
        } else {
            const { data } = await axios.patch<Staff>(url, body);
            return data;
        }
    }
}

export default new StaffService();