
	import { computed, defineComponent, PropType, reactive, ref } from 'vue';
	import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
	import { useStore } from 'vuex';
	import { Federation, FederationForm } from '@/interfaces';
	import FileService from '@/services/FileService';
	import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'CreateFederationModal',
		emits: ['submit', 'close'],
		components: {
			MrUiKitModal,
			MrUiKitButton,
			MrUiKitForm,
		},
		setup(props, { emit }) {
			const { t } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});

			const store = useStore();
			const form = reactive<FederationForm>({
				logo: {},
				userCredentials: [],
				fullname: {
					it: '',
					en: '',
				},
				sports: [],
			});
			const fields = reactive([
				{
					component: 'MrUiKitFileUpload',
					name: 'logo',
					props: {
						label: 'File',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'fullname',
					props: {
						label: computed(() => t('common.fields.fullname')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
				{
					component: 'MrUiKitSelect',
					name: 'sports',
					props: {
						label: 'Sport',
						labelPosition: 'left',
						labelKey: 'name',
						multiple: true,
						items: store.getters.sports,
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitSelect',
					name: 'userCredentials',
					props: {
						labelKey: 'email',
						label: 'Account',
						labelPosition: 'left',
						key: 'id',
						multiple: true,
						chips: true,
						items: [],
						classes: ['h-25'],
						hintMessage: 'Hint',
					},
				},
			]);

			const onSubmit = () => {
				emit('submit', form);
			};

			const onClose = () => {
				emit('close');
			};

			const onUpdateForm = async (event: any) => {
				if (form == null) return;
				const field: keyof FederationForm = event.item.name;

				switch (event.type) {
					case 'upload':
						const toUpload = form[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(form[field].file);
							form[field].url = uploadedFile.url;
							form[field].file = uploadedFile;
						}
						break;
					default:
						return;
				}
			};

			return {
				t,
				onClose,
				onSubmit,
				onUpdateForm,
				form,
				fields,
			};
		},
	});
