
import { defineComponent, ref } from 'vue'
import { MrUiKitCard, MrUiKitTab, MrUiKitNavTab } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';

import SportList from '@/components/Backoffice/Metadata/SportList.vue';
import DisciplineList from '@/components/Backoffice/Metadata/DisciplineList.vue';
import StaffRoleList from '@/components/Backoffice/Metadata/StaffRoleList.vue';
import TrainerRoleList from '@/components/Backoffice/Metadata/TrainerRoleList.vue';

export default defineComponent({
    name: 'ManageMetadata',
    components: {
        MrUiKitCard,
        MrUiKitNavTab,
        MrUiKitTab,
        SportList,
        DisciplineList,
        StaffRoleList,
        TrainerRoleList
    },
    setup() {
        const { t } = useI18n();
        const showModal = ref(false);

        const onOpenAdd = () => {
            showModal.value = true;
        }

        const onUpdate = () => {
            showModal.value = true;
        }

        const onCloseModal = () => {
            showModal.value = false;
        }

        return {
            t,
            onOpenAdd,
            onUpdate,
            onCloseModal,
            showModal,
        }
    },
})
