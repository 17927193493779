
import { PaginationModel, RouteEntity, Staff, StaffForm } from '@/interfaces'
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router';
import FederationService from '@/services/FederationService';
import OrganizationService from '@/services/OrganizationService';
import AthleteService from '@/services/AthleteService';
import StaffService from '@/services/StaffService';
import { useStore } from 'vuex';
import { MrUiKitCard, MrUiKitButton, MrUiKitModal, MrUiKitTable, MrUiKitPagination } from 'mr-ui-kit';

import StaffModal from '@/components/Backoffice/Modals/StaffModal.vue';
import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';
import axios from 'axios';

export default defineComponent({
    name: 'StaffList',
    components: {
        MrUiKitCard,
        MrUiKitButton,
        MrUiKitModal,
        MrUiKitTable,
        MrUiKitPagination,
        StaffModal,
        DeleteEntityModal
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Federation
        }
    },
    setup(props) {
        const { t, locale } = useI18n();
        const store = useStore();
        const route = useRoute();
        const entityId = ref<number>(-1);
        const showModal = ref<boolean>(false);
        const showDeleteModal = ref(false);
        const selectedStaff = ref<Staff | null>(null);
        const currentStaff = ref<StaffForm>({
            fullname: '',
            photo: {},
            staffRole: null,
            email: null
        });
        const staffs = ref<Staff[]>([]);
        const isLoading = ref<boolean>(false);
        const pagination = reactive<PaginationModel>({
            current: 1,
            count: 0,
            limit: 10
        });
        const columns = reactive([
            {
                label: computed(() => t('common.fields.name')),
                field: 'fullname'
            },
            {
                label: computed(() => t('common.fields.role')),
                field: 'role'
            },
            {
                label: '',
                field: 'action'
            }
        ]);

        onMounted(async () => {
            entityId.value = +route.params.id;
            await onInit();
        });

        const onInit = async () => {
            switch (props.entity) {
                case RouteEntity.Federation:
                    const fedCount = await FederationService.countSubList(entityId.value, RouteEntity.Staff);
                    pagination.count = fedCount.count;
                    break;
                case RouteEntity.Organization:
                    const organizationCount = await OrganizationService.countSubList(entityId.value, RouteEntity.Staff);
                    pagination.count = organizationCount.count;
                    break;
            }

            await loadMore(0);
            pagination.current = 1;
        }

        const loadMore = async (offset: number) => {
            if (!isLoading.value) {
                isLoading.value = true;

                switch (props.entity) {
                    case RouteEntity.Federation:
                        staffs.value = await FederationService.getStaff(entityId.value, locale.value, {
                            limit: 10,
                            offset
                        });
                        break;
                    case RouteEntity.Organization:
                        staffs.value = await OrganizationService.getStaff(entityId.value, locale.value, {
                            limit: 10,
                            offset
                        });
                        break;
                }

                isLoading.value = false;
            }
        }

        const onOpen = (item: Staff | null) => {
            if (item == null) {
                currentStaff.value = {
                    fullname: '',
                    photo: {},
                    staffRole: null,
                    email: null
                };
            } else {
                currentStaff.value = {
                    id: item.id,
                    fullname: item.fullname,
                    photo: item.photo ? {
                        file: item.photo,
                        url: item.photo.url
                    } : {},
                    staffRole: item.staffRole,
                    email: item.email || null
                }
            }
            showModal.value = true;
        }

        const onSubmitStaff = async () => {
            try {
                await StaffService.createOrUpdate(props.entity, entityId.value, currentStaff.value);
                await loadMore(0);
                pagination.current = 1;
                store.commit('setAlert', {
                    type: 'success',
                    message: currentStaff.value.id ? t('messages.generalSuccess.update') : t('messages.generalSuccess.create')
                })
            } catch (err) {
                store.commit('setAlert', {
                    type: 'danger',
                    message: currentStaff.value.id ? t('messages.generalError.update') : t('messages.generalError.create')
                })
            }
        }

        const onDelete = (item: Staff) => {
            selectedStaff.value = item;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedStaff.value) {
                try {    
                    await axios.delete('/staffs/' + props.entity + '/' + selectedStaff.value.id);
                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        return {
            t,
            entityId,
            showModal,
            showDeleteModal,
            onOpen,
            loadMore,
            onSubmitStaff,
            onDelete,
            onSubmitDelete,
            pagination,
            columns,
            staffs,
            currentStaff,
            selectedStaff
        }
    },
})
