import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "national-list" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = {
  style: {"width":"82px","height":"82px"},
  class: "avatar avatar-circle mb-4"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "avatar-img",
  style: {"width":"82px","height":"82px"},
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "container justify-content-center text-center p-7 ps-0 pe-0",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!
  const _component_MrUiKitList = _resolveComponent("MrUiKitList")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfiniteScroll, {
      guard: _ctx.guard,
      rowClasses: ['d-flex'],
      isLoading: _ctx.isLoading,
      onLoad: _ctx.loadMore
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.t('common.nationals')), 1)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.nationals.length)
          ? (_openBlock(), _createBlock(_component_MrUiKitList, {
              key: 0,
              columns: 4,
              items: _ctx.nationals,
              classes: ['mb-4']
            }, {
              default: _withCtx(({ item }) => [
                _createVNode(_component_MrUiKitCard, {
                  bodyClasses: [
								'h-100',
								'justify-content-center',
								'd-flex',
								'flex-column',
								'align-items-center',
							],
                  footerClasses: ['pt-0'],
                  classes: ['h-100']
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (item.logo)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "avatar-img",
                            style: {"width":"82px","height":"82px"},
                            src: item.logo.url
                          }, null, 8, _hoisted_4))
                        : (_openBlock(), _createElementBlock("img", _hoisted_5))
                    ]),
                    _createElementVNode("h4", {
                      class: "card-title link-navigate",
                      onClick: () => _ctx.onClick(item)
                    }, _toDisplayString(item.fullname), 9, _hoisted_6)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["items"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t("common.noNationals")), 1))
      ]),
      _: 1
    }, 8, ["guard", "isLoading", "onLoad"])
  ]))
}