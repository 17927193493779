
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import { MrUiKitTable, MrUiKitButton, MrUiKitPagination } from 'mr-ui-kit';
import { OtherResult, PaginationModel, RouteEntity, TeamResult } from '@/interfaces';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CompetitionService from '@/services/CompetitionService';

import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'TeamTable',
    emits: ['update'],
    components: {
        MrUiKitTable,
        MrUiKitButton,
        MrUiKitPagination,
        DeleteEntityModal
    },
    props: {
        type: {
            type: String as PropType<'team' | 'single'>,
            default: 'team'
        },
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Organization
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const store = useStore();
        const entityId = ref<number>(-1);
        const route = useRoute();
        const showDeleteModal = ref(false);
        const selectedResult = ref<TeamResult | null>(null);
        const rows = ref<TeamResult[] | OtherResult[]>([]);
        const columns = reactive([
            {
                label: computed(() => t('common.fields.name')),
                field: 'name'
            },
            {
                label: computed(() => t('result.position')),
                field: 'results'
            },
            {
                label: '',
                field: 'action'
            }
        ]);
        const pagination = reactive<PaginationModel>({
            current: 1,
            count: 0,
            limit: 10
        });

        onMounted(async () => {
            entityId.value = +route.params.id;
            await onInit();
        })

        const onInit = async () => {
            switch (props.type) {
                case 'team':
                    const teamTotal = await CompetitionService.countTeamResultsByEntityId(entityId.value, props.entity, locale.value);
                    pagination.count = teamTotal.count;
                    break;
                case 'single':
                    const singleTotal = await CompetitionService.countOtherResultsByEntityId(entityId.value, props.entity, locale.value);
                    pagination.count = singleTotal.count;
                    break;
            }
            await loadMore(0);
            pagination.current = 1;
        }

        const loadMore = async (offset: number) => {
            switch (props.type) {
                case 'team':
                    rows.value = await CompetitionService.getTeamResultsByEntityId(entityId.value, props.entity, locale.value, {
                        limit: 10,
                        offset
                    })
                    break;
                case 'single':
                    rows.value = await CompetitionService.getOtherResultsByEntityId(entityId.value, props.entity, locale.value, {
                        limit: 10,
                        offset
                    })
                    break;
            }
        }

        const onUpdate = (item: TeamResult | OtherResult) => {
            emit('update', {
                type: props.type,
                item
            });
        }

        const onDelete = (item: any) => {
            selectedResult.value = item;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedResult.value) {
                try {
                    switch (props.type) {
                        case 'team':
                            await axios.delete('/competitions/results/' + selectedResult.value.id);
                            break;
                        case 'single':
                            await axios.delete('/others/results/' + selectedResult.value.id);
                            break;
                    }

                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        return {
            t,
            rows,
            columns,
            pagination,
            entityId,
            onDelete,
            onUpdate,
            loadMore,
            onSubmitDelete,
            selectedResult,
            showDeleteModal
        }
    },
})
