
import { defineComponent, reactive } from "vue";
import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import * as validators from "@vuelidate/validators";
import i18Validators from "@/utils/i18n-validators";

export default defineComponent({
  name: "CreateVisitorModal",
  emits: ["submit", "close"],
  components: {
    MrUiKitModal,
    MrUiKitButton,
    MrUiKitForm,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const form = reactive({
      email: "",
    });
    const withI18nMessage = validators.createI18nMessage({ t });
    const required = withI18nMessage(validators.required, {
      messagePath: i18Validators.required,
    });
    const fields = reactive([
      {
        component: "MrUiKitInput",
        name: "email",
        props: {
          label: "Email",
          labelPosition: "left",
        },
        rules: {
          required,
        },
      },
    ]);

    const onSubmit = () => {
      emit("submit", form);
    };

    const onClose = () => {
      emit("close");
    };

    return {
      t,
      onClose,
      onSubmit,
      form,
      fields,
    };
  },
});
