
import { CompetitionForm, PaginationModel } from "@/interfaces";
import { Competition } from "@/interfaces";
import FederationService from "@/services/FederationService";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  MrUiKitCard,
  MrUiKitButton,
  MrUiKitModal,
  MrUiKitTable,
  MrUiKitPagination,
} from "mr-ui-kit";
import CompetitionService from "@/services/CompetitionService";
import axios from "axios";
import CreateCompetitionModal from "@/components/Backoffice/Modals/CreateCompetitionModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import { useStore } from "vuex";
import moment from "moment";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "CompetitionList",
  components: {
    MrUiKitCard,
    MrUiKitButton,
    MrUiKitModal,
    MrUiKitTable,
    MrUiKitPagination,
    CreateCompetitionModal,
    DeleteEntityModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const showModal = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const showDeleteModal = ref(false);
    const selectedCompetition = ref<Competition | null>(null);
    const modalCompetition = ref<CompetitionForm>({
      photo: {},
      location: "",
      startedAt: new Date(),
      endedAt: new Date(),
      haveMedals: false,
      name: {
        it: "",
        en: "",
      },
    });
    const entityId = ref<number>(-1);
    const competitions = ref<Competition[]>([]);
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "name",
      },
      {
        label: computed(() => t("common.fields.period")),
        field: "period",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: 10,
    });

    onMounted(async () => {
      entityId.value = +route.params.id;
      await onInit();
    });

    const onInit = async () => {
      const total = await FederationService.countCompetitions(
        entityId.value,
        locale.value
      );
      pagination.count = total.count;
      await loadMore(0);
      pagination.current = 1;
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;
        competitions.value = await FederationService.getCompetitions(
          entityId.value,
          locale.value,
          {
            limit: 10,
            offset,
          }
        );
        isLoading.value = false;
      }
    };

    const onOpenModal = async (item?: Competition) => {
      if (item) {
        const multiLang = await CompetitionService.getMultiLanguageById(
          item.id
        );
        modalCompetition.value = {
          ...multiLang,
          photo: item.photo
            ? {
                file: item.photo,
                url: item.photo.url,
              }
            : {},
          name: {
            it: multiLang.it.name,
            en: multiLang.en ? multiLang.en.name : "",
          },
          haveMedals: multiLang.haveMedals === true,
          startedAt: moment(multiLang.startedAt, "DD/MM/YYYY").toDate(),
          endedAt: moment(multiLang.endedAt, "DD/MM/YYYY").toDate(),
        };
      } else {
        modalCompetition.value = {
          photo: {},
          location: "",
          startedAt: new Date(),
          endedAt: new Date(),
          haveMedals: false,
          name: {
            it: "",
            en: "",
          },
        };
      }
      showModal.value = true;
    };

    const onDelete = (item: Competition) => {
      selectedCompetition.value = item;
      showDeleteModal.value = true;
    };

    const onSubmitDelete = async () => {
      if (selectedCompetition.value) {
        try {
          await axios.delete("/competitions/" + selectedCompetition.value.id);
          await loadMore(0);
          pagination.current = 1;
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const onSubmit = async () => {
      await CompetitionService.createOrUpdate(
        entityId.value,
        modalCompetition.value as any
      );
      await loadMore(0);
      showModal.value = false;
      pagination.current = 1;
    };

    const onNavigateFrontOffice = (item: Competition) => {
      router.push(
        localizePath({
          path: "/competitions/" + item.id,
        })
      );
    };
    return {
      t,
      loadMore,
      onOpenModal,
      onSubmit,
      onDelete,
      onSubmitDelete,
      onNavigateFrontOffice,
      pagination,
      modalCompetition,
      competitions,
      columns,
      entityId,
      showModal,
      showDeleteModal,
      selectedCompetition,
    };
  },
});
