
import { computed, defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitSideNav, MrUiKitTitle, MrUiKitTab } from 'mr-ui-kit';

import FederationPersonalData from '@/components/Backoffice/Federation/FederationPersonalData.vue';
import StaffList from '@/components/Backoffice/Common/StaffList.vue';
import SportClubList from '@/components/Backoffice/Common/SportClubList.vue';
import TrainerList from '@/components/Backoffice/Common/TrainerList.vue';
import AthleteList from '@/components/Backoffice/Common/AthleteList.vue';
import CompetitionList from '@/components/Backoffice/Federation/CompetitionList.vue';
import ManageAccount from '@/components/Backoffice/Common/ManageAccount.vue';

export default defineComponent({
    name: 'FederationBackofficeView',
    components: {
        FederationPersonalData,
        StaffList,
        SportClubList,
        TrainerList,
        AthleteList,
        CompetitionList,
        ManageAccount,
        MrUiKitSideNav,
        MrUiKitTitle,
        MrUiKitTab
    },
    setup() {
        const { t } = useI18n();
        const collapse = ref(false);
        const sideNavLabel = reactive({
            detailsTitle: computed(() => t('backoffice.sideNavTabs.details.information')),
            executiveTitle: computed(() => t('backoffice.sideNavTabs.details.executive')),
            sportClubTitle: computed(() => t('backoffice.sideNavTabs.contents.sportClubs')),
            trainerTitle: computed(() => t('backoffice.sideNavTabs.contents.trainers')),
            athleteTitle: computed(() => t('backoffice.sideNavTabs.contents.athletes')),
            competitionTitle: computed(() => t('backoffice.sideNavTabs.contents.competitions')),
            accountTitle: computed(() => t('backoffice.sideNavTabs.management.account'))
        });

        return {
            t,
            collapse,
            sideNavLabel
        }
    },
})
