import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "federation-backoffice-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitTitle = _resolveComponent("MrUiKitTitle")!
  const _component_FederationPersonalData = _resolveComponent("FederationPersonalData")!
  const _component_MrUiKitTab = _resolveComponent("MrUiKitTab")!
  const _component_StaffList = _resolveComponent("StaffList")!
  const _component_SportClubList = _resolveComponent("SportClubList")!
  const _component_TrainerList = _resolveComponent("TrainerList")!
  const _component_AthleteList = _resolveComponent("AthleteList")!
  const _component_CompetitionList = _resolveComponent("CompetitionList")!
  const _component_ManageAccount = _resolveComponent("ManageAccount")!
  const _component_MrUiKitSideNav = _resolveComponent("MrUiKitSideNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitSideNav, {
      collapsed: _ctx.collapse,
      onToggle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapse = !_ctx.collapse)),
      closeLabel: _ctx.t('common.operations.closeMenu'),
      openLabel: _ctx.t('common.operations.openMenu')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MrUiKitTitle, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backoffice.sideNavTabs.details.title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.detailsTitle,
          icon: 'bi bi-info-square'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FederationPersonalData)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.executiveTitle,
          icon: 'bi bi-person-badge'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StaffList)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTitle, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backoffice.sideNavTabs.contents.title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.sportClubTitle,
          icon: 'bi bi-list-ul'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SportClubList)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.trainerTitle,
          icon: 'bi bi-person-lines-fill'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TrainerList)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.athleteTitle,
          icon: 'bi bi-people'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AthleteList)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.competitionTitle,
          icon: 'bi bi-trophy'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CompetitionList)
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_component_MrUiKitTitle, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('backoffice.sideNavTabs.management.title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_MrUiKitTab, {
          name: _ctx.sideNavLabel.accountTitle,
          icon: 'bi bi-people-fill'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ManageAccount)
          ]),
          _: 1
        }, 8, ["name"])
      ]),
      _: 1
    }, 8, ["collapsed", "closeLabel", "openLabel"])
  ]))
}