
import { Credentials } from '@/interfaces'
import { computed, defineComponent, PropType, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';

    import * as validators from '@vuelidate/validators';
    import { currentI18nLanguage } from '@/plugins/i18n';
    import i18Validators from '@/utils/i18n-validators';




export default defineComponent({
    name: 'FirstResetPasswordForm',
    emits: ['update:modelValue', 'click' ],
    components: {
        MrUiKitButton,
        MrUiKitForm
    },
    props: {
        modelValue: {
            type: Object as PropType<Credentials>,
            default: null
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
                    const withI18nMessage = validators.createI18nMessage({ t });
            const required = withI18nMessage(validators.required, {
                messagePath: i18Validators.required,
            });
        const form = reactive<Credentials>(props.modelValue || {
            email: '',
            password: ''
        })
        const fields = reactive([
            {
                component: 'MrUiKitInput',
                name: 'email',
                props: {
                    label: computed(() => t('login.form.email.label')),
                    placeholder: computed(() => t('login.form.email.placeholder')),
                    disabled: true
                },
                rules: {
                    required
                }
            },
            {
                component: 'MrUiKitInput',
                name: 'password',
                props: {
                    type: 'password',
                    label: computed(() => t('login.form.password.label')),
                    placeholder: computed(() => t('login.form.password.placeholder'))
                },
                rules: {
                    required
                }
            }
        ]);

        const onClick = () => {
            emit('click')
        }

        return {
            t,
            onClick,
            form,
            fields
        }
    },
})
