
	import {
		Federation,
		InternationalCompetition,
		InternationalCompetitionForm,
	} from '@/interfaces';
	import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
	import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { useStore } from 'vuex';
	import FileService from '@/services/FileService';
	import FederationService from '@/services/FederationService';
	import InternationalCompetitionService from '@/services/InternationalCompetitionService';
import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'CreateInternationalCompetitionModal',
		emits: ['submit', 'close'],
		components: {
			MrUiKitModal,
			MrUiKitButton,
			MrUiKitForm,
		},
		props: {
			value: {
				type: Object as PropType<InternationalCompetition>,
				default: null,
			},
		},
		setup(props, { emit }) {
			const { t, locale } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});
			const store = useStore();
			const isMounted = ref(false);
			const federations = reactive<Federation[]>([]);
			const model = ref<InternationalCompetitionForm>({
				id: -1,
				photo: {},
				location: '',
				startedAt: new Date(),
				endedAt: new Date(),
				name: {
					it: '',
					en: '',
				},
				federations: [],
				haveMedals: false
			});
			const fields = reactive([
				{
					component: 'MrUiKitFileUpload',
					name: 'photo',
					props: {
						label: 'File',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'name',
					props: {
						label: computed(() => t('common.fields.name')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'location',
					props: {
						label: computed(() => t('common.fields.location')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitDatePicker',
					name: 'startedAt',
					props: {
						label: computed(() => t('common.fields.startedAt')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitDatePicker',
					name: 'endedAt',
					props: {
						label: computed(() => t('common.fields.endedAt')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitSelect',
					name: 'federations',
					props: {
						label: computed(() => t('common.tabs.federations')),
						labelPosition: 'left',
						items: federations,
						multiple: true,
						infiniteScroll: true,
						labelKey: 'fullname',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'haveMedals',
					props: {
						label: computed(() => t('common.fields.haveMedals')),
						labelPosition: 'left'
					}
				},
			]);
			const totalCountFed = ref(0);
			const offsetFed = ref(0);

			onMounted(async () => {
				await loadFederations(true);
				if (props.value != null) {
					model.value = await InternationalCompetitionService.getMultiLang(
						props.value.id,
						locale.value,
					);
				}
				isMounted.value = true;
			});

			const onSubmit = () => {
				emit('submit', model.value);
			};

			const onClose = () => {
				emit('close');
			};

			const onUpdateForm = async (event: any) => {
				if (model.value == null) return;
				const field: keyof InternationalCompetitionForm = event.item.name;

				switch (event.type) {
					case 'upload':
						const toUpload = model.value[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(
								model.value[field].file,
							);
							model.value[field].url = uploadedFile.url;
							model.value[field].file = uploadedFile;
						}
						break;
					case 'load':
						await loadFederations();
					default:
						return;
				}
			};

			const loadFederations = async (init?: boolean) => {
				if (init) {
					const total = await FederationService.count(locale.value);
					totalCountFed.value = total.count;
				}
				const loaded = await FederationService.get(locale.value, {
					limit: 1000,
					offset: offsetFed.value,
				});

				federations.push(...loaded);
			};

			return {
				t,
				isMounted,
				model,
				onClose,
				onSubmit,
				onUpdateForm,
				fields,
			};
		},
	});
