
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import { MrUiKitButton, MrUiKitSelect, MrUiKitInput, MrUiKitMultiLanguage, MrUiKitMultiUpload, MrUiKitAddField } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';
import { IntermiadiateForm, OtherResultForm, RouteEntity } from '@/interfaces';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import ResultBlockForm from '@/components/Backoffice/TeamResult/ResultBlockForm.vue';
import OtherResultService from '@/services/OtherResultService';
import { currentI18nLanguage } from '@/plugins/i18n';
import FileService from '@/services/FileService';
import * as validators from '@vuelidate/validators';
import i18nValidators from '@/utils/i18n-validators';
import customValidators from '@/utils/custom-validator';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
    name: 'SingleForm',
    emits: ['submit', 'back'],
    components: {
        MrUiKitButton,
        MrUiKitMultiLanguage,
        MrUiKitSelect,
        MrUiKitInput,
        MrUiKitMultiUpload,
        MrUiKitAddField,
        ResultBlockForm
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Organization
        },
        value: {
            type: Number,
            default: null
        },
        hideBack: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const route = useRoute();
        const isMounted = ref(false);
        const store = useStore();
        const withI18nMessage = validators.createI18nMessage({ t });
        const required = withI18nMessage(validators.required, {
            messagePath: i18nValidators.required
        });
        const multiRequired = withI18nMessage(customValidators.multiRequired, {
            messagePath: i18nValidators.multiRequired
        })
        const generalIntermiadiate = ref<IntermiadiateForm>({
            competitionResultType: null,
            isLeft: true,
            leftResult: '0',
            rightResult: null,
            opponent: null,
            notes: {
                it: '',
                en: ''
            }
        });
        const model = ref<OtherResultForm>({
            competition: {
                it: '',
                en: ''
            },
            sport: null,
            discipline: null,
            position: null,
            totPartecipant: null,
            intermiadiates: [],
            attachments: [],
            entity: props.entity,
            entityId: -1,
            generalNotes: {
                it: '',
                en: ''
            }
        });
        const hasPosition = withI18nMessage(() => customValidators.hasPosition(model.value.position, generalIntermiadiate.value.competitionResultType), {
            messagePath: i18nValidators.hasPosition
        })
        const intermiadiateTemplate = ref<IntermiadiateForm>({
            competitionResultType: null,
            isLeft: true,
            leftResult: '0',
            rightResult: null,
            opponent: null,
            notes: {
                it: '',
                en: ''
            }
        });

        const rulesConfig = reactive<any>({
            competition: {
                multiRequired
            },
            sport: {
                required
            },
            discipline: {
                required
            },
            position: {
                hasPosition
            },
            competitionResultType: {
                hasPosition
            }
        });
        const fullModel = computed(() => {
            return {...model.value, ...generalIntermiadiate.value}
        })
        const v$ = useVuelidate(rulesConfig, fullModel);

        onMounted(async () => {
            if (props.value != null) {
                // Retrieve multilang
                await retrieveResult();
            }
            model.value.entityId = +route.params.id;
            model.value.entity = props.entity;
            isMounted.value = true;
        });

        const retrieveResult = async () => {
            const multiLang = await OtherResultService.getMultiLanguageById(props.value, locale.value);
            model.value = multiLang.model;
            generalIntermiadiate.value = multiLang.generalIntermiadiate;
        }

        const onSubmit = async () => {
            try {
                await OtherResultService.createOrUpdate({
                    ...model.value,
                    ...generalIntermiadiate.value
                }, props.value != null);
                emit('back');
                store.commit('setAlert', {
                    type: 'success',
                    message: props.value != null ? t('messages.generalSuccess.update') : t('messages.generalSuccess.create')
                })
            } catch (err) {
                store.commit('setAlert', {
                    type: 'danger',
                    message: props.value != null ? t('messages.generalError.update') : t('messages.generalError.create')
                })
            }
        }

        const onBack = () => {
            emit('back')
        }

        const onUpload = async ({index, file}: any) => {
            const uploaded = await FileService.upload(file);
            model.value.attachments[index] = {
                ...model.value.attachments[index],
                id: uploaded.id,
                uploaded: true
            }
        }

        const disciplines = computed(() => {
            return model.value.sport ? model.value.sport.disciplines : [];
        })

        const onUpdateFormItem = (item: string) => {
            v$.value[item].$touch();
        }

        return {
            t,
            onBack,
            onSubmit,
            onUpload,
            onUpdateFormItem,
            model,
            v$,
            languages: store.getters.supportedLanguages.map((l: any) => {
                return {
                    value: l.id.toLowerCase(),
                    label: l.id.toUpperCase()
                }
            }),
            currentLanguage: currentI18nLanguage,
            intermiadiateTemplate,
            disciplines,
            generalIntermiadiate,
            sports: store.getters.sports,
            isMounted
        }
    },
})
