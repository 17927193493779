
	import {
		BreadcrumbInstance,
		BreadcrumbStructureItem,
		Organization,
		PaginationModel,
		RouteEntity,
		Trainer,
	} from '@/interfaces';
	import {
    computed,
		defineComponent,
		inject,
		onMounted,
		PropType,
		reactive,
		ref,
		watch,
	} from 'vue';
	import {
		MrUiKitPagination,
		MrUiKitCard,
		MrUiKitSelect,
		MrUiKitList,
	} from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import FederationService from '@/services/FederationService';
	import { useRoute, useRouter } from 'vue-router';
	import OrganizationService from '@/services/OrganizationService';
	import AthleteService from '@/services/AthleteService';
	import { localizePath } from '@/plugins/i18n';
	import { useStore } from 'vuex';

	export default defineComponent({
		name: 'TrainerList',
		components: {
			MrUiKitPagination,
			MrUiKitCard,
			MrUiKitSelect,
			MrUiKitList,
		},
		props: {
			count: {
				type: Number,
				default: null,
			},
			entity: {
				type: String as PropType<RouteEntity>,
				default: RouteEntity.Federation,
			},
		},
		setup(props) {
			const { t, locale } = useI18n();
			const store = useStore();
			const entityId = ref<number>(-1);
			const router = useRouter();
			const route = useRoute();
			const isLoading = ref<boolean>(false);
			const trainers = ref<Trainer[]>([]);
			const selectedOrganization = ref<Organization | null>(null);
			const organizationsItems = ref<Organization[]>([]);
			const countOrganization = ref(0);
			const offset = ref(0);
			const federationId = ref(-1);
			const pagination = reactive<PaginationModel>({
				current: 1,
				count: 0,
				limit: 9,
			});

			onMounted(async () => {
				entityId.value = +route.params.id;
				if (props.count == null) {
					switch (props.entity) {
						case RouteEntity.Federation:
							const fedCount = await FederationService.countSubList(
								entityId.value,
								RouteEntity.Trainer,
							);
							pagination.count = fedCount.count;
							break;
						case RouteEntity.Organization:
							const orgCount = await OrganizationService.countSubList(
								entityId.value,
								RouteEntity.Trainer,
							);
							pagination.count = orgCount.count;
							break;
						case RouteEntity.Athlete:
							const athleteCount = await AthleteService.countSubList(
								entityId.value,
								RouteEntity.Trainer,
							);
							pagination.count = athleteCount.count;
							break;
					}
				} else {
					pagination.count = props.count;
				}

				federationId.value = await FederationService.getIdByEntity(
					entityId.value,
					props.entity,
				);
				const total = await FederationService.countSubList(
					federationId.value,
					RouteEntity.Organization,
					locale.value,
				);
				countOrganization.value = total.count;
				await loadMore(0);
			});

			const loadMore = async (offset: number, organizationId?: number) => {
				if (!isLoading.value) {
					isLoading.value = true;
					switch (props.entity) {
						case RouteEntity.Federation:
							trainers.value = await FederationService.getTrainers(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
						case RouteEntity.Organization:
							trainers.value = await OrganizationService.getTrainers(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
						case RouteEntity.Athlete:
							trainers.value = await AthleteService.getTrainers(
								entityId.value,
								locale.value,
								{
									limit: 9,
									offset,
									organization: organizationId != null ? organizationId : null,
								},
							);
							break;
					}
					isLoading.value = false;
				}
			};

			const onLoadOrganizations = async () => {
				const loaded = await FederationService.getOrganizations(
					federationId.value,
					locale.value,
					{
						limit: 10,
						offset: offset.value,
					},
				);
				organizationsItems.value.push(...loaded);
				offset.value += 10;
			};

			watch(selectedOrganization, (newV) => {
				console.log(newV);
				if (newV != null) {
					loadMore(0, newV.id);
				} else {
					loadMore(0);
				}
			});

			const goToOrganization = (organization: Organization) => {
				router.push(
					localizePath({
						path: '/organizations/' + organization.id,
					}),
				);

				store.commit('pushBreadcrumb', {
					label: organization.fullname,
					path: '/organizations/' + organization.id,
					type: BreadcrumbStructureItem.Organization,
				});
			};

			const onClick = (trainer: Trainer) => {
				router.push(localizePath({ path: '/trainers/' + trainer.id }));

				store.commit('pushBreadcrumb', {
					label:
						trainer.firstname +
						(trainer.middlename ? ' ' + trainer.middlename : '') +
						' ' +
						trainer.lastname,
					path: '/trainers/' + trainer.id,
					type: BreadcrumbStructureItem.Trainer,
				});
			};

			const filterLabel = computed(() => {
				return t('common.operations.filter');
			})

			return {
				t,
				pagination,
				isLoading,
				trainers,
				filterLabel,
				goToOrganization,
				loadMore,
				onClick,
				onLoadOrganizations,
				selectedOrganization,
				organizationsItems,
				countOrganization,
			};
		},
	});
