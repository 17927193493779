
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router';
import { MrUiKitCard, MrUiKitButton, MrUiKitTable, MrUiKitPagination } from 'mr-ui-kit';
import { PaginationModel, RouteEntity, Tag, TagForm } from '@/interfaces';
import OrganizationService from '@/services/OrganizationService';
import TagService from '@/services/TagService';
import { useStore } from 'vuex';
import axios from 'axios';

import CreateTagModal from '@/components/Backoffice/Modals/CreateTagModal.vue';
import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';

export default defineComponent({
    name: 'TagList',
    components: {
        MrUiKitCard,
        MrUiKitButton,
        MrUiKitTable,
        MrUiKitPagination,
        CreateTagModal,
        DeleteEntityModal
    },
    setup() {
        const { t, locale } = useI18n();
        const store = useStore();
        const route = useRoute();
        const entityId = ref<number>(-1);
        const showModal = ref<boolean>(false);
        const showDeleteModal = ref(false);
        const selectedTag = ref<Tag | null>(null);
        const isLoading = ref<boolean>(false);
        const newTag = ref<TagForm>({
            athletes: [],
            name: {
                it: '',
                en: ''
            }
        });
        const tags = ref<Tag[]>([]);
        const columns = reactive([
            {
                label: computed(() => t('common.fields.name')),
                field: 'name'
            },
            {
                label: '',
                field: 'action'
            }
        ]);
        const pagination = reactive<PaginationModel>({
            current: 1,
            count: 0,
            limit: 10
        });

        onMounted(async () => {
            entityId.value = +route.params.id;
            await onInit();
        })

        const onInit = async () => {
            const total = await OrganizationService.countSubList(entityId.value, RouteEntity.Tag);
            pagination.count = total.count;
            await loadMore(0);
            pagination.current = 1;
        }

        const loadMore = async (offset: number) => {
            if (!isLoading.value) {
                isLoading.value = true;
                tags.value = await OrganizationService.getTags(entityId.value, locale.value, {
                    limit: 10,
                    offset
                });
                isLoading.value = false;
            }
        }

        const onOpenAdd = async (item?: Tag) => {
            if (item) {
                // Make api call and retrieve multilang
                const multiLang = await TagService.getMultiLanguageById(entityId.value, item.id);
                newTag.value = multiLang;
            } else {
                newTag.value = {
                    athletes: [],
                    name: {
                        it: '',
                        en: ''
                    }
                }
            }
            showModal.value = true;
        }

        const onSubmit = async (toProcess: any) => {
            try {
                await TagService.createOrUpdate(entityId.value, toProcess);
                showModal.value = false;
                await loadMore(0);
                pagination.current = 1;
                store.commit('setAlert', {
                    type: 'success',
                    message: newTag.value.id == null ? t('messages.generalSuccess.create') : t('messages.generalSuccess.update')
                })
            } catch (err) {
                store.commit('setAlert', {
                    type: 'danger',
                    message: newTag.value.id == null ? t('messages.generalError.create') : t('messages.generalError.update')
                })
            }
        }

        const onDelete = (item: Tag) => {
            selectedTag.value = item;
            showDeleteModal.value = true;
        }

        const onSubmitDelete = async () => {
            if (selectedTag.value) {
                try {    
                    await axios.delete('/organizations/' + entityId.value + '/tags/' + selectedTag.value.id);
                    await onInit();
                    store.commit('setAlert', {
                        type: 'success',
                        message: t('messages.generalSuccess.create')
                    })
                } catch (err) {
                    store.commit('setAlert', {
                        type: 'danger',
                        message: t('messages.generalError.create')
                    })
                }
            }
            showDeleteModal.value = false;
        }

        return {
            t,
            loadMore,
            onDelete,
            onSubmit,
            onOpenAdd,
            onSubmitDelete,
            columns,
            pagination,
            tags,
            showModal,
            entityId,
            newTag,
            showDeleteModal,
            selectedTag
        }
    },
})
