
	import { computed, defineComponent, onMounted, ref } from 'vue';
	import LoginForm from '@/components/Frontend/Login/LoginForm.vue';
	import AuthService from '@/services/AuthService';
	import { useStore } from 'vuex';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from 'vue-router';

	import FirstResetPasswordForm from '@/components/Frontend/Login/FirstResetPasswordForm.vue';
	import { localizePath } from '@/plugins/i18n';
	import SocialLanguage from '@/components/UI/SocialLanguage.vue';

	export default defineComponent({
		name: 'LoginView',
		components: {
			LoginForm,
			FirstResetPasswordForm,
			SocialLanguage,
		},
		setup() {
			const { t } = useI18n();
			const isMounted = ref(false);
			const route = useRoute();
			const router = useRouter();
			const store = useStore();
			const verifyToken = ref<string | null>(null);
			const loginForm = ref({
				email: '',
				password: '',
			});

			const isLogin = computed(() => {
				return route.query.token == null;
			});

			onMounted(async () => {
				if (!isLogin.value) {
					await onVerify();
				}
				isMounted.value = true;
			});

			const onVerify = async () => {
				try {
					const data = await AuthService.verifyEmail(
						route.query.token as string,
					);
					loginForm.value.email = data.email;
					verifyToken.value = data.token;
				} catch (err) {
					router.push(localizePath({ path: '/login' }));
				}
			};

			const onClick = async () => {
				try {
					await AuthService.login(loginForm.value);
				} catch (err) {
					switch (err.status) {
						case 400:
							store.commit('setAlert', {
								message: t('messages.login.passwordNotMatch'),
								type: 'danger',
							});
							break;
						case 404:
							store.commit('setAlert', {
								message: t('messages.login.userNotFound'),
								type: 'danger',
							});
							break;
					}
				}
			};

			const onFirstReset = async () => {
				try {
					await AuthService.firstReset({
						password: loginForm.value.password,
						token: verifyToken.value as string,
					});
				} catch (err) {
					store.commit('setAlert', {
						message: t('messages.generalError.create'),
						type: 'danger',
					});
				}
			};

			return {
				t,
				loginForm,
				verifyToken,
				onClick,
				onFirstReset,
				isLogin,
				isMounted,
				logoLogin: require('@/assets/img/logo_login.png'),
				logoLoginDark: require('@/assets/img/logo_login_dark.png'),
			};
		},
	});
