import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-entity-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitTag = _resolveComponent("MrUiKitTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MrUiKitTag, {
      modelValue: _ctx.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      style: {"width":"100%"},
      class: "mb-4",
      items: _ctx.entities,
      onSearch: _ctx.loadEntities,
      multiple: false,
      placeholder: _ctx.t('common.findDots'),
      labelKey: "name"
    }, null, 8, ["modelValue", "items", "onSearch", "placeholder"])
  ]))
}