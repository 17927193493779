
	import { computed, defineComponent, PropType } from 'vue';
	import { MrUiKitCard } from 'mr-ui-kit';
	import { Athlete, BioItem, SocialItem } from '@/interfaces';

	export default defineComponent({
		name: 'Biography',
		components: {
			MrUiKitCard,
		},
		props: {
			title: {
				type: String,
				default: '',
			},
			content: {
				type: String,
				default: '',
			},
			items: {
				type: Array as PropType<BioItem[]>,
				default: [],
			},
			social: {
				type: Object as PropType<SocialItem>,
				default: {},
			}
		},
		setup(props) {
			const socials = computed(() => {
				const out: any[] = [];
				for (const key of Object.keys(props.social)) {
					const value: string | undefined =
						props.social[key as keyof SocialItem];
					if (value) {
						out.push({
							icon: key,
							value,
						});
					}
				}

				return out;
			});

			const checkTarget = (item: BioItem) => {
				switch (item.title) {
					case 'Sito web':
					case 'Website':
						window.open(validateURL(item.value!), '_blank');
						break;
					case 'Email':
						document.location.href = 'mailto:' + item.value!;
						break;
					case 'Telefono':
					case 'Telephone':
						document.location.href = 'tel:' + item.value!;
				}
			};

			const validateURL = (link: string) => {
				if (link.indexOf('http://') == 0 || link.indexOf('https://') == 0) {
					return link;
				} else {
					return 'https://' + link;
				}
			};
			const hideBiography = computed(() => {
				const notNullItems = props.items.filter((i) => i.value != null);
				const notNullSocials = Object.keys(socials.value).filter(
					(key) => socials.value[key as any] != null,
				);
				return notNullItems.length === 0 && notNullSocials.length === 0;
			});

			const isVisible = (item: any) => {
				return (
					(item.value != null || item.slot != null) &&
					(item.visible == null || item.visible === true) &&
					(item.value !== "")
				)
			}

			const isBlockVisible = computed(() => {
				const withValue = props.items.filter(i => i.value != null || i.slot != null);
				const haveSocial = props.social.facebook != null || props.social.instagram != null;
				return withValue.length > 0 || haveSocial;
			})

			return {
				socials,
				checkTarget,
				isVisible,
				hideBiography,
				isBlockVisible,
			};
		},
	});
