import { GeneralFile } from "@/interfaces";
import axios from "axios";

class FileService {
    constructor() {}

    async upload(file: File): Promise<GeneralFile> {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post<GeneralFile>('/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }
}

export default new FileService();