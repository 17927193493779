import { Count, CreateOrUpdateDiscipline, Discipline, DisciplineForm, Filter, FilterBySport } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";

class DisciplineService {
    constructor() {}

    async count(lang: string, search: string | null, sportId: number | null): Promise<Count> {
        const response = await axios.get<Count>('/disciplines/count?lang=' + lang + (search ? '&search=' + search : '' + (sportId ? '&sportId=' + sportId : '')));
        return response.data;
    }

    async get(lang: string, filter: FilterBySport): Promise<Discipline[]> {
        const parsed = FilterService.parseFilter(filter);
        const { data } = await axios.get<Discipline[]>('/disciplines?lang=' + lang + '&' + parsed);
        return data;
    }

    async getMultiLang(id: number, lang: string): Promise<DisciplineForm> {
        const { data } = await axios.get<DisciplineForm>('/disciplines/' + id + '/multilang?lang=' + lang);
        return data;
    }

    async createOrUpdate(form: DisciplineForm): Promise<void> {
        const isUpdate = form.id !== -1;
        const body: CreateOrUpdateDiscipline = {
            sportId: form.sport ? form.sport.id : -1,
            name: form.name
        }

        if (!isUpdate) {
            await axios.post('/disciplines', body);
        } else {
            await axios.patch('/disciplines/' + form.id, body);
        }
    }
}

export default new DisciplineService();