import { Count, CountFilter, Filter } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";

class VisitorService {
    async count(filter?: CountFilter): Promise<Count> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Count>('/admin/visitors/count?' + parsedFilter);
        return response.data;
    }

    async get(filter: Filter): Promise<any[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<any[]>('/admin/visitors?' + parsedFilter);
        return response.data;
    }

    async create(body: {email: string}) {
        await axios.post('/admin/visitors', body);
    }
}

export default new VisitorService();