import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "athlete-list" }
const _hoisted_2 = {
  class: "row justify-content-between d-flex align-item-center pb-2",
  style: {"align-items":"center","min-height":"56px"}
}
const _hoisted_3 = { class: "col-md-4 col-12" }
const _hoisted_4 = { class: "text-capitalize mb-1" }
const _hoisted_5 = { class: "col-md-4 col-12 mt-sm-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "avatar avatar-xl avatar-circle mb-4 text-center d-flex align-items-center mx-auto" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "avatar-img",
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "card-subtitle text-capitalize pt-4 d-flex"
}
const _hoisted_12 = { class: "me-3" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "card-subtitle text-capitalize pt-3 d-flex"
}
const _hoisted_17 = { class: "me-3" }
const _hoisted_18 = ["src"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 1,
  class: "container justify-content-center text-center p-7 ps-0 pe-0",
  style: {"opacity":"0.7"}
}
const _hoisted_22 = {
  key: 2,
  class: "pt-4 pb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitSelect = _resolveComponent("MrUiKitSelect")!
  const _component_MrUiKitCard = _resolveComponent("MrUiKitCard")!
  const _component_MrUiKitList = _resolveComponent("MrUiKitList")!
  const _component_MrUiKitPagination = _resolveComponent("MrUiKitPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t('common.athletes')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MrUiKitSelect, {
          modelValue: _ctx.selectedOrganization,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOrganization) = $event)),
          labelKey: "fullname",
          items: _ctx.organizationsItems,
          multiple: false,
          onLoad: _ctx.onLoadOrganizations,
          infiniteScroll: true,
          count: _ctx.countOrganization,
          label: _ctx.filterLabel,
          placeholder: "",
          labelPosition: 'left'
        }, null, 8, ["modelValue", "items", "onLoad", "count", "label"])
      ])
    ]),
    (_ctx.athletes.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_MrUiKitList, {
            classes: ['mb-4'],
            columns: 4,
            items: _ctx.athletes
          }, {
            default: _withCtx(({ item }) => [
              _createVNode(_component_MrUiKitCard, {
                classes: ['h-100'],
                footerClasses: ['pt-0']
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    (item.photo)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "avatar-img",
                          src: item.photo.url
                        }, null, 8, _hoisted_8))
                      : (_openBlock(), _createElementBlock("img", _hoisted_9))
                  ]),
                  _createElementVNode("h4", {
                    class: "card-title text-center link-navigate",
                    onClick: () => _ctx.onClick(item)
                  }, _toDisplayString(item.firstname) + " " + _toDisplayString(item.middlename) + " " + _toDisplayString(item.lastname), 9, _hoisted_10),
                  (item.organizations.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("img", {
                            style: {"max-height":"16px"},
                            src: require('@/assets/img/homepage/ico_societa.png')
                          }, null, 8, _hoisted_13)
                        ]),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.organizations, (org, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                              onClick: _withModifiers(($event: any) => (_ctx.goTo('organizations', org)), ["stop"]),
                              key: 'athlete-org-' + org.id,
                              class: "link-navigate"
                            }, [
                              _createTextVNode(_toDisplayString(org.fullname) + " ", 1),
                              (index < item.organizations.length - 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, ", "))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_14))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (item.trainers.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("img", {
                            style: {"max-height":"16px","margin-bottom":"0.2rem !important"},
                            src: require('@/assets/img/homepage/ico_allenatore.png')
                          }, null, 8, _hoisted_18)
                        ]),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.trainers, (trainer, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                              onClick: _withModifiers(($event: any) => (_ctx.goTo('trainers', trainer)), ["stop"]),
                              key: 'athlete-org-' + trainer.id,
                              class: "link-navigate"
                            }, [
                              _createTextVNode(_toDisplayString(trainer.firstname) + " " + _toDisplayString(trainer.middlename) + " " + _toDisplayString(trainer.lastname) + " ", 1),
                              (index < item.trainers.length - 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_20, ", "))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_19))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["items"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.t("common.noAthletes")), 1)),
    (_ctx.athletes.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(_component_MrUiKitPagination, {
            modelValue: _ctx.pagination,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
            onLoad: _ctx.loadMore
          }, {
            default: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.t('pagination.page')) + " " + _toDisplayString(_ctx.pagination.current) + " " + _toDisplayString(_ctx.t('pagination.of')) + " " + _toDisplayString(slotProps.lastPage), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "onLoad"])
        ]))
      : _createCommentVNode("", true)
  ]))
}