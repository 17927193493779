
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  Competition,
  RouteEntity,
} from "@/interfaces";
import CompetitionService from "@/services/CompetitionService";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import ResultMedal from "@/components/Frontend/TeamResult/ResultMedal.vue";
import TeamResult from "@/components/Frontend/TeamResult/TeamResult.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "CompetitionView",
  components: {
    TeamResult,
    ResultMedal,
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();
    const isMounted = ref(false);
    const currentCompetition = ref<Competition | null>(null);

    onMounted(async () => {
      const id = +route.params.id;
      currentCompetition.value = await CompetitionService.getById(
        id,
        locale.value
      );

      store.commit("pushBreadcrumb", {
        label: currentCompetition.value.name,
        path: "/competitions/" + id,
        type: BreadcrumbStructureItem.Federation,
      });

      isMounted.value = true;
    });

    return {
      t,
      currentCompetition,
      isMounted,
      competitionEntity: RouteEntity.Competition,
    };
  },
});
