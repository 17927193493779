
	import { Filter, RouteEntity, Staff } from '@/interfaces';
	import { defineComponent, onMounted, PropType, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { MrUiKitCard, MrUiKiList } from 'mr-ui-kit';
	import InfiniteScroll from '@/components/UI/InfiniteScroll.vue';
	import { useRoute } from 'vue-router';
	import FederationService from '@/services/FederationService';
	import OrganizationService from '@/services/OrganizationService';
	import AthleteService from '@/services/AthleteService';

	export default defineComponent({
		name: 'StaffList',
		emits: ['load'],
		components: {
			MrUiKitCard,
			MrUiKiList,
			InfiniteScroll,
		},
		props: {
			entity: {
				type: String as PropType<RouteEntity>,
				default: RouteEntity.Federation,
			},
		},
		setup(props) {
			const { t, locale } = useI18n();
			const route = useRoute();
			const entityId = ref<number>(-1);
			const staffs = ref<Staff[]>([]);
			const isLoading = ref<boolean>(false);
			const guard = ref(false);
			const filter = ref<Filter>({
				limit: 12,
				offset: 0,
			});

			onMounted(() => {
				entityId.value = +route.params.id;
				loadMore();
			});

			const loadMore = async () => {
				if (!isLoading.value) {
					isLoading.value = true;
					let loaded: Staff[] = [];
					switch (props.entity) {
						case RouteEntity.Federation:
							loaded = await FederationService.getStaff(
								entityId.value,
								locale.value,
								filter.value,
							);
							break;
						case RouteEntity.Organization:
							loaded = await OrganizationService.getStaff(
								entityId.value,
								locale.value,
								filter.value,
							);
							break;
						case RouteEntity.Athlete:
							loaded = await AthleteService.getStaff(
								entityId.value,
								locale.value,
								filter.value,
							);
							break;
					}
					staffs.value.push(...loaded);
					isLoading.value = false;

					if (loaded.length === 0) {
						guard.value = true;
					}

					filter.value.offset += 12;
				}
			};

			const sendEmail = (mail: string) => {
				document.location.href = 'mailto:' + mail.toLowerCase();
			}

			return {
				t,
				loadMore,
				sendEmail,
				guard,
				staffs,
				isLoading,
			};
		},
	});
