
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  Organization,
  PaginationModel,
  RouteEntity,
  Trainer,
  TrainerForm,
} from "@/interfaces";
import AthleteService from "@/services/AthleteService";
import FederationService from "@/services/FederationService";
import OrganizationService from "@/services/OrganizationService";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import {
  MrUiKitCard,
  MrUiKitButton,
  MrUiKitModal,
  MrUiKitTable,
  MrUiKitPagination,
} from "mr-ui-kit";
import TrainerService from "@/services/TrainerService";
import { useStore } from "vuex";
import axios from "axios";

import CreateTrainerModal from "@/components/Backoffice/Modals/CreateTrainerModal.vue";
import ManageAccountModal from "@/components/Backoffice/Modals/ManageAccountModal.vue";
import DeleteEntityModal from "@/components/Backoffice/Modals/DeleteEntityModal.vue";
import { localizePath } from "@/plugins/i18n";

export default defineComponent({
  name: "TrainerList",
  components: {
    MrUiKitCard,
    MrUiKitButton,
    MrUiKitModal,
    MrUiKitTable,
    MrUiKitPagination,
    CreateTrainerModal,
    ManageAccountModal,
    DeleteEntityModal,
  },
  props: {
    entity: {
      type: String as PropType<RouteEntity>,
      default: RouteEntity.Federation,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const showDeleteModal = ref(false);
    const showAccountModal = ref(false);
    const selectedTrainer = ref<Trainer | null>(null);
    const showAddModal = ref<boolean>(false);
    const entityId = ref<number>(-1);
    const isLoading = ref<boolean>(false);
    const currentSearch = ref<string | null>(null);
    const newTrainer = ref<TrainerForm>({
      id: -1,
      userCredentials: [],
      organizations: [],
      photo: {},
      firstname: "",
      lastname: "",
    });
    const trainers = ref<Trainer[]>([]);
    const columns = reactive([
      {
        label: computed(() => t("common.fields.name")),
        field: "fullname",
      },
      {
        label: computed(() => t("common.organizations")),
        field: "organizations",
      },
      {
        label: "",
        field: "action",
      },
    ]);
    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: 10,
    });

    onMounted(async () => {
      entityId.value = +route.params.id;
      onInit();
    });

    const onInit = async () => {
      pagination.current = 1;
      switch (props.entity) {
        case RouteEntity.Federation:
          const fedCount = await FederationService.countSubList(
            entityId.value,
            RouteEntity.Trainer
          );
          pagination.count = fedCount.count;
          break;
        case RouteEntity.Organization:
          const orgCount = await OrganizationService.countSubList(
            entityId.value,
            RouteEntity.Trainer
          );
          pagination.count = orgCount.count;
          break;
        case RouteEntity.Athlete:
          const athleteCount = await AthleteService.countSubList(
            entityId.value,
            RouteEntity.Trainer
          );
          pagination.count = athleteCount.count;
          break;
      }
      await loadMore(0);
    };

    const loadMore = async (offset: number) => {
      if (!isLoading.value) {
        isLoading.value = true;
        switch (props.entity) {
          case RouteEntity.Federation:
            trainers.value = await FederationService.getTrainers(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
          case RouteEntity.Organization:
            trainers.value = await OrganizationService.getTrainers(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
          case RouteEntity.Athlete:
            trainers.value = await AthleteService.getTrainers(
              entityId.value,
              locale.value,
              {
                limit: 10,
                offset,
                search:
                  currentSearch.value != null ? currentSearch.value : null,
              }
            );
            break;
        }
        isLoading.value = false;
      }
    };

    const onOpenAccount = (item: Trainer) => {
      selectedTrainer.value = item;
      showAccountModal.value = true;
    };

    const onNavigate = (item: Trainer) => {
      router.push(
        localizePath({
          path: "/trainers/" + item.id + "/edit",
        })
      );
    };

    const onNavigateFrontOffice = (item: Trainer) => {
      router.push(
        localizePath({
          path: "/trainers/" + item.id,
        })
      );
    };

    const onSearch = (search: string) => {
      if (search !== "") {
        currentSearch.value = search;
      } else {
        currentSearch.value = null;
      }
      onInit();
    };

    const onDelete = (item: Trainer) => {
      selectedTrainer.value = item;
      showDeleteModal.value = true;
    };

    const onSubmitDelete = async () => {
      if (selectedTrainer.value) {
        try {
          await axios.delete("/trainers/" + selectedTrainer.value.id);
          await onInit();
          store.commit("setAlert", {
            type: "success",
            message: t("messages.generalSuccess.create"),
          });
        } catch (err) {
          store.commit("setAlert", {
            type: "danger",
            message: t("messages.generalError.create"),
          });
        }
      }
      showDeleteModal.value = false;
    };

    const organizations = (trainer: Trainer) => {
      const extracted = trainer.organizations.splice(0, 3);
      trainer.organizations.push(...extracted);
      return extracted;
    };

    const goToOrganization = (organization: Organization) => {
      router.push(
        localizePath({
          path: "/organizations/" + organization.id + "/edit",
        })
      );

      store.commit("pushBreadcrumb", {
        label: organization.fullname,
        path: "/organizations/" + organization.id + "/edit",
        type: BreadcrumbStructureItem.Organization,
      });
    };

    const onOpenAdd = () => {
      showAddModal.value = true;
      newTrainer.value = {
        id: -1,
        userCredentials: [],
        organizations: [],
        photo: {},
        firstname: "",
        lastname: "",
      };
    };

    const onCreate = async (form: TrainerForm) => {
      try {
        await TrainerService.create(form);
        await onInit();
        showAddModal.value = false;
        store.commit("setAlert", {
          type: "success",
          message: t("messages.generalSuccess.create"),
        });
      } catch (err) {
        store.commit("setAlert", {
          type: "danger",
          message: t("messages.generalError.create"),
        });
      }
    };

    const searchLabel = computed(() => {
      return t('common.findDots');
    })

    return {
      t,
      onSearch,
      loadMore,
      onNavigate,
      onDelete,
      organizations,
      onCreate,
      onOpenAdd,
      onSubmitDelete,
      newTrainer,
      showAddModal,
      showDeleteModal,
      searchLabel,
      pagination,
      columns,
      trainers,
      entityId,
      onOpenAccount,
      goToOrganization,
      onNavigateFrontOffice,
      selectedTrainer,
      showAccountModal,
      trainerEntity: RouteEntity.Trainer,
    };
  },
});
