
import { FormattedAccount, RouteEntity } from '@/interfaces'
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import { MrUiKitCard, MrUiKitAddField, MrUiKitInput, MrUiKitButton } from 'mr-ui-kit';
import { useRoute } from 'vue-router';
import AccountService from '@/services/AccountService';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'ManageAccount',
    components: {
        MrUiKitCard,
        MrUiKitAddField,
        MrUiKitInput,
        MrUiKitButton
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Federation
        }
    },
    setup(props) {
        const { t } = useI18n();
        const store = useStore();
        const entityId = ref<number>(-1);
        const route = useRoute();
        const accounts = ref<FormattedAccount[]>([]);
        const template = reactive<FormattedAccount>({
            id: -1,
            email: ''
        });
        const isMounted = ref<boolean>(false);

        onMounted(async () => {
            entityId.value = +route.params.id;
            await loadAccounts();
            isMounted.value = true;
        })

        const loadAccounts = async () => {
            const loaded = await AccountService.findAccountsByEntityId(props.entity, entityId.value);
            accounts.value = loaded;
        }

        const onSave = async () => {
            try {
                await AccountService.saveAccountsToEntity(props.entity, entityId.value, accounts.value.map(a => {
                    return {
                        id: a.id,
                        email: a.email
                    }
                }));
                await loadAccounts();
                store.commit('setAlert', {
                    type: 'success',
                    message: t('messages.generalSuccess.save')
                })
            } catch (err) {
                store.commit('setAlert', {
                    type: 'danger',
                    message: t('messages.generalError.save')
                })
            }
        }

        return {
            t,
            onSave,
            entityId,
            isMounted,
            accounts,
            template
        }
    },
})
