import { Athlete, Count, CountFilterByFederation, CreateOrganization, Filter, FilterByFederation, FilterByOrganization, Organization, OrganizationForm, OrganizationInfoForm, OrganizationMultiLanguage, RouteEntity, Staff, Tag, Trainer, UpdateOrganization } from "@/interfaces";
import axios from "axios";
import AccountService from "./AccountService";
import FilterService from "./FilterService";

class OrganizationService {
    constructor() {}

    async count(lang: string, filter?: CountFilterByFederation): Promise<Count> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Count>('/organizations/count?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async get(lang: string, filter: FilterByFederation): Promise<Organization[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Organization[]>('/organizations' + '?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getById(id: number, lang: string): Promise<Organization> {
        const response = await axios.get<Organization>('/organizations/' + id + '?lang=' + lang);
        return response.data;
    }

    async getStaff(id: number, lang: string, filter: Filter): Promise<Staff[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Staff[]>('/organizations/' + id + '/staff?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }
    
    async countSubList(id: number, type: RouteEntity, lang?: string): Promise<Count> {
        const response = await axios.get<Count>('/organizations/' + id + '/' + type + '/count' + (lang ? '?lang=' + lang : ''));
        return response.data;
    }

    async getTrainers(id: number, lang: string, filter: FilterByOrganization): Promise<Trainer[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Trainer[]>('/organizations/' + id + '/trainers?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getAthletes(id: number, lang: string, filter: FilterByOrganization): Promise<Athlete[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Athlete[]>('/organizations/' + id + '/athletes?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }

    async getMultiLanguageById(id: number): Promise<OrganizationInfoForm> {
        const { data } = await axios.get<OrganizationMultiLanguage>('/organizations/' + id + '/multilang');
        return {
            id,
            cover: {
                file: data.cover ? data.cover : null,
                url: data.cover && data.cover.url ? data.cover.url : null
            },
            file: {
                file: data.logo ? data.logo : null,
                url: data.logo && data.logo.url ? data.logo.url : null
            },
            fullname: {
                it: data.it.fullname,
                en: data.en.fullname
            },
            bio: {
                it: data.it && data.it.bio ? data.it.bio : '',
                en: data.en && data.en.bio ? data.en.bio : '',
            },
            shortname: data.shortname || '',
            publicEmail: data.publicEmail,
            publicTelephone: data.publicTelephone,
            email: data.email,
            website: data.website,
            telephone: data.telephone,
            isNational: data.isNational,
            instagram: data.instagram,
            facebook: data.facebook,
            dateFoundation: data.dateFoundation,
            active: !data.deleted,
            userCredentials: data.userCredentials
        }
    }

    async create(federationId: number, form: OrganizationForm): Promise<void> {
        const userCredentials = await AccountService.checkAccount(form.userCredentials);

        const body: CreateOrganization = {
            id: form.id,
            userCredentials,
            logoId: form.logo && form.logo.file ? form.logo.file.id : undefined,
            isNational: form.isNational,
            federationId,
            it: {
                fullname: form.fullname.it,
            },
            en: {
                fullname: form.fullname.en
            }
        }

        await axios.post('/organizations', body);
    }

    async updateById(id: number, form: OrganizationInfoForm): Promise<OrganizationInfoForm> {
        const body: UpdateOrganization = {
            coverId: form.cover && form.cover.file ? form.cover.file.id : null,
            logoId: form.file && form.file.file ? form.file.file.id : null,
            email: form.email,
            publicEmail: form.publicEmail,
            telephone: form.telephone,
            publicTelephone: form.publicTelephone,
            website: form.website,
            facebook: form.facebook,
            instagram: form.instagram,
            dateFoundation: form.dateFoundation,
            shortname: form.shortname,
            deleted: !form.active,
            isNational: form.isNational,
            it: {
                fullname: form.fullname.it,
                bio: form.bio.it || undefined
            },
            en: {
                fullname: form.fullname.en,
                bio: form.bio.en || undefined
            }
        }

        await axios.patch('/organizations/' + id, body);
        return form;
    }

    async getTags(organizationId: number, lang: string, filter: Filter): Promise<Tag[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<Tag[]>('/organizations/' + organizationId + '/tags?lang=' + lang + '&' + parsedFilter);
        return response.data;
    }
}

export default new OrganizationService();