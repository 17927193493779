
	import { defineComponent, ref, watch } from 'vue';
	import { MrUiKitToggle } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';

	export default defineComponent({
		name: 'ShowMoreFilters',
		emits: ['reload'],
		components: {
			MrUiKitToggle,
		},
		props: {
			default: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const { t, locale } = useI18n();
			const isToggle = ref(props.default);

			watch(isToggle, (nV, oV) => {
				if (nV !== oV) {
					emit('reload', isToggle.value);
				}
			});

			return {
				t,
				isToggle,
			};
		},
	});
