import { GeneralFormatted } from "@/interfaces";
import axios from "axios";

class OpponentService {
    constructor() {}

    async create(name: string): Promise<GeneralFormatted> {
        const { data } = await axios.post<GeneralFormatted>('/opponents', {
            name
        });
        return data;
    }

    async search(text: string): Promise<GeneralFormatted[]> {
        const { data } = await axios.get<GeneralFormatted[]>('/opponents?name=' + text);
        return data;
    }
}

export default new OpponentService();