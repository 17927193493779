
	import { computed, defineComponent, PropType, reactive } from 'vue';
	import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import customValidator from '@/utils/custom-validator';
	import { CompetitionForm } from '@/interfaces';
	import { useStore } from 'vuex';
	import FileService from '@/services/FileService';
import { currentI18nLanguage } from '@/plugins/i18n';

	export default defineComponent({
		name: 'CreateCompetitionModal',
		emits: ['submit', 'close'],
		components: {
			MrUiKitModal,
			MrUiKitButton,
			MrUiKitForm,
		},
		props: {
			modelValue: {
				type: Object as PropType<CompetitionForm>,
				default: null,
			},
		},
		setup(props, { emit }) {
			const { t } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const multiRequired = withI18nMessage(customValidator.multiRequired, {
				messagePath: i18Validators.multiRequired,
			});
			const store = useStore();
			const competition = reactive<CompetitionForm>(props.modelValue);
			const fields = reactive([
				{
					component: 'MrUiKitFileUpload',
					name: 'photo',
					props: {
						label: 'File',
					},
				},
				{
					component: 'MrUiKitMultiLanguage',
					name: 'name',
					props: {
						label: computed(() => t('common.fields.name')),
						labelPosition: 'left',
						currentLanguage: currentI18nLanguage,
						languages: store.getters.supportedLanguages.map((l: any) => {
							return {
								value: l.id.toLowerCase(),
								label: l.id.toUpperCase(),
							};
						}),
					},
					rules: {
						multiRequired,
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'location',
					props: {
						label: computed(() => t('common.fields.location')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitDatePicker',
					name: 'startedAt',
					props: {
						label: computed(() => t('common.fields.startedAt')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitDatePicker',
					name: 'endedAt',
					props: {
						label: computed(() => t('common.fields.endedAt')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitToggle',
					name: 'haveMedals',
					props: {
						label: computed(() => t('common.fields.haveMedals')),
						labelPosition: 'left'
					}
				},
			]);

			const onSubmit = () => {
				emit('submit');
			};

			const onClose = () => {
				emit('close');
			};

			const onUpdateForm = async (event: any) => {
				if (competition == null) return;
				const field: keyof CompetitionForm = event.item.name;

				switch (event.type) {
					case 'upload':
						const toUpload = competition[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(
								competition[field].file,
							);
							competition[field].url = uploadedFile.url;
							competition[field].file = uploadedFile;
						}
						break;
					default:
						return;
				}
			};

			return {
				t,
				onSubmit,
				onClose,
				onUpdateForm,
				competition,
				fields,
			};
		},
	});
