import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-inline mb-0" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "list-inline-item" }
const _hoisted_4 = { class: "btn-group" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (s, i) => {
      return (_openBlock(), _createElementBlock("li", {
        key: 'footer-social-' + i,
        class: "list-inline-item"
      }, [
        _createElementVNode("a", {
          class: "btn btn-ghost-secondary btn-xs btn-icon",
          href: s.url
        }, [
          _createElementVNode("i", {
            class: _normalizeClass('bi-' + s.type)
          }, null, 2)
        ], 8, _hoisted_2)
      ]))
    }), 128)),
    _createElementVNode("li", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MrUiKitButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLanguages = !_ctx.showLanguages)),
          classes: ['btn btn-soft-secondary btn-xs dropdown-toggle'],
          ariaExpanded: _ctx.showLanguages
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.getLanguageIcon(_ctx.currentI18nLanguage.locale),
                class: "avatar avatar-xss avatar-circle me-2"
              }, null, 8, _hoisted_6),
              _createElementVNode("span", null, _toDisplayString(_ctx.currentI18nLanguage.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["ariaExpanded"]),
        _createElementVNode("div", {
          class: _normalizeClass(["dropdown-menu", _ctx.showLanguages && 'show']),
          style: {"inset":"auto auto 40px -50px","cursor":"pointer"}
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedLanguages, (s, i) => {
            return (_openBlock(), _createElementBlock("a", {
              key: 'footer-language-' + i,
              class: "dropdown-item d-flex align-items-center active",
              onClick: ($event: any) => (_ctx.switchLanguage(s.locale))
            }, [
              _createElementVNode("span", _hoisted_8, [
                _createElementVNode("img", {
                  src: _ctx.getLanguageIcon(s.locale),
                  class: "avatar avatar-xss avatar-circle me-2"
                }, null, 8, _hoisted_9),
                _createElementVNode("span", null, _toDisplayString(s.name), 1)
              ])
            ], 8, _hoisted_7))
          }), 128))
        ], 2)
      ])
    ])
  ]))
}