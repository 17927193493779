
import { RouteEntity } from '@/interfaces';
import { MrUiKitSideNav, MrUiKitTitle, MrUiKitTab } from 'mr-ui-kit';
import { computed, defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AthletePersonalData from '@/components/Backoffice/Athlete/AthletePersonalData.vue';
import ResultList from '@/components/Backoffice/Common/ResultList.vue';
import ManageAccount from '@/components/Backoffice/Common/ManageAccount.vue';

export default defineComponent({
    name: 'AthleteBackoffice',
    components: {
        MrUiKitSideNav,
        MrUiKitTitle,
        MrUiKitTab,
        ResultList,
        ManageAccount,
        AthletePersonalData
    },
    setup() {
        const { t } = useI18n();
        const collapse = ref(false);
        const sideNavLabel = reactive({
            detailsTitle: computed(() => t('backoffice.sideNavTabs.details.information')),
            contentResultTitle: computed(() => t('backoffice.sideNavTabs.contents.results')),
            accountTitle: computed(() => t('backoffice.sideNavTabs.management.account')),
        });
        
        return {
            t,
            athleteEntity: RouteEntity.Athlete,
            sideNavLabel,
            collapse
        }
    },
})
