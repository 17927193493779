
import { defineComponent, reactive, computed, ref } from 'vue'
import { MrUiKitSideNav, MrUiKitTitle, MrUiKitTab } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';
import { RouteEntity } from '@/interfaces';

import TrainerPersonalData from '@/components/Backoffice/Trainer/TrainerPersonalData.vue';
import AthleteList from '@/components/Backoffice/Common/AthleteList.vue';
import ResultList from '@/components/Backoffice/Common/ResultList.vue';
import ManageAccount from '@/components/Backoffice/Common/ManageAccount.vue';

export default defineComponent({
    name: 'TrainerBackoffice',
    components: {
        MrUiKitSideNav,
        MrUiKitTitle,
        MrUiKitTab,
        TrainerPersonalData,
        AthleteList,
        ResultList,
        ManageAccount
    },
    setup() {
        const { t } = useI18n();
        const collapse = ref(false);
        const sideNavLabel = reactive({
            detailsTitle: computed(() => t('backoffice.sideNavTabs.details.information')),
            athleteTitle: computed(() => t('backoffice.sideNavTabs.contents.athletes')),
            resultTitle: computed(() => t('backoffice.sideNavTabs.contents.results')),
            accountTitle: computed(() => t('backoffice.sideNavTabs.management.account'))
        });

        return {
            t,
            collapse,
            trainerEntity: RouteEntity.Trainer,
            sideNavLabel
        }
    },
})
