import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "team-result mt-4" }
const _hoisted_2 = { class: "d-flex align-items-baseline" }
const _hoisted_3 = { class: "mb-4 text-start" }
const _hoisted_4 = {
  key: 0,
  class: "ms-auto"
}
const _hoisted_5 = { class: "row d-flex" }
const _hoisted_6 = { class: "col-12 col-md-6" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  class: "text-start pb-0",
  style: {"margin-left":"4rem"}
}
const _hoisted_9 = { class: "small" }
const _hoisted_10 = { class: "small mt-1" }
const _hoisted_11 = { class: "small mt-1" }
const _hoisted_12 = { class: "col-12 col-md-6" }
const _hoisted_13 = {
  key: 1,
  class: "container justify-content-center text-center p-7 ps-0 pe-0",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitSelect = _resolveComponent("MrUiKitSelect")!
  const _component_MrUiKitAvatar = _resolveComponent("MrUiKitAvatar")!
  const _component_MrUiKitList = _resolveComponent("MrUiKitList")!
  const _component_TeamResultIntermiadiateBlock = _resolveComponent("TeamResultIntermiadiateBlock")!
  const _component_TeamResultIntermiadiate = _resolveComponent("TeamResultIntermiadiate")!
  const _component_MrUiKitCollapse = _resolveComponent("MrUiKitCollapse")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.teamResults.length)
      ? (_openBlock(), _createBlock(_component_InfiniteScroll, {
          key: 0,
          guard: _ctx.guard,
          containerClasses: ['pt-0']
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.t("common.results")), 1)
              ]),
              (_ctx.showFilter)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_MrUiKitSelect, {
                      modelValue: _ctx.filterSelected,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterSelected) = $event)),
                      labelKey: "name",
                      items: _ctx.sports,
                      multiple: false,
                      clearable: false
                    }, null, 8, ["modelValue", "items"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamResults, (teamResult) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'team-result-' + teamResult.id,
                class: "mb-4"
              }, [
                _createVNode(_component_MrUiKitCollapse, null, {
                  collapse: _withCtx(({ show }) => [
                    show
                      ? (_openBlock(), _createBlock(_component_TeamResultIntermiadiate, {
                          key: 0,
                          id: teamResult.id,
                          generalNotes: teamResult.generalNotes
                        }, null, 8, ["id", "generalNotes"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        (teamResult.entities.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_MrUiKitList, {
                                items: teamResult.entities,
                                columns: teamResult.entities.length === 1 ? 1 : 2
                              }, {
                                default: _withCtx(({ item }) => [
                                  _createVNode(_component_MrUiKitAvatar, {
                                    "onLabel:click": () => _ctx.onNavigate(item.id, teamResult.isOrganizationResult === 0 ? 'athletes' : 'organizations'),
                                    classes: [{'mb-3': teamResult.entities.length !== 1  }],
                                    primaryText: item.fullname,
                                    picture: 
                          item.photo
                            ? item.photo.url
                            : 'https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg'
                        
                                  }, null, 8, ["onLabel:click", "classes", "primaryText", "picture"])
                                ]),
                                _: 2
                              }, 1032, ["items", "columns"])
                            ]))
                          : (_openBlock(), _createBlock(_component_MrUiKitAvatar, {
                              key: 1,
                              primaryText: teamResult.competition.name,
                              "onLabel:click": () => _ctx.onNavigate(teamResult.competition.id, 'competitions'),
                              picture: 
                    teamResult.photo
                      ? teamResult.photo.url
                      : 'https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg'
                  
                            }, null, 8, ["primaryText", "onLabel:click", "picture"])),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(teamResult.location), 1),
                          _createElementVNode("div", _hoisted_10, _toDisplayString(teamResult.startedAt) + " - " + _toDisplayString(teamResult.endedAt), 1),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(teamResult.sport) + " - " + _toDisplayString(teamResult.discipline), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_TeamResultIntermiadiateBlock, {
                          haveMedals: _ctx.haveMedals,
                          teamResult: teamResult
                        }, null, 8, ["haveMedals", "teamResult"])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["guard"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.t("common.noResults")), 1))
  ]))
}