
import { FormattedEntity, RouteEntity } from '@/interfaces'
import FederationService from '@/services/FederationService';
import OrganizationService from '@/services/OrganizationService';
import { computed, defineComponent, onBeforeUpdate, onMounted, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n';
import { MrUiKitSelect } from 'mr-ui-kit';

export default defineComponent({
    name: 'EntityFilters',
    components: {
        MrUiKitSelect
    },
    props: {
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Federation
        }
    },
    emits: ['filter'],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const isMounted = ref(false);
        const currentEntity = ref(RouteEntity.Federation);
        const filterItem: any = {
            [RouteEntity.Federation]: null,
            [RouteEntity.Organization]: RouteEntity.Federation,
            [RouteEntity.Trainer]: RouteEntity.Organization,
            [RouteEntity.Athlete]: RouteEntity.Organization
        }
        const items = ref<FormattedEntity[]>([]);
        const selected = ref<FormattedEntity | null>(null);
        const count = ref(0);
        const offset = ref(0);

        onMounted(async () => {
            currentEntity.value = props.entity;
            await onInit();
            isMounted.value = true;
        });

        onBeforeUpdate(() => {
            currentEntity.value = props.entity;
        })

        watch(currentEntity, (newV, oldV) => {
            if (newV !== oldV && isMounted.value === true) {
                onInit();
            }
        })

        const onInit = async () => {
            count.value = 0;
            offset.value = 0;
            items.value = [];
            selected.value = null;
            await onCount();
            await onLoad();
        }

        const onCount = async () => {
            switch (filterItem[currentEntity.value]) {
                case RouteEntity.Federation:
                    const totalFed = await FederationService.count(locale.value);
                    count.value = totalFed.count;
                    break;
                case RouteEntity.Organization:
                    const totalOrg = await OrganizationService.count(locale.value);
                    count.value = totalOrg.count;
                    break;
            }
        }

        const onLoad = async () => {
            switch (filterItem[currentEntity.value]) {
                case RouteEntity.Federation:
                    const loadedFed = await FederationService.get(locale.value, {
                        limit: 10,
                        offset: offset.value
                    });
                    items.value.push(...loadedFed);
                    break;
                case RouteEntity.Organization:
                    const loadedOrg = await OrganizationService.get(locale.value, {
                        limit: 10,
                        offset: offset.value
                    });
                    items.value.push(...loadedOrg);
                    break;
            }
            offset.value += 10;
        }

        const haveFilter = computed(() => {
            return filterItem[currentEntity.value] != null;
        });

        watch(selected, (newV) => {
            emit('filter', newV);
        })

        const entityLabel = computed(() => {
            return t('common.' + currentEntity.value);
        })

        const filterLabel = computed(() => {
            return t('common.operations.filter');
        })

        return {
            haveFilter,
            items,
            selected,
            filterLabel,
            count,
            onLoad,
            entityLabel,
			currentEntity
        }
    },
})
