
	import { computed, defineComponent, reactive } from 'vue';
	import { MrUiKitButton, MrUiKitInput, MrUiKitForm } from 'mr-ui-kit';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';

export default defineComponent({
    name: 'LoginForm',
    emits: ['update:modelValue', 'click' ],
    components: {
        MrUiKitButton,
        MrUiKitInput,
        MrUiKitForm
    },
    props: {
        modelValue: {
            type: Object,
            default: null
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const withI18nMessage = validators.createI18nMessage({ t });
        const required = withI18nMessage(validators.required, { messagePath: i18Validators.required });
        const credentials = reactive(props.modelValue);
        const formFields = reactive([
            {
                component: 'MrUiKitInput',
                name: 'email',
                props: {
                    label: computed(() => t('login.form.email.label')),
                    placeholder: computed(() => t('login.form.email.placeholder'))
                },
                rules: {
                    required
                }
            },
            {
                component: 'MrUiKitInput',
                name: 'password',
                props: {
                    type: 'password',
                    label: computed(() => t('login.form.password.label')),
                    placeholder: computed(() => t('login.form.password.placeholder'))
                },
                rules: {
                    required
                }
            }
        ]);

			const onClick = () => {
				emit('click');
			};

			return {
				t,
				credentials,
				formFields,
				onClick,
			};
		},
	});
