import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "first-reset-password-form flex-grow-1 mx-auto",
  style: {"max-width":"28rem"}
}
const _hoisted_2 = { class: "mb-5 mb-md-7" }
const _hoisted_3 = { class: "h2" }
const _hoisted_4 = { class: "d-flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitButton = _resolveComponent("MrUiKitButton")!
  const _component_MrUiKitForm = _resolveComponent("MrUiKitForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('firstReset.title')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.t('firstReset.subtitle')), 1)
    ]),
    _createVNode(_component_MrUiKitForm, {
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
      fields: _ctx.fields,
      onSubmit: _ctx.onClick
    }, {
      default: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MrUiKitButton, {
            type: "button",
            onClick: _ctx.onClick,
            disabled: slotProps.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('firstReset.button')), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "fields", "onSubmit"])
  ]))
}