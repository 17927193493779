import { Athlete, Filter, Tag, TagForm, TagMultiLanguage, Count } from "@/interfaces";
import axios from "axios";

class TagService {
    constructor() {}

    async getMultiLanguageById(organizationId: number, id: number): Promise<TagForm> {
        const { data } = await axios.get<TagMultiLanguage>('/organizations/' + organizationId + '/tags/' + id);
        return {
            id: data.id,
            athletes: data.athletes as Athlete[],
            name: {
                it: data.it.name,
                en: data.en.name
            }
        }
    }

    async countMulti(organizations: number[], lang: string): Promise<Count> {
        if (organizations.length === 0) {
            return {
                count: 0
            }
        }
        let filter = '?lang=' + lang;

        for (const o of organizations) {
            filter += '&filter[organizations]=' + o
        }

        const { data } = await axios.get<Count>('/tags/count' + filter);
        return data;
    }

    async findMulti(organizations: number[], lang: string, apiFilter: Filter): Promise<Tag[]> {
        if (organizations.length === 0) return []
        let filter = '?lang=' + lang;

        for (const o of organizations) {
            filter += '&filter[organizations]=' + o
        }

        const { data } = await axios.get<Tag[]>('/tags' + filter + '&filter[limit]=' + apiFilter.limit + '&filter[offset]=' + apiFilter.offset);
        return data
    }

    async createOrUpdate(organizationId: number, form: TagForm): Promise<void> {
        const body: TagMultiLanguage = {
            athletes: form.athletes.map(a => a.id),
            it: {
                name: form.name.it
            },
            en: {
                name: form.name.en
            }
        }

        const isUpdate = form.id != null;
        if (isUpdate) {
            await axios.patch('/organizations/' + organizationId + '/tags/' + form.id, body);
        } else {
            await axios.post('/organizations/' + organizationId + '/tags', body);
        }
    }
}

export default new TagService();