import { Competition, Filter, Intermiadiate, OtherResult, RouteEntity, TeamResult, TeamResultIntermiadiate, CompetitionMultiLanguage, CompetitionForm, CreateOrUpdateCompetition, Count, FilterBySport, Sport, CompetitionFilter } from "@/interfaces";
import axios from "axios";
import FilterService from "./FilterService";

class CompetitionResult {
    constructor() {}

    async getCompetitionsByFederation(federation: number, lang: string, filter: Filter): Promise<Competition[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const { data } = await axios.get<Competition[]>('/competitions/' + federation + '?lang=' + lang + '&' + parsedFilter);
        return data;
    }

    async countTeamResultsByEntityId(id: number, entity: RouteEntity, lang: string): Promise<Count> {
        const response = await axios.get<Count>(
            '/competitions/results/' + entity + '/' + id + '/count?lang=' + lang
        )
        return response.data;
    }

    async getTeamResultsByEntityId(id: number, entity: RouteEntity, lang: string, filter: Filter): Promise<TeamResult[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<TeamResult[]>(
            '/competitions/results/' + entity + '/' + id + '?lang=' + lang + '&' + parsedFilter
        )
        return response.data;
    }

    async getTeamResultsByCompetition(id: number, lang: string, filter: FilterBySport): Promise<TeamResult[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<TeamResult[]>(
            '/competitions/' + id + '/results' + '?lang=' + lang + '&' + parsedFilter
        )
        return response.data;
    }

    async getIntermiadiatesById(id: number, lang: string): Promise<TeamResultIntermiadiate> {
        const response = await axios.get<TeamResultIntermiadiate>('/competitions/intermiadiates/' + id + '?lang=' + lang);
        return response.data;
    }

    async countOtherResultsByEntityId(id: number, entity: RouteEntity, lang: string): Promise<Count> {
        const response = await axios.get<Count>(
            '/others/results/' + entity + '/' + id + '/count?lang=' + lang
        )
        return response.data;
    }

    async getOtherResultsByEntityId(id: number, entity: RouteEntity, lang: string, filter: Filter): Promise<OtherResult[]> {
        const parsedFilter = FilterService.parseFilter(filter);
        const response = await axios.get<OtherResult[]>(
            '/others/results/' + entity + '/' + id + '?lang=' + lang + '&' + parsedFilter
        )
        return response.data;
    }

    async getOtherResultsIntermiadiateById(id: number, lang: string): Promise<Intermiadiate[]> {
        const response = await axios.get<Intermiadiate[]>('/others/intermiadiates/' + id + '?lang=' + lang);
        return response.data;
    }

    async getMultiLanguageById(id: number): Promise<CompetitionMultiLanguage> {
        const { data } = await axios.get<CompetitionMultiLanguage>('/competitions/multilang/' + id);
        return data;
    }

    async createOrUpdate(federationId: number, form: CompetitionForm): Promise<boolean> {
        const body: CreateOrUpdateCompetition = {
            federationId,
            photoId: form.photo && form.photo.file ? form.photo.file.id : undefined,
            location: form.location,
            startedAt: form.startedAt.toISOString(),
            endedAt: form.endedAt.toISOString(),
            haveMedals: form.haveMedals,
            it: {
                name: form.name.it
            },
            en: {
                name: form.name.en
            }
        }

        const isUpdate = form.id != null;
        const url = '/competitions' + (isUpdate ? '/' + form.id : '');
        if (isUpdate) {
            await axios.patch(url, body);
        } else {
            await axios.post(url, body);
        }

        return true;
    }

    async countCompetitionsByFederation(federationId: number, lang: string, skipInt?: boolean): Promise<Count> {
        const response = await axios.get<Count>('/competitions/' + federationId + '/count?lang=' + lang + (
            skipInt === true ? '&skipInternational=true' : ''
        ));
        return response.data;
    }

    async getById(id: number, lang: string): Promise<Competition> {
        const { data } = await axios.get<Competition>('/competitions/detail/' + id + '?lang=' + lang);
        return data;
    }

    async getNext(lang: string, filter: CompetitionFilter): Promise<Competition[]> {
        const filters = FilterService.parseFilter(filter);
        const { data } = await axios.get<Competition[]>('/next/competitions?lang=' + lang + '&' + filters);
        return data;
    }

    async getSports(id: number, lang: string): Promise<Sport[]> {
        const { data } = await axios.get<Sport[]>('/competitions/' + id + '/sports?lang=' + lang);
        return data;
    }
}

export default new CompetitionResult();