
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitButton, MrUiKitNavTab, MrUiKitTab } from 'mr-ui-kit';
import { RouteEntity } from '@/interfaces';

import TeamForm from '@/components/Backoffice/TeamResult/TeamForm.vue';
import SingleForm from '@/components/Backoffice/TeamResult/SingleForm.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'CreateResultForm',
    emits: ['back'],
    components: {
        MrUiKitButton,
        MrUiKitNavTab,
        MrUiKitTab,
        TeamForm,
        SingleForm
    },
    props: {
        mode: {
            type: String as PropType<'new' | 'team' | 'single'>,
            default: 'new'
        },
        entity: {
            type: String as PropType<RouteEntity>,
            default: RouteEntity.Organization
        },
        value: {
            type: Number,
            default: null
        }
    },
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        const model = ref<number | null>(props.value);
        const store = useStore();
        const currentTab = ref<0 | 1>(0);
        const isMounted = ref(false);
        const types = {
            0: 'team',
            1: 'single'
        }
        const currentType = computed(() => {
            return types[currentTab.value];
        })

        onMounted(async () => {
            await store.dispatch('getResultTypes', locale.value);
            isMounted.value = true;
        })

        const onBack = () => {
            emit('back')
        }

        const onChangeTab = ({ i }: any) => {
            currentTab.value = i;
        }

        return {
            t,
            onBack,
            onChangeTab,
            currentType,
            isMounted,
            model
        }
    },
})
