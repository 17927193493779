
import { GeneralFile, Intermiadiate } from "@/interfaces";
import CompetitionService from "@/services/CompetitionService";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { MrUiKitBlob } from "mr-ui-kit";

import IntermiadiateBlock from "@/components/Frontend/TeamResult/IntermiadiateBlock.vue";
import Spinner from "@/components/UI/Spinner.vue";

export default defineComponent({
  name: "OtherResultIntermiadiate",
  components: {
    IntermiadiateBlock,
    Spinner,
    MrUiKitBlob,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    attachments: {
      type: Array as PropType<GeneralFile[]>,
      default: [],
    },
	    generalNotes: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const isLoading = ref<boolean>(false);
    const showBlob = ref(false);
    const intermiadiates = ref<Intermiadiate[]>([]);

    onMounted(async () => {
      isLoading.value = true;
      if (props.id !== -1) {
        intermiadiates.value =
          await CompetitionService.getOtherResultsIntermiadiateById(
            props.id,
            locale.value
          );
      }
      isLoading.value = false;
    });

    const blobFiles = computed(() => {
      return props.attachments.map((a) => {
        return {
          src: a.original,
          type: isPdf(a.url) ? "pdf" : "image",
        };
      });
    });

    const isPdf = (file: string) => {
      return file.indexOf(".pdf") > 0;
    };

    return {
      t,
      intermiadiates,
      isLoading,
      blobFiles,
      showBlob,
      isPdf,
    };
  },
});
