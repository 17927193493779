
	import { computed, defineComponent, PropType, reactive } from 'vue';
	import { required } from '@vuelidate/validators';
	import { MrUiKitModal, MrUiKitButton, MrUiKitForm } from 'mr-ui-kit';
	import { StaffForm } from '@/interfaces';
	import { useI18n } from 'vue-i18n';
	import * as validators from '@vuelidate/validators';
	import i18Validators from '@/utils/i18n-validators';
	import { useStore } from 'vuex';
	import FileService from '@/services/FileService';

	export default defineComponent({
		name: 'StaffModal',
		emits: ['submit', 'close', 'update:modelValue'],
		components: {
			MrUiKitModal,
			MrUiKitForm,
			MrUiKitButton,
		},
		props: {
			modelValue: {
				type: Object as PropType<StaffForm>,
				default: null,
			},
		},
		setup(props, { emit }) {
			const { t } = useI18n();
			const withI18nMessage = validators.createI18nMessage({ t });
			const required = withI18nMessage(validators.required, {
				messagePath: i18Validators.required,
			});
			const email = withI18nMessage(validators.email, {
				messagePath: i18Validators.email,
			});

			const store = useStore();
			const staff = reactive<StaffForm>(props.modelValue);
			const fields = reactive([
				{
					component: 'MrUiKitFileUpload',
					name: 'photo',
					props: {
						label: 'File',
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'fullname',
					props: {
						label: computed(() => t('common.fields.name')),
						labelPosition: 'left',
					},
					rules: {
						required,
					},
				},
				{
					component: 'MrUiKitInput',
					name: 'email',
					props: {
						label: 'Email',
						labelPosition: 'left',
					},
					rules: {
						email
					},
				},
				{
					component: 'MrUiKitSelect',
					name: 'staffRole',
					props: {
						labelKey: 'name',
						label: computed(() => t('common.fields.role')),
						labelPosition: 'left',
						key: 'id',
						items: store.state.staffRoles,
					},
					rules: {
						required,
					},
				},
			]);

			const onUpdateForm = async (event: any) => {
				if (staff == null) return;
				const field: keyof StaffForm = event.item.name;

				switch (event.type) {
					case 'upload':
						const toUpload = staff[field].file;
						if (toUpload) {
							const uploadedFile = await FileService.upload(staff[field].file);
							staff[field].url = uploadedFile.url;
							staff[field].file = uploadedFile;
						}
						break;
					default:
						return;
				}
			};

			const onSubmit = () => {
				emit('submit');
				emit('close');
			};

			const onClose = () => {
				emit('close');
			};

			return {
				t,
				staff,
				onUpdateForm,
				onSubmit,
				onClose,
				fields,
			};
		},
	});
