
import {
  Athlete,
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  RouteEntity,
  SocialItem,
} from "@/interfaces";
import AthleteService from "@/services/AthleteService";
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { MrUiKitProfile, MrUiKitNavTab, MrUiKitTab } from "mr-ui-kit";

import Biography from "@/components/Frontend/Common/Biography.vue";
import Ranking from "@/components/Frontend/Athlete/Ranking.vue";
import TrainerList from "@/components/Frontend/Common/TrainerList.vue";
import TeamResult from "@/components/Frontend/TeamResult/TeamResult.vue";
import OtherResult from "@/components/Frontend/OtherResult/OtherResult.vue";
import { localizePath } from "@/plugins/i18n";
import { useStore } from "vuex";

export default defineComponent({
    name: 'AthleteView',
    components: {
        MrUiKitProfile,
        MrUiKitNavTab,
        MrUiKitTab,
        Biography,
        Ranking,
        TrainerList,
        TeamResult,
        OtherResult
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const athleteId = ref(-1);
        const router = useRouter();
        const { t, locale, } = useI18n();
        const currentAthlete = ref<Athlete | null>(null);
        const isMounted = ref<boolean>(false);

        onMounted(async () => {
            athleteId.value = +route.params.id;
            currentAthlete.value = await AthleteService.getById(athleteId.value, locale.value);

            store.commit('setBreadcrumbRebuild');
            store.commit('pushBreadcrumb', {
                path: '/athletes/' + athleteId.value,
                label: currentAthlete.value.firstname + ' ' + currentAthlete.value.lastname,
                type: BreadcrumbStructureItem.Athlete,
                entityType: RouteEntity.Athlete,
                data: {
                    athlete: currentAthlete.value
                }
            })

            isMounted.value = true;
        })

        onBeforeUpdate(() => {
            if (athleteId.value !== +route.params.id) {
                athleteId.value = +route.params.id;
                window.location.reload();
            }
        })

        const title = computed(() => {
            if (currentAthlete.value == null) return '';
            return currentAthlete.value?.firstname + (currentAthlete.value?.middlename ? ' ' + currentAthlete.value?.middlename : '') + ' ' + currentAthlete.value?.lastname;
        })

        const bioItems = computed(() => {
            if (currentAthlete.value == null) return [];

            return [
                {
                    title: t('common.federations'),
                    visible: currentAthlete.value.federations.length > 0,
                    slot: 'federations'
                },
                {
                    title: t('common.organizations'),
                    visible: currentAthlete.value.organizations.length > 0,
                    slot: 'organizations'
                },
                {
                    title: t('common.bioItems.website'),
                    value: currentAthlete.value.website
                },
                {
                    title: t('common.bioItems.email'),
                    visible: currentAthlete.value.publicEmail,
                    value: currentAthlete.value.email
                },
                {
                    title: t('common.bioItems.telephone'),
                    visible: currentAthlete.value.publicTelephone,
                    value: currentAthlete.value.telephone
                }
            ]
        })

        const socialItems = computed<SocialItem>(() => {
            if (currentAthlete.value == null) {
                return {}
            }

            return {
                facebook: currentAthlete.value.facebook,
                instagram: currentAthlete.value.instagram
            }
        })

        const onRedirect = ({ id, fullname }: any, entity: 'organizations' | 'federations') => {
            router.push(localizePath({ path: '/' + entity + '/' + id }));
        }

        const goToBackoffice = () => {
            router.push(localizePath({ path: '/athletes/' + athleteId.value + '/edit' }));
        }

        const photo = computed(() => {
            return currentAthlete.value?.photo ? currentAthlete.value.photo.url : null;
        })

        const cover = computed(() => {
            return currentAthlete.value?.cover != null ? currentAthlete.value?.cover.original : require('@/assets/img/copertina_fed_base.jpg');
        })

        const canEdit = computed(() => {
            return currentAthlete.value?.permission != null && currentAthlete.value.permission === 'C,R,U,D';
        })

        return {
            t,
            athlete: currentAthlete,
            photo,
            cover,
            isMounted,
            title,
            bioItems,
            socialItems,
            onRedirect,
            goToBackoffice,
            canEdit,
            athleteEntity: RouteEntity.Athlete
        }
    },
})
