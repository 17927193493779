
import { defineComponent, reactive, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitSideNav, MrUiKitTitle, MrUiKitTab } from 'mr-ui-kit';
import { RouteEntity } from '@/interfaces';

import OrganizationPersonalData from '@/components/Backoffice/Organization/OrganizationPersonalData.vue';
import StaffList from '@/components/Backoffice/Common/StaffList.vue';
import TrainerList from '@/components/Backoffice/Common/TrainerList.vue';
import AthleteList from '@/components/Backoffice/Common/AthleteList.vue';
import TagList from '@/components/Backoffice/Organization/TagList.vue';
import ResultList from '@/components/Backoffice/Common/ResultList.vue';
import ManageAccount from '@/components/Backoffice/Common/ManageAccount.vue'

export default defineComponent({
    name: 'OrganizationBackoffice',
    components: {
        MrUiKitSideNav,
        MrUiKitTitle,
        MrUiKitTab,
        OrganizationPersonalData,
        StaffList,
        TrainerList,
        AthleteList,
        TagList,
        ManageAccount,
        ResultList
    },
    setup() {
        const { t } = useI18n();
        const collapse = ref(false);
        const sideNavLabel = reactive({
            detailsTitle: computed(() => t('backoffice.sideNavTabs.details.information')),
            executiveTitle: computed(() => t('backoffice.sideNavTabs.details.executive')),
            tagTitle: computed(() => t('backoffice.sideNavTabs.details.tag')),
            trainerTitle: computed(() => t('backoffice.sideNavTabs.contents.trainers')),
            athleteTitle: computed(() => t('backoffice.sideNavTabs.contents.athletes')),
            resultTitle: computed(() => t('backoffice.sideNavTabs.contents.results')),
            accountTitle: computed(() => t('backoffice.sideNavTabs.management.account'))
        });

        return {
            t,
            collapse,
            organizationEntity: RouteEntity.Organization,
            sideNavLabel
        }
    },
})
