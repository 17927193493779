
import { computed, defineComponent } from 'vue';
import { MrUiKitCard, MrUiKitNavTab, MrUiKitTab } from 'mr-ui-kit';
import { useI18n } from 'vue-i18n';
import { RouteEntity } from '@/interfaces';

import EntityList from '@/components/Account/EntityList.vue';

export default defineComponent({
    name: 'ProfileManaged',
    components: {
        MrUiKitCard,
        MrUiKitNavTab,
        MrUiKitTab,
        EntityList
    },
    setup() {
        const { t } = useI18n();

        const tabs = [
            {
                label: computed(() => t('tabs.federations')).value.replace(/"/g, ''),
                path: '/federations',
                entity: RouteEntity.Federation
            },
            {
                label: computed(() => t('tabs.organizations')).value.replace(/"/g, ''),
                path: '/organizations',
                entity: RouteEntity.Organization
            },
            {
                label: computed(() => t('tabs.trainers')).value.replace(/"/g, ''),
                path: '/trainers',
                entity: RouteEntity.Trainer
            },
            {
                label: computed(() => t('tabs.athletes')).value.replace(/"/g, ''),
                path: '/athletes',
                entity: RouteEntity.Athlete
            }
        ]

        return {
            t,
            tabs
        }
    },
})
