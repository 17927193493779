
	import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
	import { MrUiKitButton, MrUiKitTable, MrUiKitPagination } from 'mr-ui-kit';
	import { PaginationModel, Sport } from '@/interfaces';
	import { useI18n } from 'vue-i18n';
	import SportService from '@/services/SportService';
	import axios from 'axios';
	import { useStore } from 'vuex';

	import SportModal from '@/components/Backoffice/Modals/SportModal.vue';
	import DeleteEntityModal from '@/components/Backoffice/Modals/DeleteEntityModal.vue';

	export default defineComponent({
		name: 'SportList',
		emits: ['update', 'close'],
		components: {
			MrUiKitButton,
			MrUiKitTable,
			MrUiKitPagination,
			DeleteEntityModal,
			SportModal,
		},
		props: {
			showModal: {
				type: Boolean,
				default: false,
			},
		},
		setup(props, { emit }) {
			const { t, locale } = useI18n();
			const store = useStore();
			const sports = ref<Sport[]>([]);
			const showDeleteModal = ref(false);
			const selectedSport = ref<Sport | null>(null);
			const isLoading = ref(false);
			const search = ref<string | null>(null);
			const pagination = reactive<PaginationModel>({
				current: 0,
				count: 0,
				limit: 10,
			});
			const columns = reactive([
				{
					label: computed(() => t('common.fields.name')),
					field: 'name',
				},
				{
					label: '',
					field: 'action',
				}
			]);

			onMounted(async () => {
				await onInit();
			});

			const onInit = async () => {
				pagination.current = 1;
				const total = await SportService.count(locale.value, search.value);
				pagination.count = total.count;
				await onLoad(0);
			};

			const onLoad = async (offset: number) => {
				if (!isLoading.value) {
					isLoading.value = true;
					console.log('pre');
					sports.value = await SportService.get(locale.value, {
						limit: 10,
						offset,
						search: search.value != null ? search.value : null,
					});

					isLoading.value = false;
				}
			};

			const onDelete = async (sport: Sport) => {
				selectedSport.value = sport;
				showDeleteModal.value = true;
			};

			const onSubmitDelete = async () => {
				if (selectedSport.value) {
					try {
						await axios.delete('/sports/' + selectedSport.value.id);
						selectedSport.value = null;
						await onInit();
						store.commit('setAlert', {
							type: 'success',
							message: t('messages.generalSuccess.create'),
						});
					} catch (err) {
						store.commit('setAlert', {
							type: 'danger',
							message: t('messages.generalError.create'),
						});
					}
				}
				showDeleteModal.value = false;
			};

			const onUpdate = (item: Sport) => {
				selectedSport.value = item;
				emit('update');
			};

			const onCloseModal = async () => {
				selectedSport.value = null;
				await onInit();
				emit('close');
			};

			const onCloseDelete = () => {
				showDeleteModal.value = false;
				selectedSport.value = null;
			};

			const onSearch = (newSearch: string) => {
				if (newSearch != '') {
					search.value = newSearch;
				} else {
					search.value = null;
				}

				onInit();
			};

			const searchLabel = computed(() => {
			return t('common.findDots');
			})

			return {
				t,
				onLoad,
				onDelete,
				onUpdate,
				onSubmitDelete,
				onCloseModal,
				onCloseDelete,
				onSearch,
				searchLabel,
				showDeleteModal,
				selectedSport,
				sports,
				columns,
				pagination,
			};
		},
	});
