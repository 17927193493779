
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  RouteEntity,
  SocialItem,
  Trainer,
} from "@/interfaces";
import TrainerService from "@/services/TrainerService";
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { MrUiKitProfile, MrUiKitNavTab, MrUiKitTab } from "mr-ui-kit";

import Biography from "@/components/Frontend/Common/Biography.vue";
import AthleteList from "@/components/Frontend/Common/AthleteList.vue";
import TeamResult from "@/components/Frontend/TeamResult/TeamResult.vue";
import OtherResult from "@/components/Frontend/OtherResult/OtherResult.vue";
import { localizePath } from "@/plugins/i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "TrainerView",
  components: {
    MrUiKitProfile,
    MrUiKitNavTab,
    MrUiKitTab,
    Biography,
    AthleteList,
    TeamResult,
    OtherResult,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const trainerId = ref(-1);
    const router = useRouter();
    const { t, locale } = useI18n();
    const currentTrainer = ref<Trainer | null>(null);
    const isMounted = ref<boolean>(false);
    const totalCounts = reactive({
      athletes: {
        count: 0,
      },
    });

    onMounted(async () => {
      trainerId.value = +route.params.id;
      await load();

      isMounted.value = true;
    });

    onBeforeUpdate(() => {
      if (trainerId.value !== +route.params.id) {
        trainerId.value = +route.params.id;
        window.location.reload();
      }
    });

    const load = async () => {
      currentTrainer.value = await TrainerService.getById(trainerId.value, locale.value);

      totalCounts.athletes = await TrainerService.countSubList(
        trainerId.value,
        RouteEntity.Athlete
      );

			store.commit('setBreadcrumbRebuild');
      store.commit("pushBreadcrumb", {
        path: "/trainers/" + trainerId.value,
        label:
          currentTrainer.value.firstname + " " + currentTrainer.value.lastname,
        type: BreadcrumbStructureItem.Trainer,
        entityType: RouteEntity.Trainer,
        data: {
          trainer: currentTrainer.value,
        },
      });
    }

    const profileSubtitle = computed(() => {
      return totalCounts.athletes.count + " " + t("common.athletes");
    });

    const title = computed(() => {
      if (currentTrainer == null) return "";
      return (
        currentTrainer.value?.firstname +
        (currentTrainer.value?.middlename
          ? " " + currentTrainer.value?.middlename
          : "") +
        " " +
        currentTrainer.value?.lastname
      );
    });

    const bioItems = computed(() => {
      if (currentTrainer.value == null) return [];

      return [
        {
          title: t("common.federations"),
          visible: currentTrainer.value.federations.length > 0,
          slot: "federations",
        },
        {
          title: t("common.organizations"),
          visible: currentTrainer.value.organizations.length > 0,
          slot: "organizations",
        },
        {
          title: t("common.bioItems.website"),
          value: currentTrainer.value.website,
        },
        {
          title: t("common.bioItems.email"),
          visible: currentTrainer.value.publicEmail,
          value: currentTrainer.value.email,
        },
        {
          title: t("common.bioItems.telephone"),
          visible: currentTrainer.value.publicTelephone,
          value: currentTrainer.value.telephone,
        },
      ];
    });

    const socialItems = computed<SocialItem>(() => {
      if (currentTrainer.value == null) {
        return {};
      }

      return {
        facebook: currentTrainer.value.facebook,
        instagram: currentTrainer.value.instagram,
      };
    });

    const onRedirect = (
      { id, fullname }: any,
      entity: "organizations" | "federations"
    ) => {
      router.push(localizePath({ path: "/" + entity + "/" + id }));
    };

    const photo = computed(() => {
      return currentTrainer.value?.photo
        ? currentTrainer.value.photo.url
        : null;
    });

    const cover = computed(() => {
        return currentTrainer.value?.cover ? currentTrainer.value.cover.original : require('@/assets/img/copertina_fed_base.jpg');
    })

    return {
      t,
      photo,
      cover,
      trainer: currentTrainer,
      isMounted,
      title,
      totalCounts,
      profileSubtitle,
      bioItems,
      socialItems,
      onRedirect,
      trainerEntity: RouteEntity.Trainer,
    };
  },
});
