import {
	Athlete,
	AthleteForm,
	AthleteMultiLang,
	Count,
	CountFilterByOrganization,
	CreateAthlete,
	Filter,
	FilterByOrganization,
	Organization,
	RouteEntity,
	Staff,
	Trainer,
	UpdateAthleteBody,
	UpdateAthleteForm,
} from '@/interfaces';
import axios from 'axios';
import AccountService from './AccountService';
import FilterService from './FilterService';

class AthleteService {
	constructor() {}

	async count(filter?: CountFilterByOrganization): Promise<Count> {
		const parsedFilter = FilterService.parseFilter(filter);
		const response = await axios.get<Count>(
			'/athletes/count' + '?' + parsedFilter,
		);
		return response.data;
	}

	async get(
		lang: string,
		filter: FilterByOrganization,
	): Promise<Organization[]> {
		const parsedFilter = FilterService.parseFilter(filter);
		const response = await axios.get<Organization[]>(
			'/athletes' + '?lang=' + lang + '&' + parsedFilter,
		);
		return response.data;
	}

	async getById(id: number, lang: string): Promise<Athlete> {
		const response = await axios.get<Athlete>(
			'/athletes/' + id + '?lang=' + lang,
		);
		return response.data;
	}

	async getMultiLangById(id: number, lang: string): Promise<UpdateAthleteForm> {
		const { data } = await axios.get<AthleteMultiLang>(
			'/athletes/' + id + '/multilang?lang=' + lang,
		);
		return {
			id: data.id,
			firstname: data.firstname,
			lastname: data.lastname,
			cover: {
				file: data.cover ? data.cover : null,
				url: data.cover ? data.cover.url : null,
			},
			photo: {
				file: data.photo ? data.photo : null,
				url: data.photo ? data.photo.url : null,
			},
			organizations: data.organizations,
			email: data.email,
			publicEmail: data.publicEmail,
			telephone: data.telephone,
			publicTelephone: data.publicTelephone,
			instagram: data.instagram,
			internalCode: data.internalCode,
			facebook: data.facebook,
			dateOfBirth: data.dateOfBirth,
			active: data.active,
			deleted: !data.deleted,
			trainerRole: data.role,
			rankingPosition: data.rankingPosition ? String(data.rankingPosition) : '',
			rankingLink: data.rankingLink,
			bestRankingPosition: data.bestRankingPosition
				? String(data.bestRankingPosition)
				: '',
			bio: {
				it: data.it && data.it.bio ? data.it.bio : '',
				en: data.en && data.en.bio ? data.en.bio : '',
			},
		};
	}

	async getStaff(id: number, lang: string, filter: Filter): Promise<Staff[]> {
		const parsedFilter = FilterService.parseFilter(filter);
		const response = await axios.get<Staff[]>(
			'/athletes/' + id + '/staffs?lang=' + lang + '&' + parsedFilter,
		);
		return response.data;
	}

	async countSubList(
		id: number,
		type: RouteEntity,
		lang?: string,
	): Promise<Count> {
		const response = await axios.get<Count>(
			'/athletes/' + id + '/' + type + '/count' + (lang ? '?lang=' + lang : ''),
		);
		return response.data;
	}

	async getTrainers(
		id: number,
		lang: string,
		filter: FilterByOrganization,
	): Promise<Trainer[]> {
		const parsedFilter = FilterService.parseFilter(filter);
		const response = await axios.get<Trainer[]>(
			'/athletes/' + id + '/trainers?lang=' + lang + '&' + parsedFilter,
		);
		return response.data;
	}

	async create(form: AthleteForm): Promise<Athlete> {
		const userCredentials = await AccountService.checkAccount(
			form.userCredentials,
		);
		const body: CreateAthlete = {
			id: form.id,
			userCredentials,
			organizations: form.organizations.map((o: Organization) => o.id),
			firstname: form.firstname,
			lastname: form.lastname,
			photoId: form.photo.file ? form.photo.file.id : undefined,
		};
		const { data } = await axios.post<Athlete>('/athletes', body);
		return data;
	}

	async updateById(form: UpdateAthleteForm): Promise<void> {
		const body: UpdateAthleteBody = {
			coverId: form.cover && form.cover.file ? form.cover.file.id : null,
			photoId: form.photo && form.photo.file ? form.photo.file.id : null,
			firstname: form.firstname,
			lastname: form.lastname,
			organizations: form.organizations.map((o) => o.id),
			email: form.email,
			instagram: form.instagram,
			facebook: form.facebook,
			publicEmail: form.publicEmail,
			internalCode: form.internalCode,
			telephone: form.telephone,
			publicTelephone: form.publicTelephone,
			active: form.active,
			deleted: !form.deleted,
			rankingLink: form.rankingLink ? form.rankingLink : null,
			rankingPosition: form.rankingPosition ? +form.rankingPosition : null,
			bestRankingPosition: form.bestRankingPosition
				? +form.bestRankingPosition
				: null,
			it: {
				bio: form.bio.it,
			},
			en: {
				bio: form.bio.en,
			},
		};
		await axios.patch('/athletes/' + form.id, body);
	}
}

export default new AthleteService();
